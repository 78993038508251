/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { EventHandler } from 'react-bootstrap-daterangepicker';
import { useLocation, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useState } from '@hookstate/core';
import moment from 'moment';
import ReportRepository from '../../../../repositories/ReportRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { $clone } from '../../../../vodea/utilities';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';

const ReportDetails: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();

  const config = useState({
    total: 0,
    amount: 0,
    loading: true,
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const dataTable = useState({
    cost_of_sales: 0,
    gross_profit: 0,
    operational_expenses: [
      {
        name: '',
        value: 0
      }
    ],
    total_discount: 0,
    profit_and_loss: 0,
    total_operational_expense: 0,
    total_revenue: 0,
    total_value_po_ppn: 0,
    total_value_po_without_ppn: 0
  });

  const getData = async () => {
    const conf = _.omit($clone(config.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    dataTable.set({
      cost_of_sales: 0,
      gross_profit: 0,
      operational_expenses: [
        {
          name: '',
          value: 0
        }
      ],
      total_discount: 0,
      profit_and_loss: 0,
      total_operational_expense: 0,
      total_value_po_ppn: 0,
      total_value_po_without_ppn: 0,
      total_revenue: 0
    });

    ReportRepository.all(params)
      .then((Response: AxiosResponse) => {
        const data = Response.data.data;
        dataTable.set(data);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangeDate = (start: any, end: any) => {
    config.date_from.set(start.format('YYYY-MM-DD'));
    config.date_to.set(end.format('YYYY-MM-DD'));

    getData();
  };

  const breadcrumbList = [
    {
      label: 'Report',
      link: '/report'
    },
    {
      label: 'Profit and Loss',
      link: '/profit-and-loss'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Report</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div id={'report-section'}>
        <div id={'report-section'}>
          <div className={'card-paper'}>
            <div className={'card-header'}>
              <div className={'card-header-filter-wrapper d-flex align-items-center justify-content-between'}>
                <div className={'title-wrapper'}>
                  <h6 className={'card-header-title'}>{t('Profit and Loss')}</h6>
                </div>
                <div className={'filter-item'}>
                  <VuiDateRangePicker
                    startDate={config.date_from.get()}
                    endDate={config.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={false}
                  />
                </div>
              </div>
            </div>
            <Table className={'table-report'}>
              <tbody>
                <tr>
                  <td className={'title-table'}>Purchase Order</td>
                </tr>
                <tr>
                  <td className={'data-table pad'}>
                    Total Purchase Order PPN
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.total_value_po_ppn.get()}
                      data={dataTable.total_value_po_ppn.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                  <td className={'data-table pad'}>
                    Total Purchase Order Non PPN
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.total_value_po_without_ppn.get()}
                      data={dataTable.total_value_po_without_ppn.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={'title-table'}>Revenue</td>
                </tr>
                <tr>
                  <td className={'data-table pad'}>
                    Total of Revenue
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.total_revenue.get()}
                      data={dataTable.total_revenue.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={'title-table'}>Cost of Sales</td>
                </tr>
                <tr>
                  <td className={'data-table pad'}>
                    Total Cost of Sales
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.cost_of_sales.get()}
                      data={dataTable.cost_of_sales.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                  <td className={'data-table pad'}>
                    Total Cost Discount
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.total_discount.get()}
                      data={dataTable.total_discount.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={'data-table title-table'}>
                    Gross Profit
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.gross_profit.get()}
                      data={dataTable.gross_profit.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={'title-table'}>Operational Expanse</td>
                </tr>
                {dataTable.operational_expenses.get() &&
                  dataTable.operational_expenses.get().map(item => (
                    <tr key={`item-${item.name}`}>
                      <td className={'data-table pad'}>
                        {item.name}
                        <VuiNumberFormat className={'table-idr'} data={item.value} prefix={'Rp. '} />
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td className={'data-table pad'}>
                    Total of Operational Expanse
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.total_operational_expense.get()}
                      data={dataTable.total_operational_expense.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={'title-table data-table'}>
                    Profit (Loss){' '}
                    <VuiNumberFormat
                      className={'table-idr'}
                      value={dataTable.profit_and_loss.get()}
                      data={dataTable.profit_and_loss.get()}
                      prefix={'Rp. '}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDetails;

import { Link } from 'react-router-dom';
import { VuiButton } from '../../VuiButton';
import React from 'react';

interface VuiActionFormProps {
  loading?: boolean;
  title?: string;
  cancelLink: string;
  cancelLabel?: string;
  saveLabel?: string;
}

const VuiActionForm: React.FC<VuiActionFormProps> = ({
  title = 'Save Changes?',
  cancelLink,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  loading = false
}) => {
  return (
    <div className={'card-action-form'}>
      <h6 className={'title'}>{title}</h6>

      <div className='btn-action-wrapper'>
        <Link to={cancelLink} className={'btn btn-sm btn-secondary'}>
          {cancelLabel}
        </Link>
        <VuiButton forSubmit={true} label={saveLabel} loading={loading} />
      </div>
    </div>
  );
};

export default VuiActionForm;

import { api } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  salesOrder: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/sales-order`, {
      params
    });
  },
  receivePayment: function (params: any) {
    return api.get(`${endPoint()}/api/widget/receive-payment`, {
      params
    });
  },
  purchaseOrder: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/purchase-order`, {
      params
    });
  },
  sendPayment: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/send-payment`, {
      params
    });
  },
  deliveryOrder: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/delivery-order`, {
      params
    });
  },
  product: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/product`, {
      params
    });
  },
  customer: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/customer`, {
      params
    });
  },
  listCustomer: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/customer-favourite`, {
      params
    });
  },
  listProduct: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/product-favourite`, {
      params
    });
  },
  totalOverdueSalesOrder: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/overdue-sales-order`, {
      params
    });
  },
  channelOffline: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/channel-offline`, {
      params
    });
  },
  outstandingCashRequest: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/outstanding-cash-request`, {
      params
    });
  },
  exportListCustomer: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/list-customer`, {
      params,
      responseType: 'blob'
    });
  },
  cigaretteSales: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/cigarette-sales`, {
      params
    });
  },
  nonCigaretteSales: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/non-cigarette-sales`, {
      params
    });
  }
};

export default Repository;

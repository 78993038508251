import { Helmet } from 'react-helmet-async';
import React, { FC, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import NotificationList from './NotificationList';

export type TabList = 'SalesOrderDue' | 'GoodsReceiptDue' | 'StockWarning' | 'ExportReady';

const Notification: FC = () => {
  const title = 'Notification';
  const [currentTab, setCurrentTab] = useState<TabList>('SalesOrderDue');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <Tabs id='controlled-tab-example' activeKey={currentTab} onSelect={(k: any) => setCurrentTab(k)}>
        <Tab eventKey='SalesOrderDue' title='Sales Order Due'>
          {currentTab === 'SalesOrderDue' && <NotificationList defaultParams={'SalesOrderDueNotification'} />}
        </Tab>
        <Tab eventKey='GoodsReceiptDue' title='Goods Receipt Due'>
          {currentTab === 'GoodsReceiptDue' && <NotificationList defaultParams={'GoodsReceiptDueNotification'} />}
        </Tab>
        <Tab eventKey='StockWarning' title='Stock Warning'>
          {currentTab === 'StockWarning' && <NotificationList defaultParams={'StockWarningNotification'} />}
        </Tab>
        <Tab eventKey='ExportReady' title='Export Ready'>
          {currentTab === 'ExportReady' && <NotificationList defaultParams={'ExportReadyNotification'} />}
        </Tab>
      </Tabs>
    </>
  );
};

export default Notification;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import BootstrapTable, { TableChangeHandler } from 'react-bootstrap-table-next';
import StockOpnameRepository from '../../../../../repositories/StockOpnameRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from '@hookstate/core/dist';
import paginationFactory from 'react-bootstrap-table2-paginator';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';

const StockOpnameForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'asc',
    order_by: '',
    total: 0,
    loading: false,

    // additional
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const title = t('Stock Opname');

  const breadcrumbList = [
    {
      label: t('Inventory'),
      link: '/inventory'
    },
    {
      label: t('Stock Opname'),
      link: '/inventory/stock-opname'
    },
    {
      label: id,
      link: '/sales/sales-order/' + id
    }
  ];

  const data = useState({
    number: 0,
    date: moment().format('DD-MM-YYYY'),
    stock_opname_products: []
  });

  const productColumns = [
    {
      dataField: 'product.name',
      text: 'Name'
    },
    {
      dataField: 'price',
      text: 'Price',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} decimalScale={2} />;
      }
    },
    {
      dataField: 'product.unit.name',
      text: 'Unit'
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      formatter: (cell: any) => {
        return <NumberFormat defaultValue={cell} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />;
      }
    }
  ];

  const getData = () => {
    data.set({
      number: 0,
      date: moment().format('DD-MM-YYYY'),
      stock_opname_products: []
    });

    StockOpnameRepository.show(id, {
      with: 'stockOpnameProducts.product.unit'
    })
      .then((response: AxiosResponse) => {
        data.set(response.data.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    if (sortField) tableConfig.order_by.set(sortField);
    if (sortOrder) tableConfig.sorted_by.set(sortOrder);

    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const options = {
    showTotal: true,
    sizePerPage: 10,
    page: 1
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div className={'form-wrapper'}>
        <div className={'default-page-layout layout-reverse'}>
          <div className={'information-section'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={data.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <input type='text' className='form-control' disabled value={moment(data.date.get()).format('DD-MM-YYYY')} />
                </div>
              </div>
            </div>
          </div>

          <div className='multiple-paper-section'>
            <div className='card-paper'>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Product List</h6>
              </div>
              <div className='card-content'>
                <BootstrapTable
                  keyField='id'
                  pagination={paginationFactory(options)}
                  data={data.stock_opname_products.get()}
                  columns={productColumns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockOpnameForm;

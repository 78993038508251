// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationRepository from '../../../../../repositories/NotificationRepository';
import { AxiosError, AxiosResponse } from 'axios';

interface VuiHeaderNotificationProps {}

const VuiHeaderNotification: React.FC<VuiHeaderNotificationProps> = () => {
  const navigate = useNavigate();
  const [unRead, setUnReadNotification] = useState<number>(0);

  const loadSummaryNotificationData = useCallback(async () => {
    await NotificationRepository.getSummary()
      .then((response: AxiosResponse) => {
        setUnReadNotification(response.data.unread);
      })
      .catch((error: AxiosError) => {});
  }, []);

  useMemo(() => {
    (async () => {
      await loadSummaryNotificationData();
    })();
  }, []);

  return (
    <>
      <div className={'badge-notification'} onClick={() => navigate('/notification')}>
        {unRead > 0 ? <span className={'notification-sign'} /> : null}
        <FontAwesomeIcon className={'icon'} icon={faBell} />
      </div>
    </>
  );
};

export default VuiHeaderNotification;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect, useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { useNavigate, useParams } from 'react-router-dom';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import _ from 'lodash';
import {
  InformationInputs,
  informationSchema,
  InformationBaseModel,
  GoodsReceiptDifferenceInputs,
  goodsReceiptDifferenceSchema,
  DifferenceBaseModel
} from './validation';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import PurchaseOrderRepository from '../../../../../repositories/PurchaseOrderRepository';
import GoodsReceiptRepository from '../../../../../repositories/GoodsReceiptRepository';
import BootstrapTable from 'react-bootstrap-table-next';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import NumberFormat from 'react-number-format';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { goodsReceiptDifferenceTreatOptions, goodsReceiptDifferenceTypeOptions } from '../../../../../constants';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import { $clone } from '../../../../../vodea/utilities';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';

const PurchaseReceiveItemForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const isClosed = useState(false);

  const title = id ? t('Edit Receive Item') : t('Create Receive Item');

  const getData = () => {
    GoodsReceiptRepository.show(id, {
      with: [
        'goodsReceiptItems.product',
        'goodsReceiptItems.unit',
        'goodsReceiptItems.storage',
        'goodsReceiptItems.purchaseOrderItem',
        'purchaseOrder',
        'goodsReceiptDifferences.product',
        'goodsReceiptDifferences.unit',
        'goodsReceiptDifferences.type',
        'goodsReceiptDifferences.paymentTreat'
      ]
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        isClosed.set(data.is_close && data.is_close == 1);

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));

          if (formattedData.goods_receipt_differences.length) {
            formattedData.goods_receipt_differences.forEach((item: any, key: number) => {
              formattedData.goods_receipt_differences[key] = formatSetValueForm(
                {
                  id: null,
                  purchase_order_item_id: null,
                  payment_treat_id: null,
                  product: null,
                  unit: null,
                  quantity: null,
                  type_id: null
                },
                item
              );

              formattedData.goods_receipt_differences[key].purchase_order_item_id = {
                id: item.purchase_order_item_id,
                name: item.product.name,
                product: item.product,
                unit: item.unit
              };
            });
          }
        }

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  useMemo(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line

  const informationData = useState({
    number: ''
  });
  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    watch: informationWatch,
    setError: informationSetError,
    setValue: informationSetValue
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      purchase_order_id: null,
      date: moment(),
      goods_receipt_items: [],
      goods_receipt_differences: []
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    Object.assign(formData, {
      goods_receipt_differences: []
    });

    if (differenceFields.length !== 0) {
      differenceFields.forEach((item: any, key: number) => {
        formData.goods_receipt_differences[key] = formatFormData(_.omit(item, ['product', 'unit', 'key']));
      });
    }

    (id ? GoodsReceiptRepository.update(id, formData) : GoodsReceiptRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/purchase/receive-item');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const watchPurchaseOrder = informationWatch('purchase_order_id');
  useEffect(() => {
    if (watchPurchaseOrder && !id) {
      getPurchaseOrderData();
    }
  }, [watchPurchaseOrder]); // eslint-disable-line

  const getPurchaseOrderData = () => {
    informationSetValue('goods_receipt_items', []);

    PurchaseOrderRepository.show(_.get(watchPurchaseOrder, 'id'), {
      with: ['user', 'supplier', 'purchaseOrderItems.product', 'purchaseOrderItems.unit', 'purchaseOrderItems.storage']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        if (data.purchase_order_items) {
          data.purchase_order_items.forEach((value: any, index: number) => {
            const tempItem = {
              purchase_order_item_id: value.id,
              purchase_order_item: value,
              quantity: value.quantity,
              product: value.product,
              unit: value.unit,
              storage: value.storage
            };

            itemAppend(tempItem);
          });
        }
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  // Table Receive Item
  const productDifferenceOptions = useState<any[]>([]);

  const { fields: itemFields, append: itemAppend } = useFieldArray({
    keyName: 'key',
    name: 'goods_receipt_items',
    control: informationControl
  });

  useEffect(() => {
    if (itemFields) {
      getProductDifferenceOptions();
    }
  }, [itemFields]); // eslint-disable-line

  const getProductDifferenceOptions = () => {
    productDifferenceOptions.set([]);

    itemFields.forEach((value: any) => {
      const option: any = {
        id: value.purchase_order_item_id,
        name: _.get(value, 'product.name'),
        product: _.get(value, 'product'),
        unit: _.get(value, 'unit')
      };

      productDifferenceOptions.merge([option]);
    });
  };

  const tableReceiveItemColumns = [
    {
      dataField: 'product.name',
      text: 'Name'
    },
    {
      dataField: 'unit.name',
      text: 'Unit'
    },
    {
      dataField: 'purchase_order_item.quantity',
      text: 'Qty'
    },
    {
      dataField: 'receive',
      text: 'Receive',
      isDummyField: true,
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <>
            <input
              type={'hidden'}
              ref={informationRegister}
              name={`goods_receipt_items[${rowIndex}].purchase_order_item_id`}
              defaultValue={_.get(row, 'purchase_order_item_id')}
            />

            <input type={'hidden'} ref={informationRegister} name={`goods_receipt_items[${rowIndex}].id`} defaultValue={row.id} />

            <div
              className={clsx({
                'form-group': true,
                error: _.get(informationErrors, `goods_receipt_items[${rowIndex}].quantity`)
              })}
            >
              <Controller
                name={`goods_receipt_items[${rowIndex}].quantity`}
                control={informationControl}
                defaultValue={row.quantity}
                render={props => (
                  <NumberFormat
                    className={'form-control'}
                    defaultValue={props.value}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    placeholder={'0'}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
            </div>
          </>
        );
      }
    },
    {
      dataField: 'storage.name',
      text: 'Storage'
    }
  ];

  // Table Difference
  const { fields: differenceFields, append: differenceAppend, remove: differenceRemove, insert: differenceInsert } = useFieldArray({
    keyName: 'key',
    name: 'goods_receipt_differences',
    control: informationControl
  });

  const tableDifferenceColumns = [
    {
      dataField: 'product.name',
      text: 'Name'
    },
    {
      dataField: 'unit.name',
      text: 'Unit'
    },
    {
      dataField: 'quantity',
      text: 'Qty'
    },
    {
      dataField: 'type_id.name',
      text: 'Type'
    },
    {
      dataField: 'payment_treat_id.name',
      text: 'Treat'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleDifferenceModal(true, rowIndex)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handleDifferenceClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const {
    handleSubmit: differenceHandleSubmit,
    control: differenceControl,
    errors: differenceErrors,
    setValue: differenceSetValue,
    reset: differenceReset
  } = useForm<GoodsReceiptDifferenceInputs>({
    resolver: yupResolver(goodsReceiptDifferenceSchema),
    defaultValues: DifferenceBaseModel
  });

  const differenceDeleteIndex = useState(0);
  const showDifferenceDeleteModal = useState(false);

  const handleDifferenceClickDelete = (index: number) => {
    differenceDeleteIndex.set(index);
    showDifferenceDeleteModal.set(true);
  };

  const handleDifferenceDelete = () => {
    differenceRemove(differenceDeleteIndex.get());
    showDifferenceDeleteModal.set(false);
    differenceDeleteIndex.set(0);
  };

  const showDifferenceModal = useState(false);
  const selectedDifferenceModalIndex = useState(-1);

  const handleDifferenceModal = (show: boolean, index: number = -1) => {
    showDifferenceModal.set(show);
    selectedDifferenceModalIndex.set(index);
    differenceReset();

    if (index !== -1) {
      const selectedData = differenceFields[index];

      setTimeout(() => {
        differenceSetValue('id', _.get(selectedData, 'id'));
        differenceSetValue('purchase_order_item_id', _.get(selectedData, 'purchase_order_item_id'));
        differenceSetValue('type_id', _.get(selectedData, 'type_id'));
        differenceSetValue('quantity', _.get(selectedData, 'quantity'));
        differenceSetValue('payment_treat_id', _.get(selectedData, 'payment_treat_id'));
      }, 500);
    }
  };

  const onDifferenceSubmit = differenceHandleSubmit(data => {
    const tempData = {
      ...data,
      product: _.get(data, 'purchase_order_item_id.product'),
      unit: _.get(data, 'purchase_order_item_id.unit')
    };

    if (selectedDifferenceModalIndex.get() === -1) {
      differenceAppend(tempData);
    } else {
      differenceInsert(selectedDifferenceModalIndex.get() + 1, tempData);
      differenceRemove(selectedDifferenceModalIndex.get());
    }

    showDifferenceModal.set(false);
  });

  const breadcrumbList = [
    {
      label: t('Purchase'),
      link: '/purchase'
    },
    {
      label: t('Receive Item'),
      link: '/purchase/receive-item'
    },
    {
      label: id ? id : t('Create'),
      link: '/purchase/receive-item/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <div id={'return-item-page'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'page-layout'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true
                      })}
                    >
                      <label className={'form-label'}>Number</label>
                      <input type='text' className='form-control' disabled value={informationData.number.get()} />
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.purchase_order_id
                      })}
                    >
                      <label className={'form-label'}>PO Number</label>
                      <div className={'form-select-wrapper type-with-button'}>
                        <Controller
                          name={'purchase_order_id'}
                          control={informationControl}
                          render={props => (
                            <VuiSelect
                              selectParams={{
                                for: 'receive-item'
                              }}
                              selectRepository={PurchaseOrderRepository}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              labelKey={'number'}
                              isDisabled={id !== undefined}
                            />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.purchase_order_id, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.date
                      })}
                    >
                      <label className={'form-label'}>Date</label>
                      <Controller
                        name={'date'}
                        control={informationControl}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                        )}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'multiple-paper-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Product Receive')}</h6>
                </div>
                <div className={'card-content'}>
                  {watchPurchaseOrder !== null ? (
                    <>
                      <BootstrapTable keyField='key' data={itemFields} columns={tableReceiveItemColumns} />
                    </>
                  ) : (
                    <div className='card-empty-content'>please select PO Number first</div>
                  )}
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Difference')}</h6>
                </div>
                <div className='card-content'>
                  {watchPurchaseOrder !== null ? (
                    <>
                      <BootstrapTable keyField='key' data={differenceFields} columns={tableDifferenceColumns} />

                      <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleDifferenceModal(true)}>
                        <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                        {t('Add Product')}
                      </button>
                    </>
                  ) : (
                    <div className='card-empty-content'>please select PO Number first</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!isClosed.get() && <VuiActionForm loading={informationLoading.get()} cancelLink={'/purchase/receive-item'} />}
        </form>
      </div>

      <Modal
        show={showDifferenceModal.get()}
        onHide={() => showDifferenceModal.set(false)}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop={'static'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {selectedDifferenceModalIndex.get() === -1 ? t('Add Product Difference') : t('Edit Product Difference')}
          </Modal.Title>
        </Modal.Header>

        <form className={'form-wrapper'} onSubmit={onDifferenceSubmit}>
          <Modal.Body>
            <div className={'row'}>
              <Controller
                name={'id'}
                control={differenceControl}
                defaultValue={1}
                render={props => <input type={'hidden'} defaultValue={props.value} />}
              />

              <div
                className={clsx({
                  'form-group': true,
                  error: differenceErrors.purchase_order_item_id
                })}
              >
                <label className={'form-label'}>Product</label>
                <Controller
                  name={'purchase_order_item_id'}
                  control={differenceControl}
                  defaultValue={null}
                  render={props => (
                    <VuiSelect
                      options={$clone(productDifferenceOptions.get())}
                      defaultValue={props.value}
                      onChange={val => {
                        props.onChange(val);
                      }}
                      isMulti={false}
                    />
                  )}
                />
                <label className={'label-help'}>{_.get(differenceErrors, 'purchase_order_item_id.message')}</label>
              </div>

              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: differenceErrors.type_id
                  })}
                >
                  <label className={'form-label'}>Type</label>
                  <Controller
                    name={'type_id'}
                    control={differenceControl}
                    defaultValue={null}
                    render={props => (
                      <VuiSelect
                        defaultValue={props.value}
                        options={goodsReceiptDifferenceTypeOptions}
                        onChange={val => {
                          props.onChange(val);
                        }}
                        isMulti={false}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(differenceErrors, 'type_id.message')}</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: differenceErrors.quantity
                  })}
                >
                  <label className={'form-label'}>Quantity</label>
                  <Controller
                    name={'quantity'}
                    control={differenceControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative={false}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        placeholder={'0'}
                        onValueChange={({ value }) => props.onChange(value)}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(differenceErrors, 'quantity.message')}</label>
                </div>
              </div>

              <div
                className={clsx({
                  'form-group': true,
                  error: differenceErrors.payment_treat_id
                })}
              >
                <label className={'form-label'}>Payment Treat</label>
                <Controller
                  name={'payment_treat_id'}
                  control={differenceControl}
                  defaultValue={null}
                  render={props => (
                    <VuiSelect
                      defaultValue={props.value}
                      options={goodsReceiptDifferenceTreatOptions}
                      onChange={val => {
                        props.onChange(val);
                      }}
                      isMulti={false}
                    />
                  )}
                />
                <label className={'label-help'}>{_.get(differenceErrors, 'payment_treat_id.message')}</label>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showDifferenceModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={false} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal
        show={showDifferenceDeleteModal.get()}
        onHide={() => showDifferenceDeleteModal.set(false)}
        onContinue={handleDifferenceDelete}
      />
    </>
  );
};

export default PurchaseReceiveItemForm;

import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  forgotPassword: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/forgot-password`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.forgotPassword.name].handleRequestCancellation().token
    });
  },
  resetPassword: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/reset-password`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.resetPassword.name].handleRequestCancellation().token
    });
  }
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;

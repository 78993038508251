import { useMemo, useState } from 'react';
import _ from 'lodash';
import useIsMounted from './useIsMounted';

const useScroll = () => {
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const isMounted = useIsMounted();

  useMemo(() => {
    (window as any).addEventListener(
      'scroll',
      _.debounce((event: any) => {
        const bottom =
          event.target.scrollingElement.scrollHeight - event.target.scrollingElement.scrollTop <=
          event.target.scrollingElement.clientHeight + 150;

        if (isMounted.current) {
          if (bottom) {
            setIsBottom(false);
            setTimeout(() => {
              setIsBottom(bottom);
            }, 10);
          } else {
            setIsBottom(false);
          }

          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          if (scrollTop > 500) {
            setShowButton(true);
          } else if (scrollTop <= 500) {
            setShowButton(false);
          }

          if (scrollTop > lastScrollTop) {
            setIsScrollDown(true);
          } else {
            setIsScrollDown(false);
          }
          setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
        }
      }, 150)
    );
  }, []);

  return {
    isScrollDown,
    isBottom,
    showButton
  };
};

export default useScroll;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useState } from '@hookstate/core/dist';
import { $clone } from '../../../../../vodea/utilities';
import { useNavigate, useParams } from 'react-router-dom';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import _ from 'lodash';
import { InformationInputs, informationSchema, ProductInputs, productSchema, InformationBaseModel, ProductBaseModel } from './validation';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faReceipt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import SupplierRepository from '../../../../../repositories/SupplierRepository';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import PurchaseOrderRepository from '../../../../../repositories/PurchaseOrderRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import ProductRepository from '../../../../../repositories/ProductRepository';
import UnitRepository from '../../../../../repositories/UnitRepository';
import StorageRepository from '../../../../../repositories/StorageRepository';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../stores';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import { taxOptions } from '../../../../../constants';

const PurchaseOrderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const isClosed = useState(false);

  const title = id ? t('Edit Purchase Order') : t('Create Purchase Order');

  const { user } = useSelector((state: RootState) => {
    return state.system;
  });

  const informationData = useState({
    number: '',
    dueDate: ''
  });

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line

  const getData = () => {
    PurchaseOrderRepository.show(id, {
      with: ['user', 'supplier', 'purchaseOrderItems.product', 'purchaseOrderItems.unit', 'purchaseOrderItems.storage']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        isClosed.set(data.is_close && data.is_close == 1);

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));

          if (formattedData?.purchase_order_items?.length > 0) {
            formattedData.purchase_order_items.forEach((item: any, key: number) => {
              formattedData.purchase_order_items[key] = formatSetValueForm(
                {
                  ...ProductBaseModel,
                  id,
                  ...{
                    quantity: 0,
                    price: 0,
                    discount: 0
                  }
                },
                item
              );
            });
          }
        }

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else if (name === 'is_ppn') {
            const selectedTax = taxOptions.find(item => item.value === value);
            informationSetValue(name, selectedTax);
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      supplier_id: user.role_user.role_id === '4' ? user.supplier : null,
      date: moment(),
      term_of_payment: 15
    }
  });

  const watchOrderDate = informationWatch('date');
  const watchTermOfPayment = informationWatch('term_of_payment');
  const watchSupplier = informationWatch('supplier_id');

  useEffect(() => {
    if (moment.isMoment(watchOrderDate)) {
      informationData.dueDate.set(moment($clone(watchOrderDate)).add(watchTermOfPayment, 'day').format('D MMMM YYYY'));
    }
  }, [watchOrderDate, watchTermOfPayment]); // eslint-disable-line

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    formData['is_ppn'] = Boolean(Number(formData['is_ppn'].value));

    Object.assign(formData, {
      purchase_order_items: [],
      with: ['purchaseOrderItems']
    });

    if (formData.supplier_id === '4') {
      delete formData.supplier_id;
    }

    if (watchProductItems.length !== 0) {
      watchProductItems.forEach((item: any, key: number) => {
        formData.purchase_order_items[key] = formatFormData(item);
      });
    }

    (id ? PurchaseOrderRepository.update(id, formData) : PurchaseOrderRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/purchase/purchase-order');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const showModalProduct = useState(false);
  const productLoading = useState(false);
  const {
    handleSubmit: productHandleSubmit,
    setValue: productSetValue,
    errors: productErrors,
    reset: productReset,
    control: productControl,
    watch: productWatch,
    getValues: productGetValues
  } = useForm<ProductInputs>({
    resolver: yupResolver(productSchema),
    defaultValues: {
      id: 0,
      product_id: null,
      unit_id: null,
      storage_id: null
    }
  });

  const watchProduct = productWatch('product_id');

  const onProductSubmit = productHandleSubmit(data => {
    const tempData = {
      ...data
    };

    if (productModalIndex.get() === -1) {
      productAppend(tempData);
    } else {
      productInsert(productModalIndex.get() + 1, tempData);
      productRemove(productModalIndex.get());
    }

    showModalProduct.set(false);
  });

  // Product
  const productModalIndex = useState(-1);

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove,
    insert: productInsert
  } = useFieldArray({
    keyName: 'key',
    name: 'purchase_order_items',
    control: informationControl
  });

  const watchProductItems = informationWatch('purchase_order_items');

  const summary = useMemo(() => {
    const tempSummary = {
      subtotal: 0,
      discount: 0,
      total: 0,
      payment: 0,
      balance: 0
    };

    if (watchProductItems.length !== 0) {
      watchProductItems.forEach((value: ProductInputs, index: number) => {
        tempSummary.subtotal += _.get(value, 'price', 0) * 1 * (_.get(value, 'quantity', 0) * 1);
        tempSummary.discount += _.get(value, 'discount', 0) * 1;
      });
    }

    tempSummary.total = tempSummary.subtotal - tempSummary.discount;
    tempSummary.balance = tempSummary.total - tempSummary.payment;

    return tempSummary;
  }, [watchProductItems]);

  const productColumns = [
    {
      dataField: 'product_id.name',
      text: 'Name'
    },
    {
      dataField: 'price',
      text: 'Price',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'unit_id.name',
      text: 'Unit'
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      formatter: (cell: any) => {
        return <NumberFormat defaultValue={cell} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />;
      }
    },
    {
      dataField: 'discount',
      text: 'Discount',
      formatter: (cell: any) => {
        return <VuiNumberFormat className='text-danger' data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'total',
      text: 'Total',
      isDummyField: true,
      formatter: (cell: any, row: any) => {
        const total = row.price * row.quantity - row.discount;
        return <VuiNumberFormat data={total} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleProductModal(true, rowIndex)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handleProductClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const productDeleteIndex = useState(0);
  const showProductDeleteModal = useState(false);

  const handleProductClickDelete = (index: number) => {
    productDeleteIndex.set(index);
    showProductDeleteModal.set(true);
  };

  const handleProductDelete = () => {
    productRemove(productDeleteIndex.get());
    showProductDeleteModal.set(false);
    productDeleteIndex.set(0);
  };

  const handleProductModal = (show: boolean, index: number = -1) => {
    productReset();
    showModalProduct.set(show);
    productModalIndex.set(index);

    if (index !== null) {
      const selectedData = watchProductItems[index];

      setTimeout(() => {
        productSetValue('id', _.get(selectedData, 'id'));
        productSetValue('product_id', _.get(selectedData, 'product_id'));
        productSetValue('unit_id', _.get(selectedData, 'unit_id'));
        productSetValue('storage_id', _.get(selectedData, 'storage_id'));
        productSetValue('quantity', _.get(selectedData, 'quantity'));
        productSetValue('price', _.get(selectedData, 'price'));
        productSetValue('discount', _.get(selectedData, 'discount'));
      }, 500);
    }
  };

  const breadcrumbList = [
    {
      label: t('Purchase'),
      link: '/purchase'
    },
    {
      label: t('Purchase Order'),
      link: '/purchase/purchase-order'
    },
    {
      label: id ? id : t('Create'),
      link: '/purchase/purchase-order/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div id={'purchase-section'}>
        <div className={'page-header-component'}>
          <h3 className={'title'}>{title}</h3>

          {id ? (
            <div className='action-wrapper'>
              <button type={'button'} className={'btn btn-success action-item'}>
                <FontAwesomeIcon className={'icon icon-prefix'} icon={faReceipt} />
                {t('Invoice')}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div id={'return-item-page'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'page-layout'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true
                      })}
                    >
                      <label className={'form-label'}>Number</label>
                      <input type='text' className='form-control' disabled value={informationData.number.get()} />
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.supplier_id
                      })}
                    >
                      <label className={'form-label'}>Supplier</label>
                      <div className={'form-with-button-wrapper'}>
                        <div className={'form-input-wrapper'}>
                          <Controller
                            name={'supplier_id'}
                            control={informationControl}
                            render={props => (
                              <VuiSelect
                                selectRepository={SupplierRepository}
                                defaultValue={props.value}
                                onChange={props.onChange}
                                isDisabled={user.role_user.role_id === '4'}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.supplier_id, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.is_ppn
                      })}
                    >
                      <label className={'form-label'}>Pajak</label>
                      <div className={'form-with-button-wrapper'}>
                        <div className={'form-input-wrapper'}>
                          <Controller
                            name={'is_ppn'}
                            control={informationControl}
                            defaultValue={taxOptions[0]}
                            render={props => (
                              <VuiSelect
                                options={taxOptions}
                                optionKey='value'
                                labelKey='label'
                                defaultValue={props.value}
                                onChange={props.onChange}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.is_ppn, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.date
                      })}
                    >
                      <label className={'form-label'}>Date</label>
                      <Controller
                        name={'date'}
                        control={informationControl}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                        )}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.term_of_payment
                      })}
                    >
                      <label className={'form-label'}>Term of Payment</label>
                      <div className={'form-select-wrapper type-with-button'}>
                        <Controller
                          name={'term_of_payment'}
                          control={informationControl}
                          render={props => (
                            <NumberFormat
                              className='form-control'
                              value={props.value}
                              allowNegative={false}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                              onValueChange={({ value }) => props.onChange(value)}
                            />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.term_of_payment, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true
                      })}
                    >
                      <label className={'form-label'}>Due Date</label>
                      <input type='text' className='form-control' readOnly value={informationData.dueDate.get()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='multiple-paper-section'>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Product List</h6>
                </div>
                <div className={'card-content'}>
                  {watchSupplier !== null ? (
                    <>
                      <BootstrapTable keyField='key' data={productFields} columns={productColumns} />

                      <button type={'button'} className={'btn btn-primary btn-small'} onClick={e => handleProductModal(true)}>
                        <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                        {t('Add Product')}
                      </button>
                    </>
                  ) : (
                    <div className='card-empty-content'>please select supplier first</div>
                  )}
                </div>
              </div>

              <div className={'card-paper'} hidden>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Return Item List</h6>
                </div>
                <div className={'card-content'}></div>
              </div>

              <div className={'card-paper'} hidden>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Payment</h6>
                </div>
                <div className={'card-content'}></div>
              </div>

              <div className='mt-4'>
                <div className='row flex-row-reverse'>
                  <div className={'col-md-6'}>
                    <div className={'card-paper'}>
                      <div className={'card-header'}>
                        <h6 className={'card-header-title'}>Summary</h6>
                      </div>

                      <div className={'card-content'}>
                        <table className='table-summary type-right'>
                          <tbody>
                            <tr>
                              <td>Subtotal</td>
                              <td>
                                <VuiNumberFormat value={summary.subtotal} data={summary.subtotal} prefix={'IDR '} />
                              </td>
                            </tr>
                            <tr>
                              <td className='text-danger'>Discount</td>
                              <td>
                                <VuiNumberFormat
                                  className='text-danger'
                                  value={summary.discount}
                                  data={summary.discount}
                                  prefix={'IDR '}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td>
                                <VuiNumberFormat value={summary.total} data={summary.total} prefix={'IDR '} />
                              </td>
                            </tr>
                            <tr>
                              <td>Payment</td>
                              <td>
                                <VuiNumberFormat value={summary.payment} data={summary.payment} prefix={'IDR '} />
                              </td>
                            </tr>
                            <tr>
                              <td>Balance</td>
                              <td>
                                <VuiNumberFormat value={summary.balance} data={summary.balance} prefix={'IDR '} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isClosed.get() && <VuiActionForm loading={informationLoading.get()} cancelLink={'/purchase/purchase-order'} />}
        </form>
      </div>

      <Modal show={showModalProduct.get()} onHide={() => showModalProduct.set(false)} backdrop={'static'} centered size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title>{productModalIndex.get() === -1 ? t('Add Product') : t('Edit Product')}</Modal.Title>
        </Modal.Header>

        <form className={'form-wrapper'} onSubmit={onProductSubmit}>
          <Modal.Body>
            <div className={'row'}>
              <Controller
                name={'id'}
                control={productControl}
                defaultValue={0}
                render={props => <input type={'hidden'} defaultValue={props.value} />}
              />

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.product_id
                  })}
                >
                  <label className={'form-label'}>Product</label>
                  <Controller
                    name={'product_id'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <VuiSelect
                        selectParams={{
                          for: 'purchase-order'
                          //supplier: _.get(watchSupplier, 'id')
                        }}
                        selectRepository={ProductRepository}
                        defaultValue={props.value}
                        onChange={val => {
                          props.onChange(val);
                          productSetValue('unit_id', null);
                          productSetValue('storage_id', null);
                        }}
                        isMulti={false}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(productErrors.product_id, 'message')}</label>
                </div>
              </div>
              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.unit_id
                  })}
                >
                  <label className={'form-label'}>Unit</label>
                  <Controller
                    name={'unit_id'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <VuiSelect
                        propKey={_.get(watchProduct, 'id')}
                        selectParams={{
                          for: 'purchase-order',
                          product: _.get(watchProduct, 'id')
                        }}
                        selectRepository={UnitRepository}
                        defaultValue={props.value}
                        onChange={val => {
                          props.onChange(val);
                        }}
                        isMulti={false}
                        isDisabled={watchProduct === null}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(productErrors.unit_id, 'message')}</label>
                </div>
              </div>

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.storage_id
                  })}
                >
                  <label className={'form-label'}>Storage</label>
                  <Controller
                    name={'storage_id'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <VuiSelect
                        propKey={_.get(watchProduct, 'id')}
                        selectParams={{
                          for: 'purchase-order',
                          product: _.get(watchProduct, 'id')
                        }}
                        selectRepository={StorageRepository}
                        defaultValue={props.value}
                        onChange={val => {
                          props.onChange(val);
                        }}
                        isMulti={false}
                        isDisabled={watchProduct === null}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(productErrors.storage_id, 'message')}</label>
                </div>
              </div>

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.quantity
                  })}
                >
                  <label className={'form-label'}>Quantity</label>
                  <Controller
                    name={'quantity'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative={false}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        placeholder={'0'}
                        onValueChange={({ value }) => props.onChange(value)}
                      />
                    )}
                  />
                  <label className={'label-help'}>{productErrors.quantity?.message}</label>
                </div>
              </div>
              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.price
                  })}
                >
                  <label className={'form-label'}>Price / Unit</label>
                  <Controller
                    name={'price'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative={false}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        placeholder={'IDR 0'}
                        prefix={'IDR '}
                        onValueChange={({ value }) => props.onChange(value)}
                      />
                    )}
                  />
                  <label className={'label-help'}>{productErrors.price?.message}</label>
                </div>
              </div>
              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.discount
                  })}
                >
                  <label className={'form-label'}>Discount</label>
                  <Controller
                    name={'discount'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative={false}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        placeholder={'IDR 0'}
                        prefix={'IDR '}
                        onValueChange={({ value }) => props.onChange(value)}
                      />
                    )}
                  />
                  <label className={'label-help'}>{productErrors.discount?.message}</label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'light'} onClick={() => showModalProduct.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={productLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal
        show={showProductDeleteModal.get()}
        onHide={() => showProductDeleteModal.set(false)}
        onContinue={handleProductDelete}
      />
    </>
  );
};

export default PurchaseOrderForm;

export const defaultRoleOptions = [
  {
    id: 1,
    name: 'Admin'
  },
  {
    id: 2,
    name: 'Cashier'
  },
  {
    id: 3,
    name: 'Employee'
  },
  {
    id: 4,
    name: 'Supplier'
  }
];

export const defaultStatusOptions = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 0,
    name: 'Inactive'
  }
];

export const purchaseOrderPaymentMethod = [
  {
    id: 1,
    name: 'Cash'
  },
  {
    id: 2,
    name: 'Bank Transfer'
  },
  {
    id: 3,
    name: 'E-Wallet'
  }
];

export const salesOrderChannelOptions = [
  {
    id: 1,
    name: 'Offline'
  },
  {
    id: 2,
    name: 'Online'
  }
];

export const salesOrderItemTypeOptions = [
  {
    id: 3,
    name: 'Normal'
  }
  /*{
    id: 4,
    name: 'Redeem'
  },*/
];

export const salesOrderStatusOptions = [
  {
    id: 17,
    name: 'Active'
  },
  {
    id: 18,
    name: 'Paid'
  },
  {
    id: 19,
    name: 'Closed'
  }
];

export const salesReturnItemTypeOptions = [
  {
    id: 5,
    name: 'Replacement'
  },
  {
    id: 6,
    name: 'Refund'
  }
];

export const salesReturnItemStockTreatOptions = [
  {
    id: 7,
    name: 'Return'
  },
  {
    id: 8,
    name: 'Throw'
  },
  {
    id: 9,
    name: 'Claim'
  }
];

export const goodsReceiptDifferenceTypeOptions = [
  {
    id: 13,
    name: 'Normal'
  },
  {
    id: 14,
    name: 'Defect'
  }
];

export const goodsReceiptDifferenceTreatOptions = [
  {
    id: 15,
    name: 'Normal'
  },
  {
    id: 16,
    name: 'Cut Invoice'
  }
];

export const monthOptions = [
  {
    id: 1,
    name: 'January'
  },
  {
    id: 2,
    name: 'February'
  },
  {
    id: 3,
    name: 'March'
  },
  {
    id: 4,
    name: 'April'
  },
  {
    id: 5,
    name: 'May'
  },
  {
    id: 6,
    name: 'June'
  },
  {
    id: 7,
    name: 'July'
  },
  {
    id: 8,
    name: 'August'
  },
  {
    id: 9,
    name: 'September'
  },
  {
    id: 10,
    name: 'October'
  },
  {
    id: 11,
    name: 'November'
  },
  {
    id: 12,
    name: 'December'
  }
];

export const paymentMethodOptions = [
  { id: 1, name: 'Cash' },
  { id: 2, name: 'Transfer' }
];

export const defaultChannelOptions = [
  { id: '1', name: 'Offline' },
  { id: '2', name: 'Online' }
];

export const taxOptions = [
  {
    label: 'Non PPN',
    value: '0'
  },
  {
    label: 'PPN',
    value: '1'
  }
];

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { useState } from '@hookstate/core/dist';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import * as _ from 'lodash';
import moment from 'moment';
import { $clone } from '../../../../vodea/utilities';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { LoadingIndication, NoDataIndication } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import ReportRepository from '../../../../repositories/ReportRepository';
import BootstrapTable from 'react-bootstrap-table-next';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import { Bar } from 'react-chartjs-2';
import VuiSummaryCard from '../../../../vodea/@vodea-ui/components/SummaryCard';
import { Col, Row } from 'react-bootstrap';
import WidgetRepository from '../../../../repositories/WidgetRepository';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import { Link } from 'react-router-dom';
import ModalDetailDelivery from '../DeliveryDriver/ModalDetailDelivery';
import ModalDetailSalesRevenue from './ModalDetailSalesRevenue';

const SalesRevenue = () => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [salesId, setSalesId] = React.useState();
  const tableConfig = useState({
    sorted_by: 'desc',
    order_by: 'name',

    // additional
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const [tableLoading, setTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [totalCigaretteSales, setTotalCigaretteSales] = React.useState(0);
  const [totalNonCigaretteSales, setTotalNonCigaretteSales] = React.useState(0);

  const tableColumns: any[] = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'total_sales_order',
      text: 'Total Sales Order'
    },
    {
      dataField: 'total_cigarette_order',
      text: 'Total Rokok',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={Number(cell)} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'total_non_cigarette_order',
      text: 'Total Non-Rokok',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={Number(cell)} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell: any, row: any) => {
        return (
          <div className={'table-btn-wrapper'}>
            <div
              className={'btn btn-primary btn-small'}
              onClick={() => {
                setSalesId(row.id);
                setIsOpenModal(true);
              }}
            >
              View
            </div>
          </div>
        );
      }
    }
  ];

  const getTableData = useCallback(async () => {
    setTableData([]);
    setTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    await ReportRepository.salesRevenue(params)
      .then((response: AxiosResponse) => {
        setTableData(response.data.data);
        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        setTableLoading(false);
      });
  }, [tableConfig.get()]);

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData().then();
    getTotalCigaretteSales().then();
    getTotalNonCigaretteSales().then();
  };

  const getTotalCigaretteSales = useCallback(async () => {
    const conf = _.omit($clone(tableConfig.value), ['sorted_by', 'order_by']);

    const params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    await WidgetRepository.cigaretteSales(params)
      .then((response: AxiosResponse) => {
        setTotalCigaretteSales(response.data.total_cigarette_sales);
      })
      .catch((err: AxiosError) => {});
  }, [tableConfig.get()]);

  const getTotalNonCigaretteSales = useCallback(async () => {
    const conf = _.omit($clone(tableConfig.value), ['sorted_by', 'order_by']);

    const params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    await WidgetRepository.nonCigaretteSales(params)
      .then((response: AxiosResponse) => {
        setTotalNonCigaretteSales(response.data.total_non_cigarette_sales);
      })
      .catch((err: AxiosError) => {});
  }, [tableConfig.get()]);

  useMemo(() => {
    getTableData().then();
    getTotalCigaretteSales().then();
    getTotalNonCigaretteSales().then();
  }, []);

  const breadcrumbList = [
    {
      label: 'Report',
      link: '/report'
    },
    {
      label: 'Sales',
      link: '/report/sales'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Sales')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Sales')}</h3>
      </div>

      <ModalDetailSalesRevenue
        open={isOpenModal}
        salesId={salesId}
        params={{
          date_to: moment(tableConfig.date_to.get()).format('YYYY-MM-DD'),
          date_from: moment(tableConfig.date_from.get()).format('YYYY-MM-DD')
        }}
        onClose={() => {
          setIsOpenModal(false);
          setSalesId(undefined);
        }}
      />

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special'}>
              <VuiDateRangePicker
                startDate={tableConfig.date_from.get()}
                endDate={tableConfig.date_to.get()}
                callback={handleTableDateRange}
                useRanges={true}
              />
            </div>
          </div>

          {tableData.length > 0 && (
            <div className='mb-md-4 mb-sm-3'>
              <Bar
                data={{
                  labels: tableData.map((data: any) => data?.name),
                  datasets: [
                    {
                      label: 'Total',
                      data: tableData.map((data: any) => data?.grand_total),
                      backgroundColor: '#346497'
                    }
                  ]
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: false
                  }
                }}
              />
            </div>
          )}

          {permissions.includes('widgetcigarette-sales') || permissions.includes('widgetnon-cigarette-sales') ? (
            <Row className='mb-md-4 mb-sm-3'>
              {permissions.includes('widgetcigarette-sales') && (
                <Col md={3} xs={12}>
                  <VuiSummaryCard data={totalCigaretteSales} tooltipText={'Omset Penjualan'} title={'Total Rokok'} />
                </Col>
              )}

              {permissions.includes('widgetnon-cigarette-sales') && (
                <Col md={3} xs={12}>
                  <VuiSummaryCard data={totalNonCigaretteSales} tooltipText={'Omset Penjualan'} title={'Total Non-Rokok'} />
                </Col>
              )}
            </Row>
          ) : null}

          <BootstrapTable
            wrapperClasses={'table-responsive'}
            keyField={'id'}
            data={tableData}
            columns={tableColumns}
            noDataIndication={() => (tableLoading ? <LoadingIndication /> : <NoDataIndication />)}
          />
        </div>
      </div>
    </>
  );
};

export default SalesRevenue;

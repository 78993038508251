// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone, defaultActionFormatter } from '../../../../vodea/utilities';
import { useLocation } from 'react-router-dom';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import CutOffRepository from '../../../../repositories/CutOffRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { padLeadingZeros, showToast } from '../../../../vodea/utilities/helpers/global';
import moment from 'moment';
import _ from 'lodash';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import { Button, Modal } from 'react-bootstrap';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InformationInputs, informationSchema } from './validation';
import clsx from 'clsx';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { monthOptions } from '../../../../constants';
import Cleave from 'cleave.js/react';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';

const FinancePayroll: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    CutOffRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const title = t('Payroll');

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'period',
    total: 0,
    loading: false,

    // additional
    date_from: moment().startOf('year').format('YYYY-MM-DD'),
    date_to: moment().endOf('year').format('YYYY-MM-DD')
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'period',
      text: 'Period',
      render: (cell: any) => {
        return moment(cell).format('MMMM YYYY');
      }
    },
    {
      dataField: 'status',
      text: 'Status'
    },
    {
      dataField: 'created_at',
      text: 'Created',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY hh:mm A');
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      render: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset && isMounted.current) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), ['loading', 'total']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await CutOffRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) {
            setTotalData(response.data.total_data);
          }
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (e && e.message !== 'all canceled' && isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
          changeTableLoading(false);
        }
      });
  };

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData(true);
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const formLoading = useState(false);
  const showFormModal = useState(false);

  const month = Number(moment().format('M'));
  const thisMonth = _.find(monthOptions, function (o) {
    return o.id === month;
  });

  const { handleSubmit: informationHandleSubmit, control: informationControl, errors: informationErrors } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      month: thisMonth,
      year: moment().format('YYYY')
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    formLoading.set(true);

    const month = padLeadingZeros(_.get(data, 'month.id', 0), 2);
    const period = `${data.year}-${month}-01`;

    const formData = {
      period: period
    };

    CutOffRepository.create(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        formLoading.set(false);
        showFormModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          showToast(e.response.data.message, 'error');
        }
      });
  });

  const openFormModal = () => {
    showFormModal.set(true);
  };

  const breadcrumbList = [
    {
      label: t('Finance'),
      link: '/finance'
    },
    {
      label: t('Payroll'),
      link: '/finance/payroll'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
        <div className={'btn-wrapper'}>
          <button type={'button'} className={'btn btn-primary'} onClick={() => openFormModal()}>
            <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
            {t('Generate Payroll')}
          </button>
        </div>
      </div>

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special type-search'}>
              <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
            </div>

            <div className={'filter-item filter-special'}>
              <VuiDateRangePicker
                startDate={tableConfig.date_from.get()}
                endDate={tableConfig.date_to.get()}
                callback={handleTableDateRange}
                useRanges={true}
              />
            </div>
          </div>

          <DataTableInfiniteScroll
            columns={tableColumns}
            data={tableData.get()}
            propKey={'id'}
            isLoading={tableConfig.loading.get()}
            isFetchingMoreData={isFetchingMoreData}
            totalData={totalData}
          />
        </div>
      </div>

      <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />

      <Modal show={showFormModal.get()} onHide={() => showFormModal.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>Generate Payroll</Modal.Title>
        </Modal.Header>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <Modal.Body>
            <div
              className={clsx({
                'form-group': true,
                error: informationErrors.month
              })}
            >
              <label className={'form-label'}>Month</label>
              <Controller
                name={'month'}
                control={informationControl}
                render={props => <VuiSelect options={monthOptions} defaultValue={props.value} onChange={props.onChange} />}
              />
              <label className={'label-help'}>{_.get(informationErrors.month, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: informationErrors.year
              })}
            >
              <label className={'form-label'}>Year</label>
              <Controller
                name={'year'}
                control={informationControl}
                render={({ value, onChange }) => (
                  <Cleave
                    value={value}
                    className='form-control'
                    placeholder={'2020'}
                    options={{
                      date: true,
                      datePattern: ['Y']
                    }}
                    onChange={onChange}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(informationErrors.year, 'message')}</label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showFormModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={formLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FinancePayroll;

import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  show: function (params: any = null) {
    return api.get(`${endPoint()}/api/account/notification`, {
      params
    });
  },
  getSummary: function (params: any = null) {
    return api.get(`${endPoint()}/api/account/notification-summary`, {
      params
    });
  }
};

export default Repository;

import * as yup from 'yup';
import 'yup-phone';

export interface InformationInputs {
  id?: number;
  code: string;
  name: string;
  parent_id?: any;
  is_supplier?: boolean;
  is_special?: boolean;
}

export interface Props {
  // title: string;
  // cancelUrl: string;
  // afterSubmitUrl: string;
  data: InformationInputs;
}

// export type InformationInputs = {
//   name: string;
//   is_visible: object;
// };

export const informationSchema = yup.object().shape({
  name: yup.string().required().label('name'),
  code: yup.string().required().label('code')
});

export const InformationBaseModel: InformationInputs = {
  code: '',
  name: '',
  parent_id: null
};

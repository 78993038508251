// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { VuiButton } from '../VuiButton';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { useState } from '@hookstate/core/dist';
import { MenuChildrenProps, MenuStructure, VuiSidebarProps } from './types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';

const compareMenuWithPermission = (sections: any, permissions: any, isSupplier: boolean) => {
  const response: typeof MenuStructure = [];
  sections.forEach((menu: any) => {
    const menus: typeof MenuStructure = [];
    if (menu.children) {
      const children: MenuChildrenProps[] = [];

      menu.children.forEach((child: any, childIndex: any) => {
        menu.permissions = [
          ...(Array.isArray(menu.permissions) ? menu.permissions : [menu.permissions]),
          ...(Array.isArray(child.permissions) ? child.permissions : [child.permissions])
        ];

        if (
          child.permissions === 'any' ||
          (typeof child.permissions === 'string' && permissions.includes(child.permissions)) ||
          (Array.isArray(child.permissions) && child.permissions.filter((x: string) => permissions.includes(x)).length)
        ) {
          children.push(child);
        }
      });

      menu.children = children;
    }

    if (menu.permissions === 'any') {
      if (!isSupplier) {
        menus.push(menu);
      }
    } else if (typeof menu.permissions === 'string' && permissions.includes(menu.permissions)) {
      menus.push(menu);
    } else if (Array.isArray(menu.permissions) && menu.permissions.filter((x: string) => permissions.includes(x)).length) {
      menus.push(menu);
    }

    if (menus.length) {
      response.push(menu);
    }
  });

  return response;
};

const VuiSidebar: React.FC<VuiSidebarProps> = ({ show, onClose }) => {
  const location = useLocation();
  const pathname = _.split(location.pathname, '/');
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const isSupplier = user.role_user.role_id === '4';
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const sections = compareMenuWithPermission(MenuStructure, permissions, isSupplier);

  const sidebarHeaderHeight = useState(0);
  const sidebarFooterHeight = useState(0);
  const sidebarHeaderRef = useRef<HTMLHeadingElement>(null);
  const sidebarFooterRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (sidebarHeaderRef.current) {
      sidebarHeaderHeight.set(sidebarHeaderRef?.current?.offsetHeight);
    }

    if (sidebarFooterRef.current) {
      sidebarFooterHeight.set(sidebarFooterRef?.current?.offsetHeight);
    }
  }, [sidebarHeaderRef, sidebarFooterRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkActiveRoutes = (routes: Array<string>) => {
    return _.difference(routes, pathname).length !== routes.length;
  };

  return (
    <>
      <div
        className={clsx([
          'app-backdrop',
          {
            show: show
          }
        ])}
        onClick={onClose}
      />

      <div
        className={clsx([
          'app-sidebar',
          {
            'show-mobile': show
          }
        ])}
      >
        <div className={'sidebar-main-wrapper'}>
          {!isSupplier ? (
            <div className={'app-sidebar-header'} ref={sidebarHeaderRef}>
              <Link to={'/sales/sales-order/create'}>
                <VuiButton label={'Create Sales Order'} loading={false} fullWidth={true} />
              </Link>
              <hr />
            </div>
          ) : null}

          <div
            className={'app-sidebar-content'}
            style={{
              height: `calc(100% - ${sidebarFooterHeight.get()}px - ${sidebarHeaderHeight.get()}px)`
            }}
          >
            <ul className={'main-nav-list'}>
              {sections.map((item, key) => {
                const isActive = checkActiveRoutes(item.activeRoutes);

                return (
                  <li
                    className={clsx([
                      'nav-item',
                      {
                        opened: isActive && item.children?.length
                      }
                    ])}
                    key={key}
                  >
                    <Link
                      to={item.link}
                      className={clsx([
                        'nav-link',
                        {
                          active: isActive && !item.children?.length
                        }
                      ])}
                    >
                      <span className={'icon-wrapper'}>
                        <FontAwesomeIcon className={'nav-icon'} icon={item.icon} />
                      </span>
                      <span className={'nav-text'}>{item.name}</span>
                    </Link>

                    {item.children?.length ? (
                      <div className={'nav-dropdown'}>
                        <ul className={'nav-dropdown-list'}>
                          {item.children.map((child, childKey) => {
                            return (
                              <li className={'nav-dropdown-item'} key={childKey}>
                                <Link
                                  to={child.link}
                                  className={clsx([
                                    'nav-dropdown-link',
                                    {
                                      active: checkActiveRoutes(child.activeRoutes)
                                    }
                                  ])}
                                >
                                  <span className={'nav-text'}>{child.name}</span>
                                  {child.badge && <label className={'nav-badge'}>{child.badge}</label>}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VuiSidebar;

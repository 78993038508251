// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone } from '../../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import PurchaseOrderRepository from '../../../../repositories/PurchaseOrderRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import _ from 'lodash';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import moment from 'moment';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import SupplierRepository from '../../../../repositories/SupplierRepository';
import UserRepository from '../../../../repositories/UserRepository';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import fileDownload from 'js-file-download';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';
import { taxOptions } from '../../../../constants';
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

const PurchaseOrder: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const isSupplier = user.role_user.role_id === '4';
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const loadingExport = useState(false)

  const handleExport = async () => {
    loadingExport.set(true)

    const conf = _.omit($clone(tableConfig.value), ['loading', 'total']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    PurchaseOrderRepository.export(params).then((response: any) => {
      fileDownload(response.data, 'purchase-order-report.xls');
      loadingExport.set(false)
    });
  };

  const approveAction = (id: number) => {
    PurchaseOrderRepository.approve(id)
      .then((Response: AxiosResponse) => {
        showToast(Response.data.message, Response.data.success ? 'success' : 'error');
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const rejectAction = (id: number) => {
    PurchaseOrderRepository.reject(id)
      .then((Response: AxiosResponse) => {
        showToast(Response.data.message, Response.data.success ? 'success' : 'error');
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    PurchaseOrderRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const title = t('Purchase Order');

  const selectedSupplier = useState(null);
  const selectedUser = useState(null);
  const selectedTax = useState(null);

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'date',
    total: 0,
    loading: false,

    // additional
    user: '',
    supplier: '',
    is_ppn: null,

    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'number',
      text: 'Number'
    },
    {
      dataField: 'date',
      text: 'Order Date',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY hh:mm A');
      }
    },
    {
      dataField: 'creator_name',
      text: 'Ordered By'
    },
    {
      dataField: 'supplier_name',
      text: 'Supplier'
    },
    {
      dataField: 'grand_total',
      text: 'Total',
      render: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'status_name',
      text: 'Status'
    },
    {
      dataField: 'action',
      text: 'Action',
      render: (cell: any, row: any, rowIndex: number) => {
        const isClose = row.is_close && row.is_close == 1
        return (
          <>
            <div className={'table-btn-wrapper mb-2'}>
              {permissions.includes('purchase-order.update') ? (
                <Link className={'btn btn-primary btn-small'} to={`${location.pathname}/${row.id}`}>
                  View
                </Link>
              ) : null}

              {permissions.includes('purchase-order.destroy') && !isClose ? (
                <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => tableOnClickDelete(row.id)}>
                  Delete
                </button>
              ) : null}
            </div>

            <div className={'table-btn-wrapper'}>
              {permissions.includes('purchase-order.approve') && row.can_approve ? (
                <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => approveAction(row.id)}>
                  Approve
                </button>
              ) : null}

              {permissions.includes('purchase-order.reject') && row.can_reject ? (
                <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => rejectAction(row.id)}>
                  Reject
                </button>
              ) : null}
            </div>
          </>
        );
      }
    }
  ];

  useEffect(() => {
    if (isSupplier) {
      selectedSupplier.set(user.supplier);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isSupplier) {
      params = {
        ...params,
        supplier: user.supplier.id
      };
    }

    if (isMounted.current && !forNextPage) tableData.set([]);

    await PurchaseOrderRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) setTotalData(response.data.total_data);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData(true);
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const breadcrumbList = [
    {
      label: t('Purchase'),
      link: '/purchase'
    },
    {
      label: t('Purchase Order'),
      link: '/purchase/purchase-order'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
        <div className={'d-flex'}>
          <div className={'action-wrapper'}>
            {permissions.includes('purchase-order.store') ? (
              <Link to={'/purchase/purchase-order/create'} className={'action-item'}>
                <button type={'button'} className={'btn btn-primary'}>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                  {t('Create Purchase Order')}
                </button>
              </Link>
            ) : null}
            {permissions.includes('report.purchase-order') ? (
              <VuiButton forSubmit={false} label={t('Export')} loading={loadingExport.get()} onClick={handleExport} />
            ) : null}
          </div>
        </div>
      </div>

      {permissions.includes('purchase-order.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>

              <div className={'filter-item filter-special'}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges={true}
                />
              </div>

              <div className={'filter-item'}>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    placeholder={'Select Ordered By'}
                    clearable={true}
                    selectRepository={UserRepository}
                    defaultValue={selectedUser.get()}
                    onChange={val => {
                      selectedUser.set(val);
                      tableConfig.user.set(_.get(val, 'id', null));
                      getTableData(true);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className={'filter-item'}>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    placeholder={'Select Supplier'}
                    clearable={true}
                    isDisabled={isSupplier}
                    selectRepository={SupplierRepository}
                    defaultValue={selectedSupplier.get()}
                    onChange={val => {
                      selectedSupplier.set(val);
                      tableConfig.supplier.set(_.get(val, 'id', null));
                      getTableData(true);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className={'filter-item'}>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    options={taxOptions}
                    optionKey='value'
                    labelKey='label'
                    defaultValue={selectedTax.get()}
                    onChange={val => {
                      selectedTax.set(val);
                      tableConfig.is_ppn.set(_.get(val, 'value', null));
                      getTableData(true);
                    }}
                  />
                </div>
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />
    </>
  );
};

export default PurchaseOrder;

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from '@hookstate/core/dist';
import { MediaProps } from '../../../../interfaces/media';
import VuiMediaModal from '../../Modal/VuiMediaModal';

interface Props {
  name: string;
  defaultValue: string;
  onChange: (content: any) => void;
}

const tinyApiKey = () => window._env_.REACT_APP_TINY_API_KEY;

const VuiTextEditor: React.FC<Props> = ({ name, defaultValue, onChange }) => {
  const modalShow = useState(false);
  const scopedValue = useState(defaultValue);

  const handleOpenDialog = () => {
    modalShow.set(true);
  };

  const mediaCallback = (medias: MediaProps[]) => {
    let imageHtml = '';

    medias.forEach(item => {
      imageHtml += `<img src="${item.url}" alt=""/>`;
    });

    scopedValue.set(scopedValue.get() + imageHtml);
  };

  const handleOnChange = (content: any, editor: any) => {
    scopedValue.set(content);
    onChange(content);
  };

  return (
    <>
      <Editor
        apiKey={tinyApiKey()}
        cloudChannel='5-stable'
        disabled={false}
        id={'uuid-' + name}
        init={{
          height: 500,
          menubar: false,
          branding: false,
          plugins: [
            'print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons'
          ],
          toolbar:
            'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange removeformat | charmap emoticons | insertfile customUploadImage media link | code',
          setup: function (editor) {
            editor.ui.registry.addButton('customUploadImage', {
              icon: 'image',
              onAction: function () {
                handleOpenDialog();
              }
            });
          }
        }}
        initialValue={defaultValue}
        value={scopedValue.get()}
        inline={false}
        onEditorChange={handleOnChange}
      />

      <VuiMediaModal multiple defaultValue={[]} callback={mediaCallback} show={modalShow.get()} onHide={() => modalShow.set(false)} />
    </>
  );
};

export default VuiTextEditor;

import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  mobile_phone: string;
  dob: object;
  photo_id: number | null;
};

export type PasswordInputs = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  mobile_phone: yup.string().phone('ID', true).required().label('phone number'),
  dob: yup.mixed().required().label('date of birth'),
  photo_id: yup.mixed()
});

export const passwordSchema = yup.object().shape({
  old_password: yup.string().min(8).required().label('current password'),
  new_password: yup.string().min(8).required().label('new password'),
  new_password_confirmation: yup
    .string()
    .oneOf([yup.ref('new_password'), null])
    .required()
    .label('confirm new password')
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  mobile_phone: '',
  dob: {},
  photo_id: null
};

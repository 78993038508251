import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './assets/sass/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { rootReducer } from './stores';
import './i18n/config';
// @ts-ignore
import { ToastContainer } from 'react-toastify';

const store = createStore(rootReducer, applyMiddleware(thunk));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>TukuBako</title>
        </Helmet>
        <ScrollToTop />
        <App />

        <ToastContainer />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from '@hookstate/core/dist';
import VuiHeaderNotification from '../../vodea/@vodea-ui/components/Header/VuiHeaderNotification';
import VuiHeaderProfile from '../../vodea/@vodea-ui/components/Header/VuiHeaderProfile';
import VuiSidebar from '../../vodea/@vodea-ui/components/VuiSidebar';
import VuiHeaderSearch from '../../vodea/@vodea-ui/components/Header/VuiHeaderSearch';
import { usePassportService } from '../../vodea/utilities/hooks';
import VuiLoadingScreen from '../../vodea/@vodea-ui/components/VuiLoadingScreen';
import UpButton from '../../vodea/@vodea-ui/components/UpButton';

const LayoutApp: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const loggedIn = useSelector((state: RootState) => state.system.loggedIn);

  const showSidebar = useState(false);

  if (isOnFetchingUser) return <VuiLoadingScreen />;

  if (!loggedIn) {
    return <Navigate to={'/auth/login'} replace />;
  }

  return (
    <section id={'app-layout'}>
      <div className={'app-header'}>
        <div className={'brand-wrapper'}>
          <div
            className={'mobile-menu'}
            onClick={() => {
              showSidebar.set(!showSidebar.get());
            }}
          >
            <FontAwesomeIcon className={'menu-icon'} icon={faBars as any} />
          </div>

          <Link to={'/home'}>
            <img className={'logo'} src={'/logo.png'} alt={'logo'} />
          </Link>
        </div>

        <div className={'navigation-wrapper'}>
          <div className={'navigation-item'}>
            <VuiHeaderSearch />
          </div>

          <div className={'navigation-item'}>
            <VuiHeaderNotification />
          </div>

          <div className={'navigation-item'}>
            <VuiHeaderProfile />
          </div>
        </div>
      </div>

      <div className={'app-header-separator'} />

      <VuiSidebar
        show={showSidebar.get()}
        onClose={() => {
          showSidebar.set(false);
        }}
      />

      <div className={'app-content'}>
        <div className={'app-container'}>
          <Outlet />
        </div>
      </div>

      <UpButton />
    </section>
  );
};

export default LayoutApp;

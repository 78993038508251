// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EventHandler } from 'react-bootstrap-daterangepicker';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import { useState } from '@hookstate/core/dist';
import VuiDataTable from '../../../../../vodea/@vodea-ui/components/VuiDataTable';
import { defaultActionFormatter } from '../../../../../vodea/utilities';
import { useLocation } from 'react-router-dom';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../stores';

const products = [
  {
    id: 1,
    date: '10 Dec 2020',
    numberSO: 'SO-2020-01',
    type: 'Out',
    out: '10',
    in: '0',
    balance: '1.200'
  },
  {
    id: 2,
    date: '14 Dec 2020',
    numberSO: 'SO-2020-02',
    type: 'In',
    out: '0',
    in: '1.000',
    balance: '1.310'
  }
];

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

const ProductStock: React.FC<any> = () => {
  const location = useLocation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const tableLoading = useState(false);
  const tablePage = useState(1);
  const tableSizePerPage = useState(1);
  const tableData = useState(products.slice(0, tableSizePerPage.get()));

  const tableColumns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true
    },
    {
      dataField: 'numberSO',
      text: 'Number',
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true
    },
    {
      dataField: 'out',
      text: 'Out',
      sort: true
    },
    {
      dataField: 'in',
      text: 'In',
      sort: true
    },
    {
      dataField: 'balance',
      text: 'Balance',
      sort: true
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        keyId: 'number'
      }
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    tableLoading.set(true);

    setTimeout(() => {
      tableData.set(products.slice(currentIndex, currentIndex + sizePerPage));
      tablePage.set(page);
      tableSizePerPage.set(sizePerPage);
      tableLoading.set(false);
    }, 500);
  };

  const breadcrumbList = [
    {
      label: 'Product',
      link: '/'
    }
  ];

  const datepickerEvent: EventHandler = (event, picker) => {
    if (event.type === 'apply') {
      const name = event.target.name;
      const startDate = picker.startDate.format('YYYY-MM-DD');
      const endDate = picker.endDate.format('YYYY-MM-DD');
    }
  };

  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>Teh Gelas</h3>
      </div>

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input type='text' className='form-control' placeholder='Search' />
              </div>
            </div>

            <div className={'filter-item filter-special'}>
              <VuiDateRangePicker name={'rangeDate'} event={datepickerEvent} />
            </div>

            <div className={'filter-item'}>
              <div className={'form-select-wrapper'}>
                <Select className='basic-single' classNamePrefix='select' isClearable={true} options={options} />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableLoading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tablePage.get()}
            sizePerPage={tableSizePerPage.get()}
            totalSize={products.length}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default ProductStock;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores';
import { useTranslation } from 'react-i18next';

const Home: React.FC<any> = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });

  return (
    <div className={'page-layout'}>
      <Helmet>
        <title>{t('Home')}</title>
      </Helmet>
      <h1 className={'subtitle'}>Hello, {user.name}</h1>
    </div>
  );
};

export default Home;

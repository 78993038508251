// @ts-nocheck
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import _ from 'lodash';
import TableMutation from './TableMutation';
import { Tab, Tabs } from 'react-bootstrap';
import TableProductMutation from './TableProduct';
import ModalDetailProductMutation from './ModalDetailProductMutation';
import moment from 'moment';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import ReportRepository from '../../../../repositories/ReportRepository';
import { AxiosError, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

const Mutation: React.FC<any> = () => {
  const { t } = useTranslation();
  const title = t('Mutation');
  const breadcrumbList = [
    {
      label: t('Inventory'),
      link: '/inventory'
    },
    {
      label: t('Mutation'),
      link: '/inventory/mutation'
    }
  ];

  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);

  const [currentTab, setCurrentTab] = React.useState<string>('GoodsReceipt');

  const [params, setParams] = React.useState({
    search: '',
    date_from: new Date(),
    date_to: new Date()
  });

  const handleTableSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setParams(node => ({ ...node, page: 1, search: e.target.value }));
  }, 300);
  const handleTableDateRange = (start: any, end: any) => {
    setParams(node => ({ ...node, date_from: start.format('YYYY-MM-DD'), date_to: end.format('YYYY-MM-DD') }));
  };

  const [openProductMutationModal, setOpenProductMutationModal] = React.useState(false);
  const [mutationProductId, setMutationProductId] = React.useState<number | undefined>();
  const [exportProductLoading, setExportProductLoading] = React.useState(false);

  const handleExportProduct = useCallback(async () => {
    setExportProductLoading(true);

    const formatParams = {
      ...params,
      date_from: moment(params.date_from).format('YYYY-MM-DD'),
      date_to: moment(params.date_to).format('YYYY-MM-DD')
    };

    await ReportRepository.exportProductMutation(formatParams)
      .then((response: AxiosResponse) => {
        fileDownload(response.data, 'product-mutation.xls');
      })
      .catch((err: AxiosError) => {})
      .finally(() => {
        setExportProductLoading(false);
      });
  }, [params]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
        {permissions.includes('report.product-mutation') && currentTab === 'Product' && (
          <div className={'btn-wrapper d-flex align-items-center'}>
            <VuiButton forSubmit={false} label={t('Export')} loading={exportProductLoading} onClick={handleExportProduct} />
          </div>
        )}
      </div>

      <ModalDetailProductMutation
        productId={mutationProductId}
        open={openProductMutationModal}
        params={{
          date_to: moment(params.date_to).format('YYYY-MM-DD'),
          date_from: moment(params.date_from).format('YYYY-MM-DD')
        }}
        onClose={() => {
          setOpenProductMutationModal(false);
          setMutationProductId(undefined);
        }}
      />

      {permissions.includes('warehouse-stock-log.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <div className='input-group prefix-group'>
                  <span className='input-group-text'>
                    <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                  </span>
                  <input
                    defaultValue={params.search}
                    type='text'
                    className='form-control'
                    placeholder='Search'
                    onChange={handleTableSearch}
                  />
                </div>
              </div>

              <div className={'filter-item filter-special'}>
                <VuiDateRangePicker
                  startDate={params.date_from}
                  endDate={params.date_to}
                  callback={handleTableDateRange}
                  useRanges={true}
                />
              </div>
            </div>

            <Tabs id='controlled-tab-example' activeKey={currentTab} onSelect={(k: any) => setCurrentTab(k)} className='mb-3'>
              <Tab eventKey='GoodsReceipt' title='Goods Receipt'>
                {currentTab === 'GoodsReceipt' && (
                  <TableMutation permissions={permissions} defaultParams={{ ...params, subject: 'GoodsReceiptItem' }} />
                )}
              </Tab>
              <Tab eventKey='SalesOrder' title='Sales Order'>
                {currentTab === 'SalesOrder' && (
                  <TableMutation permissions={permissions} defaultParams={{ ...params, subject: 'SalesOrderItem' }} />
                )}
              </Tab>
              <Tab eventKey='SalesReturn' title='Sales Return'>
                {currentTab === 'SalesReturn' && (
                  <TableMutation permissions={permissions} defaultParams={{ ...params, subject: 'SalesReturnItem' }} />
                )}
              </Tab>
              <Tab eventKey='StockOpname' title='Stock Opname'>
                {currentTab === 'StockOpname' && (
                  <TableMutation permissions={permissions} defaultParams={{ ...params, subject: 'StockOpnameProduct' }} />
                )}
              </Tab>
              <Tab eventKey='Product' title='Product'>
                {currentTab === 'Product' && (
                  <TableProductMutation
                    defaultParams={params}
                    onViewProduct={id => {
                      setMutationProductId(id);
                      setOpenProductMutationModal(true);
                    }}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Mutation;

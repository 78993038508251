import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/warehouse-stock-product`, {
      params
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/warehouse-stock-product/${id}`, {
      params
    });
  },
  showIn: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/warehouse-stock-product-in/${id}`, {
      params
    });
  }
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone, defaultActionFormatter } from '../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AttendanceRepository from '../../../repositories/AttendanceRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../vodea/utilities/helpers/global';
import _ from 'lodash';
import moment from 'moment';
import VuiActionModal from '../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import useIsMounted from '../../../vodea/utilities/hooks/useIsMounted';
import VuiDateRangePicker from '../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores';
import DataTableInfiniteScroll, { ITableColumn } from '../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import useScroll from '../../../vodea/utilities/hooks/useScroll';
import VuiSearch from '../../../vodea/@vodea-ui/components/VuiSearch';

const Attendance: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();

  const { isBottom } = useScroll();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const showImportModal = useState(false);
  const [importFile, setImportFile] = React.useState<Blob | null>(null);
  const fileName = useState('');
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableDeleteId = useState(0);
  const showActionModal = useState(false);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    AttendanceRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'date',
    total: 0,
    loading: false,

    // additional
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'employee_id',
      text: 'Employee ID'
    },
    {
      dataField: 'employee_name',
      text: 'Name'
    },
    {
      dataField: 'date',
      text: 'Date',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY');
      }
    },
    {
      dataField: 'time_in',
      text: 'Clock In',
      render: (cell: any) => {
        return moment(cell, 'HH:mm:ss').format('HH:mm');
      }
    },
    {
      dataField: 'time_out',
      text: 'Clock Out',
      render: (cell: any) => {
        return cell ? moment(cell, 'HH:mm:ss').format('HH:mm') : '-';
      }
    },
    {
      dataField: 'status',
      text: 'Status'
    },
    {
      dataField: 'action',
      text: 'Action',
      render: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete,
        permission: 'attendance',
        permissions: permissions
      }
    }
  ];

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await AttendanceRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) {
            setTotalData(response.data.total_data);
          }
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData(true);
  };

  const onImportAttendanceChange = (e: any) => {
    const tempImportFile = e.target.files[0];

    if (tempImportFile) {
      setImportFile(tempImportFile);
      fileName.set(tempImportFile.name);

      showImportModal.set(true);

      e.target.value = null;
    }
  };

  const onCancelImport = () => {
    showImportModal.set(false);
  };

  const importAttendance = () => {
    const formData = new FormData();

    if (importFile !== null) {
      formData.append('file', importFile);
    }

    AttendanceRepository.import(formData)
      .then((response: AxiosResponse) => {
        showImportModal.set(false);

        getTableData(true);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');

        changeTableLoading(false);
      });
  };

  const breadcrumbList = [
    {
      label: t('Attendance'),
      link: '/attendance'
    }
  ];

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  return (
    <>
      <Helmet>
        <title>{t('Attendance')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Attendance')}</h3>
        <div className={'action-wrapper'}>
          {permissions.includes('day-off.index') ? (
            <Link to={'/attendance/day-off'} className={'btn btn-warning action-item'}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faCalendarAlt} />
              {t('Day Off')}
            </Link>
          ) : null}

          {permissions.includes('attendance.import') ? (
            <label className={'btn btn-info action-item'}>
              <input
                className='d-none'
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={onImportAttendanceChange}
              />
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faFileUpload} />
              {t('Import Attendance')}
            </label>
          ) : null}

          {permissions.includes('attendance.store') ? (
            <Link to={'/attendance/create'} className={'btn btn-primary action-item'}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
              {t('Create Attendance')}
            </Link>
          ) : null}
        </div>
      </div>

      {permissions.includes('attendance.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>

              <div className={'filter-item filter-special'}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges={true}
                />
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />

      <VuiActionModal
        show={showImportModal.get()}
        onHide={() => onCancelImport()}
        title={'Are you sure want to import ' + fileName.get() + '?'}
        description={'current data will be replaced if there any duplicated data'}
        btnVariant={'primary'}
        onContinue={importAttendance}
      />
    </>
  );
};

export default Attendance;

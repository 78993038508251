import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  is_visible: object;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  is_visible: yup.mixed().required().label('status')
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  is_visible: {},
};

// @ts-nocheck
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import VuiNumberFormat from '../VuiNumberFormat';
import * as React from 'react';

type VuiSummaryCardProps = {
  data: string | number;
  isCurrency?: boolean;
  title: string;
  tooltipText?: string;
};

const VuiSummaryCard = ({ data, isCurrency = true, title, tooltipText }: VuiSummaryCardProps) => {
  return (
    <div className={'card-paper'}>
      <div className={'card-header'}>
        <h6 className={'card-header-title'}>{title}</h6>
        {tooltipText && (
          <OverlayTrigger
            key={`tooltip-overlay-gmv`}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-gmv`}>
                <i>{tooltipText}</i>
              </Tooltip>
            }
          >
            <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
          </OverlayTrigger>
        )}
      </div>
      <div className={'card-content'}>
        <h5>{isCurrency ? <VuiNumberFormat value={data} data={data} prefix={'IDR '} /> : <VuiNumberFormat value={data} data={data} />}</h5>
      </div>
    </div>
  );
};

export default VuiSummaryCard;

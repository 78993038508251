import * as yup from 'yup';

export type InformationInputs = {
    name: string;
    date: object;
};

export const informationSchema = yup.object().shape({
    name: yup.mixed().required(),
    date: yup.mixed().required().label('Date'),
});

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone } from '../../../../vodea/utilities';
import { useTranslation } from 'react-i18next';
import StockOpnameRepository from '../../../../repositories/StockOpnameRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import _ from 'lodash';
import moment from 'moment';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import CategoryRepository from '../../../../repositories/CategoryRepository';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { InformationInputs, informationSchema } from '../Product/Form/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import { Link, useLocation } from 'react-router-dom';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const StockOpname: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const location = useLocation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);

  const showImportModal = useState(false);
  const showSelectModal = useState(false);
  const [importFile, setImportFile] = React.useState<Blob | null>(null);
  const fileName = useState('');

  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);
  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'date',
    total: 0,
    loading: false,

    // additional
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'number',
      text: 'Number'
    },
    {
      dataField: 'creator_name',
      text: 'Name'
    },
    {
      dataField: 'total_product',
      text: 'Total Product'
    },
    {
      dataField: 'date',
      text: 'Date',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY');
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      render: (cell: any, row: any) => {
        return (
          <div className={'table-btn-wrapper mb-2'}>
            {permissions.includes('stock-opname.show') ? (
              <Link className={'btn btn-primary btn-small'} to={`${location.pathname}/${row.id}`}>
                View
              </Link>
            ) : null}
          </div>
        );
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await StockOpnameRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) setTotalData(response.data.total_data);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData(true);
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const onImportStockOpnameChange = (e: any) => {
    const tempImportFile = e.target.files[0];

    if (tempImportFile) {
      setImportFile(tempImportFile);
      fileName.set(tempImportFile.name);

      showImportModal.set(true);

      e.target.value = null;
    }
  };

  const onCancelImport = () => {
    showImportModal.set(false);
  };

  const { control: informationControl, watch: informationWatch } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema)
  });

  const selectedCategory = informationWatch('category_id');

  const importStockOpname = () => {
    const formData = new FormData();

    if (importFile !== null) {
      formData.append('file', importFile);
    }

    StockOpnameRepository.create(formData)
      .then((response: AxiosResponse) => {
        showImportModal.set(false);

        getTableData(true);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');

        changeTableLoading(false);
      });
  };

  const breadcrumbList = [
    {
      label: t('Inventory'),
      link: '/inventory'
    },
    {
      label: t('Stock Opname'),
      link: '/inventory/stock-opname'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Stock Opname')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Stock Opname')}</h3>
        <div className={'action-wrapper'}>
          <button className={'btn btn-warning action-item'} onClick={() => showSelectModal.set(true)}>
            {t('Download Recent')}
          </button>
          {permissions.includes('stock-opname.store') ? (
            <label className={'btn btn-info action-item'}>
              <input
                className='d-none'
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={onImportStockOpnameChange}
              />
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faFileUpload} />
              {t('Import Stock Opname')}
            </label>
          ) : null}
        </div>
      </div>

      {permissions.includes('stock-opname.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>

              <div className={'filter-item filter-special'}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges={true}
                />
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      {/*<VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} /> */}
      <Modal show={showSelectModal.get()} onHide={() => showSelectModal.set(false)} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Download Recent</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'form-select-wrapper'}>
            <label className={'form-label'}>Select Category</label>
            <Controller
              name={'category_id'}
              control={informationControl}
              defaultValue={null}
              render={props => (
                <VuiSelect selectRepository={CategoryRepository} defaultValue={props.value} onChange={props.onChange} isMulti={false} />
              )}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className={'btn btn-danger action-item'} onClick={() => showSelectModal.set(false)}>
            Close
          </button>
          <a
            target='_blank'
            className={'btn btn-primary action-item'}
            href={endPoint() + '/import-template/stock-opname?' + (selectedCategory?.id ? `category=${selectedCategory?.id}` : '')}
            onClick={() => showSelectModal.set(false)}
          >
            {t('Download')}
          </a>
        </Modal.Footer>
      </Modal>

      <VuiActionModal
        show={showImportModal.get()}
        onHide={() => onCancelImport()}
        title={'Are you sure want to import ' + fileName.get() + '?'}
        description={'current data will be replaced if there any duplicated data'}
        btnVariant={'primary'}
        onContinue={importStockOpname}
      />
    </>
  );
};

export default StockOpname;

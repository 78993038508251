// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone, defaultActionFormatter } from '../../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductRepository from '../../../../repositories/ProductRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import _ from 'lodash';
import moment from 'moment';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import CategoryRepository from '../../../../repositories/CategoryRepository';
import { defaultStatusOptions } from '../../../../constants';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import fileDownload from 'js-file-download';

const stockOptions = [
  { id: 0, name: 'Semua Stok', params: { 'has-stock': undefined, 'stock-warning': undefined } },
  { id: 1, name: 'Stok Tersedia', params: { 'has-stock': '1', 'stock-warning': undefined } },
  { id: 2, name: 'Stok Tidak Tersedia', params: { 'has-stock': '0', 'stock-warning': undefined } },
  { id: 3, name: 'Stok Dibawah Batas', params: { 'has-stock': undefined, 'stock-warning': '1' } }
];

const ProductList: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();
  const [totalGlobal, setTotalGlobal] = React.useState<number | string>(0);
  const [totalUnpaid, setTotalUnpaid] = React.useState<number | string>(0);
  const [totalPaid, setTotalPaid] = React.useState<number | string>(0);
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const selectedStock = useState(stockOptions[0]);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const selectedCategory = useState(null);

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'asc',
    order_by: 'name',
    total: 0,
    loading: false,

    // Additional
    category: ''
  });

  const tableData = useState([]);

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    ProductRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, 'success');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'sku',
      text: 'SKU'
    },
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'category_name',
      text: 'Category'
    },
    {
      dataField: 'supplier_name',
      text: 'Supplier'
    },
    {
      dataField: 'quantity',
      text: 'Stock',
      render: (cell: any, row: any) => {
        return (
          <NumberFormat
            defaultValue={cell || 0}
            displayType={'text'}
            allowNegative={true}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            suffix={` ${_.get(row, 'unit_name')}`}
          />
        );
      }
    },
    {
      dataField: 'profit',
      text: 'Profit',
      render: (cell: any) => {
        return `${cell}%`;
      }
    },
    {
      dataField: 'is_visible',
      text: 'Status',
      render: (cell: any) => {
        const selected = _.find(defaultStatusOptions, option => {
          return Number(option.id) === Number(cell);
        });

        return _.get(selected, 'name', '');
      }
    },
    {
      dataField: 'created_at',
      text: 'Created',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY h:m A');
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      render: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete,
        permission: 'product',
        permissions: permissions
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getCardData = (params: any, forNextPage: boolean = false) => {
    const parsingStringToNumber = (value: string | number) => (typeof value === 'string' ? parseInt(value) : value);

    if (permissions.includes('widgettotal-paid')) {
      ProductRepository.cardPaid(params).then((response: any) => {
        forNextPage
          ? setTotalPaid(prevState => parsingStringToNumber(prevState) + response.data.total_paid)
          : setTotalPaid(response.data.total_paid);
      });
    }

    if (permissions.includes('widgettotal-global')) {
      ProductRepository.cardGlobal(params).then((response: any) => {
        forNextPage
          ? setTotalGlobal(prevState => parsingStringToNumber(prevState) + response.data.total_global)
          : setTotalGlobal(response.data.total_global);
      });
    }

    if (permissions.includes('widgettotal-unpaid')) {
      ProductRepository.cardUnpaid(params).then((response: any) => {
        forNextPage
          ? setTotalUnpaid(prevState => parsingStringToNumber(prevState) + response.data.total_unpaid)
          : setTotalUnpaid(response.data.total_unpaid);
      });
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    getCardData(params, forNextPage);

    if (isMounted.current && !forNextPage) tableData.set([]);

    await ProductRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) setTotalData(response.data.total_data);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const loadingExport = useState(false);

  const handleExport = async () => {
    loadingExport.set(true);

    const conf = _.omit($clone(tableConfig.value), ['loading', 'total']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    ProductRepository.export(params).then((response: any) => {
      fileDownload(response.data, 'product-report.xls');
      loadingExport.set(false);
    });
  };

  const breadcrumbList = [
    {
      label: 'Inventory',
      link: '/inventory'
    },
    {
      label: 'Product',
      link: '/inventory/product'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Product')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Product')}</h3>
        <div className={'btn-wrapper d-flex align-items-center'}>
          <div className={'action-wrapper'}>
            {permissions.includes('product.store') ? (
              <Link to={'/inventory/product/create'} className={'action-item'}>
                <button type={'button'} className={'btn btn-primary'}>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                  {t('Create Product')}
                </button>
              </Link>
            ) : null}

            {permissions.includes('report.product') ? (
              <VuiButton forSubmit={false} label={t('Export')} loading={loadingExport.get()} onClick={handleExport} />
            ) : null}
          </div>
        </div>
      </div>

      <div className={'row'}>
        {permissions.includes('widgettotal-global') && (
          <div className={'col-md-3 mb-3'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Total Global</h6>
                <OverlayTrigger
                  key={`tooltip-overlay-gmv`}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-gmv`}>
                      <i>Berdasarkan dari seluruh total modal yang ada di produk</i>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </div>
              <div className={'card-content'}>
                <h5>
                  <VuiNumberFormat value={totalGlobal} data={totalGlobal} prefix={'IDR '} />
                </h5>
              </div>
            </div>
          </div>
        )}

        {permissions.includes('widgettotal-paid') && (
          <div className={'col-md-3 mb-3'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Total Paid</h6>
                <OverlayTrigger
                  key={`tooltip-overlay-gmv`}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-gmv`}>
                      <i>Berdasarkan dari total produk yang sudah terbayar</i>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </div>
              <div className={'card-content'}>
                <h5>
                  <VuiNumberFormat value={totalPaid} data={totalPaid} prefix={'IDR '} />
                </h5>
              </div>
            </div>
          </div>
        )}

        {permissions.includes('widgettotal-unpaid') && (
          <div className={'col-md-3 mb-3'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Total Unpaid</h6>
                <OverlayTrigger
                  key={`tooltip-overlay-gmv`}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-gmv`}>
                      <i>Berdasarkan dari total produk yang belum terbayar</i>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </div>
              <div className={'card-content'}>
                <h5>
                  <VuiNumberFormat value={totalUnpaid} data={totalUnpaid} prefix={'IDR '} />
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>

      {permissions.includes('product.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>

              <div className={'filter-item'}>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    clearable={true}
                    selectRepository={CategoryRepository}
                    defaultValue={selectedCategory.get()}
                    onChange={val => {
                      selectedCategory.set(val);
                      tableConfig.category.set(_.get(val, 'id', null));
                      getTableData(true);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
              <div className={'filter-item'}>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    clearable={true}
                    options={stockOptions}
                    defaultValue={selectedStock.get()}
                    onChange={val => {
                      selectedStock.set(val);
                      tableConfig.set(node => ({ ...node, ...val.params }));
                      getTableData(true);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>

          <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />
        </div>
      ) : null}
    </>
  );
};

export default ProductList;

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { HeaderAuth } from '../../../layouts/Auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { VuiButton } from '../../../vodea/@vodea-ui/components/VuiButton';
import { useState } from '@hookstate/core/dist';
import { useTranslation } from 'react-i18next';
import { usePassportService } from '../../../vodea/utilities/hooks';
import { Inputs, schema } from './validation';
import { mapHookErrors, showToast } from '../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../vodea/utilities/hooks/useIsMounted';

const Login: React.FC<any> = () => {
  const { t } = useTranslation();
  const loading = useState(false);
  const { login } = usePassportService(true);
  const isMounted = useIsMounted();

  const { register, handleSubmit, errors, setError } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit(data => {
    loading.set(true);

    login(data.email, data.password).catch(e => {
      loading.set(false);

      if (isMounted.current && e?.response?.data?.error) {
        const errors = mapHookErrors(e.response.data.error);
        Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

        showToast(e.response.data.message, 'error');
      }
    });
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <HeaderAuth title={t('Sign In')} subtitle={t('Sign in on the internal platform')} />
      <form className={'form-wrapper'} onSubmit={onSubmit}>
        <div
          className={clsx({
            'form-group': true,
            error: errors.email
          })}
        >
          <label className={'form-label'}>Email</label>
          <input type='text' name='email' className='form-control' ref={register} />
          <label className={'label-help'}>{errors.email?.message}</label>
        </div>
        <div
          className={clsx({
            'form-group mb-4': true,
            error: errors.password
          })}
        >
          <label className={'form-label'}>Password</label>
          <input type='password' name='password' className='form-control' ref={register} />
          <label className={'label-help'}>{errors.password?.message}</label>
        </div>

        <div className='d-flex align-items-center justify-content-between mb-4'>
          <div className='form-check'>
            <input className='form-check-input' type='checkbox' value='' id={'remember-me'} />
            <label className='form-check-label' htmlFor={'remember-me'}>
              {t('Remember me')}
            </label>
          </div>
          <Link to={'/auth/forgot-password'}>{t('Forgot Password')}?</Link>
        </div>

        <VuiButton label={t('Sign In')} loading={loading.get()} forSubmit={true} fullWidth={true} />
      </form>
    </>
  );
};

export default Login;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { none, useState } from '@hookstate/core/dist';
import VuiUploadImage from '../../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage';
import { useNavigate, useParams } from 'react-router-dom';
import { InformationInputs, informationSchema, PicInputs, picSchema, InformationBaseModel, PicBaseModel } from './validation';
import SupplierRepository from '../../../../../repositories/SupplierRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import CityRepository from '../../../../../repositories/CityRepository';
import { $clone } from '../../../../../vodea/utilities';

const ContactSupplierForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const title = id ? t('Edit Supplier') : t('Create Supplier');

  const showPicModal = useState(false);
  const picModalData = useState(PicBaseModel);

  const selectedPicIndex = useState<number | null>(null);
  const navigate = useNavigate();
  const showActionModal = useState(false);

  const informationData = useState({
    number: ''
  });

  const picDetailOnClickDelete = (index: number) => {
    selectedPicIndex.set(index);
    showActionModal.set(true);
  };

  const picHandleOnContinue = (e: React.MouseEvent, index: any) => {
    pics[index].set(none);

    informationClearError(`pics[${index}]`);
    const picsErrors = informationErrors.pics?.filter(val => val);
    informationClearError('pics');

    if (picsErrors?.length) {
      picsErrors?.forEach((value, key) => {
        if (value) {
          // @ts-ignore
          informationSetError(`pics[${key}]`, value);
        }
      });
    }

    selectedPicIndex.set(null);
    showActionModal.set(false);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line

  const getData = () => {
    SupplierRepository.show(id, {
      with: ['city', 'pics', 'photo']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        if (isMounted.current) {
          informationData.set(data);
          pics.set([...data.pics]);
        }

        _.forEach(formattedData, (value, name) => {
          // @ts-ignore
          informationSetValue(name, value);
        });
      })
      .catch((e: AxiosError) => {
        //showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValue,
    clearErrors: informationClearError
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      telephone: ''
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const formData = formatFormData(data);

    if (id === undefined) {
      SupplierRepository.create({
        ...formData,
        pics: pics.value
      })
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');

          informationLoading.set(false);

          navigate('/contact/supplier');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      SupplierRepository.update(id, {
        ...formData,
        pics: pics.value
      })
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });
            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  const pics = useState<PicInputs[]>([]);

  const hasErrors = (key: string): boolean => {
    return _.has(informationErrors, key) || _.has(picErrors, key);
  };

  const getErrors = (key: string): string => {
    return _.get(informationErrors, key) || _.get(picErrors, key);
  };

  const renderTablePicRow = (row: any, index: number) => {
    return (
      <tr key={index}>
        {Boolean(_.get(informationErrors, `pics[${index}]`)) ? (
          hasErrors('pics') ? (
            <td className={'table-icon-wrapper'}>
              <OverlayTrigger
                key={index}
                placement={'top'}
                overlay={<Tooltip id={`tooltip-${index}`}>{getErrors(`pics[${index}].email.message`)}</Tooltip>}
              >
                <Button variant='danger' className={'button-icon-wrapper'} onClick={() => handlePicOpenModal(row, index)}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Button>
              </OverlayTrigger>
            </td>
          ) : (
            <td></td>
          )
        ) : (
          <td></td>
        )}
        <td valign='middle'>{row.nik}</td>
        <td valign='middle'>{row?.name}</td>
        <td valign='middle'>{row.email}</td>
        <td valign='middle'>{row.mobile_phone}</td>
        <td valign='middle'>
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={e => handlePicOpenModal(row, index)}>
              <FontAwesomeIcon className={'icon'} icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={e => picDetailOnClickDelete(index)}>
              <FontAwesomeIcon className={'icon'} icon={faTrashAlt} />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const {
    register: picRegister,
    handleSubmit: picHandleSubmit,
    errors: picErrors,
    clearErrors: picClearErrors,
    setError: picSetError
  } = useForm<PicInputs>({
    resolver: yupResolver(picSchema(picModalData.id.get()))
  });

  const onPicSubmit = picHandleSubmit(data => {
    showPicModal.set(false);

    if (selectedPicIndex.value !== null) {
      pics[selectedPicIndex.value].set(data);
    } else {
      pics.merge([data]);
    }
  });

  const handlePicOpenModal = (data = PicBaseModel, index: any = null) => {
    selectedPicIndex.set(index);
    const modalErros = _.get(informationErrors, `pics[${index}]`, null);

    if (modalErros) {
      _.forEach(PicBaseModel, (value, name) => {
        if (modalErros[name] !== undefined) {
          picSetError(name, {
            type: 'manual',
            message: modalErros[name].message
          });
        }
      });
    } else {
      picClearErrors();
    }

    showPicModal.set(true);
    picModalData.set($clone(data));
    //rowIdPic.set(null);
  };

  const breadcrumbList = [
    {
      label: t('Contact'),
      link: '/contact'
    },
    {
      label: t('Supplier'),
      link: '/contact/supplier'
    },
    {
      label: id ? id : t('Create'),
      link: '/contact/supplier/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'contact-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout type-profile'}>
            <div className={'multiple-paper-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true
                        })}
                      >
                        <label className={'form-label'}>Supplier ID</label>
                        <input
                          type='text'
                          name='code'
                          className='form-control'
                          value={_.get($clone(informationData.value), 'number')}
                          disabled
                        />
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input type='text' name='name' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.email
                        })}
                      >
                        <label className={'form-label'}>Email</label>
                        <input type='text' name='email' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.email?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.telephone
                        })}
                      >
                        <label className={'form-label'}>Phone Number</label>
                        <input type='text' name='telephone' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.telephone?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.address
                        })}
                      >
                        <label className={'form-label'}>Address</label>
                        <textarea name='address' rows={5} className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.address?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Person In Charge</h6>
                </div>

                <div className={'card-content'}>
                  <Table size='sm' responsive striped hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>{pics.value.map(renderTablePicRow)}</tbody>
                  </Table>

                  <button type={'button'} className={'btn btn-primary btn-small'} onClick={e => handlePicOpenModal()}>
                    <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                    {t('Add')}
                  </button>
                </div>
              </div>
            </div>

            <div className={'multiple-paper-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Location')}</h6>
                </div>
                <div className={'card-content'}>
                  <div
                    className={clsx({
                      'form-group': true,
                      error: informationErrors.city_id
                    })}
                  >
                    <label className={'form-label'}>City</label>

                    <div className={'form-select-wrapper'}>
                      <Controller
                        name={'city_id'}
                        control={informationControl}
                        render={props => (
                          <VuiSelect selectRepository={CityRepository} defaultValue={props.value} onChange={props.onChange} />
                        )}
                      />
                    </div>
                    <label className={'label-help'}>{_.get(informationErrors.city_id, 'message')}</label>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Profile Picture')}</h6>
                </div>
                <div className={'card-content'}>
                  <div
                    className={clsx({
                      'form-group': true,
                      error: informationErrors.photo_id
                    })}
                  >
                    <Controller
                      name='photo_id'
                      control={informationControl}
                      render={({ value, onChange }) => <VuiUploadImage defaultValue={value} onChange={onChange} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/contact/supplier'} />
        </form>
      </div>

      <Modal show={showPicModal.get()} onHide={() => showPicModal.set(false)} backdrop={'static'} size={'lg'} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Person in Charge Detail')}</Modal.Title>
        </Modal.Header>
        <form className={'form-wrapper'} onSubmit={onPicSubmit}>
          <Modal.Body>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: picErrors.nik
                  })}
                >
                  <label className={'form-label'}>ID</label>
                  <input
                    type='text'
                    name={'nik'}
                    className='form-control'
                    defaultValue={_.get($clone(picModalData.value), 'nik')}
                    ref={picRegister}
                  />
                  <label className={'label-help'}>{picErrors.nik?.message}</label>
                </div>
              </div>

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: picErrors.name
                  })}
                >
                  <label className={'form-label'}>Name</label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    ref={picRegister}
                    defaultValue={_.get($clone(picModalData.value), 'name')}
                  />
                  <label className={'label-help'}>{picErrors.name?.message}</label>
                </div>
              </div>

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: picErrors.email
                  })}
                >
                  <label className={'form-label'}>Email</label>
                  <input
                    type='text'
                    name='email'
                    className='form-control'
                    ref={picRegister}
                    defaultValue={_.get($clone(picModalData.value), 'email')}
                  />
                  <label className={'label-help'}>{picErrors.email?.message}</label>
                </div>
              </div>

              <div className={'col-md-6'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: picErrors.mobile_phone
                  })}
                >
                  <label className={'form-label'}>Phone Number</label>
                  <input
                    type='text'
                    name='mobile_phone'
                    className='form-control'
                    ref={picRegister}
                    defaultValue={_.get($clone(picModalData.value), 'mobile_phone')}
                  />
                  <label className={'label-help'}>{picErrors.mobile_phone?.message}</label>
                </div>
              </div>

              <div className={'col-md-12 mt-2'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: picErrors.password
                  })}
                >
                  <label className={'form-label'}>Password</label>
                  <input
                    type='password'
                    name='password'
                    className='form-control'
                    ref={picRegister}
                    defaultValue={_.get($clone(picModalData.value), 'password')}
                  />
                  <label className={'label-help'}>{picErrors.password?.message}</label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <input
            type='hidden'
            name='id'
            className='form-control'
            ref={picRegister}
            defaultValue={_.get($clone(picModalData.value), 'id')}
          />

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showPicModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={false} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal
        show={showActionModal.get()}
        onHide={() => showActionModal.set(false)}
        onContinue={e => picHandleOnContinue(e, selectedPicIndex.value)}
      />
    </>
  );
};

export default ContactSupplierForm;

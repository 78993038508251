import * as yup from 'yup';
import 'yup-phone';

export type ReturnItemInputs = {
  id?: number,
  sales_order_item_id: object | null;
  type_id: object | null;
  stock_treat_id: object | null,
  quantity: number | null
}

export const returnItemSchema = yup.object().shape({
  id: yup.mixed(),
  sales_order_item_id: yup.mixed().required().label('product'),
  type_id: yup.mixed().required().label('type'),
  stock_treat_id: yup.mixed().required().label('stock treat'),
  quantity: yup.number().typeError("field must be a number.").required()
})


export type InformationInputs = {
  customer_id: object | null;
  sales_order_id: object | null;
  date: object;
  sales_return_items: ReturnItemInputs[]
};

export const informationSchema = yup.object().shape({
  customer_id: yup.mixed().required(),
  sales_order_id: yup.mixed().required(),
  date: yup.mixed().required(),
});

import React, { FC, useCallback, useMemo } from 'react';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import NotificationRepository from '../../../../repositories/NotificationRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import { TabList } from '../index';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingIndication } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import { useNavigate } from 'react-router-dom';

export interface INotificationList {
  defaultParams: string;
}

interface IExportReady {
  subject_id: number | null;
  file_url: string;
}

interface IDefaultNotificationData {
  description: string;
  subject_id: number;
  timestamp: string;
  title: string;
  web_url: string | null;
}

interface INotificationType<T> {
  notification: T;
}

interface INotification<T> {
  created_at: string;
  data: INotificationType<T>;
  id: string;
  notifiable_id: string;
  notifiable_type: string;
  read_at: null | string;
  type: string;
  updated_at: string;
}

interface IQuery {
  page: number;
  per_page: number;
  order_by: string;
  sorted_by: string;
}

const NotificationList = ({ defaultParams }: INotificationList) => {
  const isMounted = useIsMounted();
  const [data, setData] = React.useState<INotification<IDefaultNotificationData & Partial<IExportReady>>[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<IQuery>({
    page: 1,
    per_page: 10,
    order_by: 'date',
    sorted_by: 'desc'
  });
  const navigate = useNavigate();

  const loadData = useCallback(
    async (query: IQuery) => {
      if (isMounted.current) {
        setHasMore(true);
      }

      await NotificationRepository.show({
        ...query,
        type: defaultParams
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response.data;
          if (isMounted.current) {
            setData(prevState => [...prevState, ...responseData]);
            if (responseData.length < query.per_page) {
              setHasMore(false);
            }
          }
        })
        .catch((e: AxiosError) => {
          if (e.isAxiosError) showToast(e.message, 'error');
        });
    },
    [defaultParams]
  );

  useMemo(() => {
    (async () => {
      await loadData(query);
    })();
  }, [defaultParams, query]);

  const handleNextData = () => {
    if (isMounted.current) {
      setQuery(prevState => ({
        ...prevState,
        page: prevState.page + 1
      }));
    }
  };

  const goToNotification = (data: INotification<IDefaultNotificationData & Partial<IExportReady>>) => {
    if (data.data.notification.web_url) {
      if (data.data.notification.web_url === 'cut-off') {
        navigate('/finance/payroll/' + data.data.notification.subject_id + '/?notif_id=' + data.id);
      }
      if (data.data.notification.web_url === 'product') {
        navigate('/inventory/product/' + data.data.notification.subject_id + '/?notif_id=' + data.id);
      }
      if (data.data.notification.web_url === 'receive-item') {
        navigate('/purchase/receive-item/' + data.data.notification.subject_id + '/?notif_id=' + data.id);
      }
      if (data.data.notification.web_url === 'sales-order') {
        navigate('/sales/sales-order/' + data.data.notification.subject_id + '/?notif_id=' + data.id);
      }
    } else {
      window.open(data.data.notification.file_url, '_blank');
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={data.length}
        next={handleNextData}
        hasMore={hasMore}
        loader={
          <div style={{ textAlign: 'center', padding: '10px 0' }}>
            <LoadingIndication />
          </div>
        }
        scrollThreshold={0.95}
      >
        {data.map(notification => {
          return (
            <Card style={{ cursor: 'pointer' }} onClick={() => goToNotification(notification)} key={notification.id}>
              <Card.Body>
                <div>
                  <div className={'slide-item'}>
                    <label className={'title'}>{notification.data.notification.title}</label>
                    <p className={'summary'}>{notification.data.notification.description}</p>
                    <label className={'date'}>{moment(notification.data.notification.timestamp).format('DD MMMM YYYY | HH:mm')}</label>
                  </div>
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default NotificationList;

import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/gate-setting`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
    });
  },
  show: function (id: number | string, params: any = null, useCancelToken = true) {
    return api.get(`${endPoint()}/api/gate-setting/${id}`, {
      params,
      cancelToken: useCancelToken ? cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token : false
    });
  },
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/gate-setting`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.select.name].handleRequestCancellation().token
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/gate-setting`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/gate-setting/${id}`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/gate-setting/${id}`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
    });
  },
  permission: function (params: any = null) {
    return api.get(`${endPoint()}/api/permission`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.permission.name].handleRequestCancellation().token
    });
  }
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone, defaultActionFormatter } from '../../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import SalesOrderRepository from '../../../../repositories/SalesOrderRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import moment from 'moment';
import _ from 'lodash';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import fileDownload from 'js-file-download';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { defaultChannelOptions } from '../../../../constants';
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

const SalesOrder: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();

  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const [totalData, setTotalData] = React.useState<number>(0);
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const selectedChannel = useState(null);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    SalesOrderRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const title = t('Sales Order');

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'date',
    total: 0,
    loading: false,
    channel: null,
    // additional

    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'number',
      text: 'Number'
    },
    {
      dataField: 'customer_name',
      text: 'Customer'
    },
    {
      dataField: 'grand_total',
      text: 'Total',
      render: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'date',
      text: 'Order Date',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY hh:mm A');
      }
    },
    {
      dataField: 'creator_name',
      text: 'Cashier'
    },
    {
      dataField: 'print_count',
      text: 'Print Count'
    },
    {
      dataField: 'channel_name',
      text: 'Channel'
    },
    {
      dataField: 'action',
      text: 'Action',
      render: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete,
        permission: 'sales-order',
        permissions: permissions,
        isLongTable: true
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), ['loading', 'total']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await SalesOrderRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) {
            setTotalData(response.data.total_data);
          }
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (e && e.message !== 'all canceled' && isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          changeTableLoading(false);
        }
      });
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData(true);
  };

  const loadingExport = useState(false)

  const handleExport = async () => {
    loadingExport.set(true)

    const conf = _.omit($clone(tableConfig.value), ['loading', 'total']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    SalesOrderRepository.export(params).then((response: any) => {
      fileDownload(response.data, 'sales-order-report.xls');
      loadingExport.set(false)
    });
  };

  const breadcrumbList = [
    {
      label: t('Sales'),
      link: '/sales'
    },
    {
      label: t('Sales Order'),
      link: '/sales/sales-order'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>

        <div className={'btn-wrapper d-flex align-items-center'}>
          <div className={'action-wrapper'}>
            {permissions.includes('sales-order.store') ? (
              <Link to={'/sales/sales-order/create'} className={'action-item'}>
                <button type={'button'} className={'btn btn-primary'}>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                  {t('Create Sales Order')}
                </button>
              </Link>
            ) : null}
            {permissions.includes('report.sales-order') ? (
              <VuiButton forSubmit={false} label={t('Export')} loading={loadingExport.get()} onClick={handleExport} />
            ) : null}
          </div>
        </div>
      </div>

      {permissions.includes('sales-order.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>

              <div className={'filter-item filter-special'}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges={true}
                />
              </div>

              <div className='filter-item'>
                <div className='form-select-wrapper'>
                  <VuiSelect
                    placeholder={'Select Channel'}
                    clearable={true}
                    options={defaultChannelOptions}
                    defaultValue={selectedChannel.get()}
                    onChange={val => {
                      selectedChannel.set(val);
                      tableConfig.channel.set(_.get(val, 'id', null));
                      getTableData(true);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />
    </>
  );
};

export default SalesOrder;

// @ts-nocheck
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import clsx from 'clsx';
import moment from 'moment';
import { useState } from '@hookstate/core/dist';
import CutOffRepository from '../../../../../repositories/CutOffRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../../vodea/utilities/helpers/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { $clone } from '../../../../../vodea/utilities';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';

const FinancePayrollForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const saveLoading = useState(false);

  const informationData = useState({
    period: moment().format('YYYY-MM-DD'),
    month: moment().format('MMMM'),
    year: moment().format('YYYY'),
    created_at: moment().format('D MMMM YYYY hh:mm A'),
    status: false
  });
  const [userOptions, setUserOptions] = React.useState<any[]>([]);

  const status = informationData.status.value ? 'Locked' : 'Open';

  const title = `Payroll ${informationData.month.get()} ${informationData.year.get()}`;

  useEffect(() => {
    if (id) {
      (async () => {
        await Promise.all([getData()]).catch();
      })();
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    await CutOffRepository.show(id, {
      with: ['cutOffUsers.user']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const period = data.period;
        informationData.period.set(period);
        informationData.month.set(moment(period, 'YYYY-MM-DD').format('MMMM'));
        informationData.year.set(moment(period, 'YYYY-MM-DD').format('YYYY'));
        informationData.created_at.set(moment(data.created_at).format('D MMMM YYYY hh:mm A'));
        informationData.status.set(Boolean(data.is_locked * 1));
        tableData.set(_.get(data, 'cut_off_users', []));
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const tableData = useState<any[]>([]);

  const tableNumericValidator = (newValue: any, row: any, column: any) => {
    if (isNaN(newValue)) {
      return {
        valid: false,
        message: 'Price should be numeric'
      };
    }

    return true;
  };
  const columns = [
    {
      dataField: 'user.name',
      text: 'Name',
      editable: false
    },
    {
      dataField: 'salary',
      text: 'Salary',
      formatter: (cell: any, row: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            prefix={'IDR '}
          />
        );
      },
      type: 'number',
      validator: tableNumericValidator,
      editable: !informationData.status.value
    },
    {
      dataField: 'allowance',
      text: 'Allowance',
      formatter: (cell: any, row: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            prefix={'IDR '}
          />
        );
      },
      type: 'number',
      validator: tableNumericValidator,
      editable: !informationData.status.value
    },
    {
      dataField: 'deduction',
      text: 'Deduction',
      formatter: (cell: any, row: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            prefix={'IDR '}
          />
        );
      },
      type: 'number',
      validator: tableNumericValidator,
      editable: !informationData.status.value
    }
  ];

  const tableBeforeSaveCell = (oldValue: any, newValue: any, row: any, column: any) => {
    let tempTableData = $clone(tableData.get());

    const name = column.dataField;
    const index = _.findIndex(tempTableData, ['id', row.id]);

    tempTableData[index][name] = newValue;

    tableData.set(tempTableData);
  };

  const breadcrumbList = [
    {
      label: t('Finance'),
      link: '/finance'
    },
    {
      label: t('Payroll'),
      link: '/finance/payroll'
    },
    {
      label: `${informationData.month.get()} ${informationData.year.get()}`,
      link: '/'
    }
  ];

  const handleChangeLockStatus = (e: any) => {
    informationData.status.set(s => !s);
  };

  const handleSave = () => {
    saveLoading.set(true);

    const cutOffUsers = $clone(tableData.value).map((item: any) => {
      delete item.user;
      delete item.user_id;

      return item;
    });

    const params = {
      is_locked: informationData.status.value,
      period: informationData.period.value,
      cut_off_users: cutOffUsers
    };
    CutOffRepository.update(id, params)
      .then((response: AxiosResponse) => {
        if (isMounted) {
          saveLoading.set(false);
        }
        showToast(response.data.message, 'success');
      })
      .catch((e: AxiosError) => {
        if (isMounted) {
          saveLoading.set(false);
        }
        showToast(e?.response?.data?.message, 'error');
      });
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>

        <div className='action-wrapper'>
          <Link to={'/finance/payroll'} className={'btn btn-light action-item'}>
            <FontAwesomeIcon className={'icon icon-prefix'} icon={faChevronLeft} />
            {t('Back')}
          </Link>
          <button
            type={'button'}
            className={`btn ${informationData.status.value ? 'btn-danger' : 'btn-success'} action-item`}
            onClick={handleChangeLockStatus}
          >
            <Form.Check style={{ minHeight: 0 }} id='is-locked' type='checkbox' checked={informationData.status.value} readOnly />
            {t(status)}
          </button>
        </div>
      </div>

      <div className='default-page-layout layout-reverse'>
        <div className='information-section'>
          <div className={'card-paper'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Information')}</h6>
            </div>
            <div className={'card-content'}>
              <div className='row'>
                <div className='col-md-6'>
                  <div
                    className={clsx({
                      'form-group': true
                    })}
                  >
                    <label className={'form-label'}>Month</label>
                    <input type='text' className='form-control' disabled value={informationData.month.get()} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div
                    className={clsx({
                      'form-group': true
                    })}
                  >
                    <label className={'form-label'}>Year</label>
                    <input type='text' className='form-control' disabled value={informationData.year.get()} />
                  </div>
                </div>
              </div>

              <div
                className={clsx({
                  'form-group': true
                })}
              >
                <label className={'form-label'}>Created</label>
                <input type='text' className='form-control' disabled value={informationData.created_at.get()} />
              </div>

              <div
                className={clsx({
                  'form-group': true
                })}
              >
                <label className={'form-label'}>Status</label>
                <input type='text' className='form-control' disabled value={status} />
              </div>
            </div>
          </div>
        </div>

        <div className='multiple-paper-section'>
          <div className='card-paper'>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>Payroll List</h6>
            </div>
            <div className='card-content'>
              <BootstrapTable
                keyField='id'
                data={$clone(tableData.get())}
                columns={columns}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  beforeSaveCell: tableBeforeSaveCell
                })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={'card-action-form'}>
        <h6 className={'title'}>Save Change?</h6>

        <div className='btn-action-wrapper'>
          <Link to={'/finance/payroll'} className={'btn btn-sm btn-secondary'}>
            Cancel
          </Link>
          <VuiButton forSubmit={false} label={'Save'} loading={saveLoading.get()} onClick={handleSave} />
        </div>
      </div>
    </>
  );
};

export default FinancePayrollForm;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EventHandler } from 'react-bootstrap-daterangepicker';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import { useState } from '@hookstate/core/dist';
import VuiDataTable from '../../../../vodea/@vodea-ui/components/VuiDataTable';
import { defaultActionFormatter } from '../../../../vodea/utilities';
import { useLocation } from 'react-router-dom';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';

const products = [
  {
    id: 1,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 2,
    number: 'SO-2020-0005',
    customer: {
      name: 'Sohn Doe'
    },
    total: 200000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 3,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 4,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 5,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 6,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 7,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 8,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 9,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 10,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  },
  {
    id: 11,
    number: 'SO-2020-0001',
    customer: {
      name: 'John Doe'
    },
    total: 500000,
    status: 'Active',
    created: '11/06/1995',
    cashier: 'Maya'
  }
];

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

const ExampleList: React.FC<any> = () => {
  const location = useLocation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const tableLoading = useState(false);
  const tablePage = useState(1);
  const tableSizePerPage = useState(1);
  const tableData = useState(products.slice(0, tableSizePerPage.get()));

  const tableColumns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true
    },
    {
      dataField: 'number',
      text: 'Number',
      sort: true
    },
    {
      dataField: 'customer.name',
      text: 'Customer',
      sort: true
    },
    {
      dataField: 'total',
      text: 'Total',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status'
    },
    {
      dataField: 'created',
      text: 'Created Date'
    },
    {
      dataField: 'cashier',
      text: 'Cashier'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        keyId: 'number'
      }
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    tableLoading.set(true);

    setTimeout(() => {
      tableData.set(products.slice(currentIndex, currentIndex + sizePerPage));
      tablePage.set(page);
      tableSizePerPage.set(sizePerPage);
      tableLoading.set(false);
    }, 500);
  };

  const breadcrumbList = [
    {
      label: 'Example',
      link: '/example'
    }
  ];

  const datepickerEvent: EventHandler = (event, picker) => {
    if (event.type === 'apply') {
      const name = event.target.name;
      const startDate = picker.startDate.format('YYYY-MM-DD');
      const endDate = picker.endDate.format('YYYY-MM-DD');
    }
  };

  return (
    <>
      <Helmet>
        <title>Example List</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>Example List</h3>
        <div className={'btn-wrapper'}>
          <button type={'button'} className={'btn btn-primary'}>
            <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
            Add New Example
          </button>
        </div>
      </div>

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input type='text' className='form-control' placeholder='Search' />
              </div>
            </div>

            <div className={'filter-item'}>
              <div className={'form-select-wrapper'}>
                <Select className='basic-single' classNamePrefix='select' isClearable={true} options={options} />
              </div>
            </div>

            <div className={'filter-item'}>
              <VuiDateRangePicker name={'singleDate'} event={datepickerEvent} single={true} />
            </div>

            <div className={'filter-item filter-special'}>
              <VuiDateRangePicker name={'rangeDate'} event={datepickerEvent} />
            </div>
          </div>

          <VuiDataTable
            loading={tableLoading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tablePage.get()}
            sizePerPage={tableSizePerPage.get()}
            totalSize={products.length}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default ExampleList;

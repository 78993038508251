import * as yup from 'yup';
import 'yup-phone';
import {phoneRegExp} from "../../../../../types";

export interface InformationInputs {
  name: string;
  email: string;
  telephone: string;
  address: string;
  city_id: object;
  photo_id: number | null;
  pics: PicInputs[];
}

export interface PicInputs {
  id?: any;
  nik: string;
  email: string;
  name: string;
  mobile_phone: string;
  password: string;
}

export const informationSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  email: yup.string().email(),
  telephone: yup.string().matches(phoneRegExp, {
    message:"Phone number is not valid",
    excludeEmptyString:true
  }),
  address: yup.string(),
  city_id: yup.mixed().required().label('city'),
  photo_id: yup.mixed()
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  email: '',
  telephone: '',
  address: '',
  city_id: {},
  photo_id: null,
  pics: []
};

export const PicBaseModel: PicInputs = {
  id: 0,
  nik: '',
  email: '',
  name: '',
  mobile_phone: '',
  password: ''
};

export const picSchema = (id: any) => {
  return yup.object().shape({
    id: yup.number().typeError("field must be a number."),
    nik: yup.string().required(),
    email: yup.string().required().email(),
    name: yup.string().required(),
    mobile_phone: yup.string().phone('ID', true).required().label('Phone Number'),
    password: yup.string()
        .when('id', (id: any, schema: any) => {
          if (id === 0) {
            return schema.required().min(8);
          }

          return schema.min(8);
        })
  });
};

// @ts-nocheck
import { useTranslation } from 'react-i18next';
import VuiDateRangePicker from '../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import React, { FC, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import VuiNumberFormat from '../../vodea/@vodea-ui/components/VuiNumberFormat';
import VuiSelect from '../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { ChartWidgetProps } from '../widget.interface';
import { taxOptions } from '../../constants';

const PurchaseOrderWidget: FC<ChartWidgetProps> = ({ chartData, chartLabel, handleChangeDate, config, callback, stateConfig }) => {
  const { t } = useTranslation();
  const [selectedTax, setSelectedTax] = useState(null);

  return (
    <div className={'card-paper'}>
      <div className={'card-header'}>
        <div className={'card-header-filter-wrapper'}>
          <div className={'title-wrapper'}>
            <h6 className={'card-header-title'}>{t('Purchase Order')}</h6>
          </div>
          <div style={{ display: 'flex', width: '50%' }}>
            <div className='filter-item' style={{ flex: 1, marginRight: 20 }}>
              <div className='form-select-wrapper'>
                <VuiSelect
                  placeholder={'Select'}
                  options={taxOptions}
                  defaultValue={selectedTax}
                  optionKey='value'
                  labelKey='label'
                  onChange={val => {
                    setSelectedTax(val);
                    stateConfig.is_ppn.set(_.get(val, 'value', null));
                    if (callback) callback();
                  }}
                />
              </div>
            </div>
            <div className={'filter-item'}>
              <VuiDateRangePicker startDate={config.date_from} endDate={config.date_to} callback={handleChangeDate} useRanges={false} />
            </div>
          </div>
        </div>
      </div>
      <div className={'card-content'}>
        {config.loading ? (
          <div className={'box-dashboard-wrapper is-loading-chart'} />
        ) : (
          <div className={'box-dashboard-wrapper'}>
            <div className={'box-caption-wrapper'}>
              <Bar
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: 'Total',
                      data: chartData,
                      backgroundColor: '#346497'
                    }
                  ]
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: false
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className='card-content'>
        <div className='grid-section three-section mt-3'>
          <div className='card-paper'>
            <div className={'card-header'}>
              <div className={'card-header-filter-wrapper'}>
                <div className={'title-wrapper'}>
                  <h6 className={'card-header-title'}>{t('Total')}</h6>
                </div>
              </div>
            </div>
            <div className={'card-content'}>
              {config.loading ? (
                <div className={'box-dashboard-wrapper is-loading-box'} />
              ) : (
                <div className={'box-dashboard-wrapper'}>
                  <div className={'box-caption-wrapper'}>
                    <NumberFormat
                      value={config.total}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='card-paper'>
            <div className={'card-header'}>
              <div className={'card-header-filter-wrapper'}>
                <div className={'title-wrapper'}>
                  <h6 className={'card-header-title'}>{t('Amount')}</h6>
                </div>
              </div>
            </div>
            <div className={'card-content'}>
              {config.loading ? (
                <div className={'box-dashboard-wrapper is-loading-box'} />
              ) : (
                <div className={'box-dashboard-wrapper'}>
                  <div className={'box-caption-wrapper'}>
                    <VuiNumberFormat data={config.amount} prefix={'IDR '} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderWidget;

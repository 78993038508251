import * as yup from 'yup';
import 'yup-phone';

export interface Model {
  id?: number;
  type: string;
  role_id: any;
  role?: any;
  user_id: any;
  user?: any;
  permission_ids: number[];
  permissions?: any[];
}

export const modelSchema = yup.object().shape({
  type: yup.string(),
  role_id: yup.mixed().when('type', {
    is: 'role',
    then: yup.mixed().required().label('Role'),
    otherwise: yup.mixed().notRequired()
  }),
  user_id: yup.mixed().when('type', {
    is: 'people',
    then: yup.mixed().required().label('User'),
    otherwise: yup.mixed().notRequired()
  }),
  permission_ids: yup.array().of(yup.number().required().label('Permission')).required().label('Permission')
});

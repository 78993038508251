import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../../vodea/utilities/helpers/global';
import { Modal } from 'react-bootstrap';
import VuiDataTable from '../../../../../vodea/@vodea-ui/components/VuiDataTable';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import ReportRepository from '../../../../../repositories/ReportRepository';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import { LoadingIndication } from '../../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';

type MutationProduct = {
  number: string;
  date: Date | string;
  total_sales_order: string;
};

type Props = {
  driverId?: number;
  params: any;
  open: boolean;
  onClose: () => void;
};

const ModalDetailDelivery = ({ driverId, onClose, open, params }: Props) => {
  const [data, setData] = useState<MutationProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState({
    page: 1,
    per_page: 10
  });
  const [totalData, setTotalData] = useState<number>(0);
  const [totalSalesOrder, setTotalSalesOrder] = useState<number>(0)

  useEffect(() => {
    loadData().then();
  }, [driverId, params, query]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = useCallback(async () => {
    if (!driverId) {
      return;
    }

    setLoading(true);

    await ReportRepository.showDeliveryPerDriver(driverId, {
      ...query,
      ...params
    })
      .then((response: AxiosResponse) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
        setTotalSalesOrder(Number(response.data.total_sales_order))
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [driverId, query, params]);

  const tableColumns = [
    {
      dataField: 'date',
      text: 'Date'
    },
    {
      dataField: 'number',
      text: 'Number'
    },
    {
      dataField: 'total_sales_order',
      text: 'Total Sales Order'
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage }) => {
    setQuery({
      page: page,
      per_page: sizePerPage
    });
  };

  return (
    <Modal show={open} onHide={onClose} backdrop={'static'} centered>
      <Modal.Header closeButton>
        <Modal.Title className='mb-unset'>Detail</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6 className={'mb-3 d-flex'}>
          <span className={'mr-2'}>Total SO :</span>
          <b>
            {loading ? (
                <LoadingIndication styles={{ width: 18, height: 18 }} />
            ) : (
                <VuiNumberFormat data={totalSalesOrder} value={totalSalesOrder} />
            )}
          </b>
        </h6>

        <VuiDataTable
          loading={loading}
          columns={tableColumns}
          data={data}
          page={query.page}
          sizePerPage={query.per_page}
          totalSize={totalData}
          onTableChange={onTableChange}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailDelivery;

import React from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { State } from '@hookstate/core/dist';
import clsx from 'clsx';

export const defaultActionFormatter: ColumnFormatter<any> = (cell, row, rowIndex, formatExtraData) => {
  const formatPermission = formatExtraData.permission || '';
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    if (typeof formatExtraData?.actionDelete == 'function') {
      formatExtraData?.actionDelete(row.id);
    }
  };

  const isClose = row.is_close && row.is_close == 1

  return (
    <div
      className={clsx({
        'table-btn-wrapper': true,
        'long-table': formatExtraData?.isLongTable
      })}
    >
      {formatExtraData.permissions?.includes(`${formatPermission}.update`) ? (
        <Link className={'btn btn-primary btn-small'} to={`${formatExtraData?.location}/${row.id}`}>
          View
        </Link>
      ) : null}

      {formatExtraData.permissions?.includes(`${formatPermission}.destroy`) && !isClose ? (
        <button type={'button'} className={'btn btn-danger btn-small'} onClick={handleDelete}>
          Delete
        </button>
      ) : null}
    </div>
  );
};

export const defaultInputFormatter: ColumnFormatter<any> = (cell, row, rowIndex, formatExtraData) => {
  return (
    <div className={'form-group'}>
      <input type='text' name='number' className='form-control' ref={`${formatExtraData?.location}`} />
    </div>
  );
};

export const $clone = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const $cloneState = (value: State<any>) => {
  return $clone(value.value);
};

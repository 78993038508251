// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InformationInputs, informationSchema, ItemInputs, itemSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import { useState } from '@hookstate/core/dist';
import clsx from 'clsx';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import UserRepository from '../../../../../repositories/UserRepository';
import _ from 'lodash';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import moment from 'moment';
import PaymentMethodRepository from '../../../../../repositories/PaymentMethodRepository';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import ExpenseCategoryRepository from '../../../../../repositories/ExpenseCategoryRepository';
import NumberFormat from 'react-number-format';
import { $clone } from '../../../../../vodea/utilities';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import ExpenseRepository from '../../../../../repositories/ExpenseRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import Select from 'react-select';
import { paymentMethodOptions } from '../../../../../constants';

const FinanceExpenseForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();

  const title = id ? t('Edit Expense') : t('Create Expense');

  const summaryData = useState({
    total: 0
  });

  const informationData = useState({
    number: ''
  });

  useEffect(() => {
    if (id) {
      (async () => {
        await Promise.all([getData()]).catch();
      })();
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    await ExpenseRepository.show(id, {
      with: ['user', 'paymentMethod', 'expenseItems.expenseCategory']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(
          {
            user_id: null,
            date: null,
            payment_method_id: null,
            expense_items: null
          },
          data
        );

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));

          if (formattedData?.expense_items?.length > 0) {
            formattedData.expense_items.forEach((item: any, key: number) => {
              formattedData.expense_items[key] = formatSetValueForm(
                {
                  id,
                  ...{
                    expense_category_id: null,
                    description: null,
                    amount: null
                  }
                },
                item
              );
            });
          }
        }

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      date: moment()
    }
  });
  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    Object.assign(formData, {
      expense_items: []
    });

    if (itemFields.length !== 0) {
      itemFields.forEach((item: any, key: number) => {
        formData.expense_items[key] = formatFormData(item);
      });
    }

    (id ? ExpenseRepository.update(id, formData) : ExpenseRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/finance/expense');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const { fields: itemFields, append: itemAppend, remove: itemRemove, insert: itemInsert } = useFieldArray({
    keyName: 'key',
    name: 'expense_items',
    control: informationControl
  });

  useEffect(() => {
    calculateSummaryData();
  }, [itemFields]); // eslint-disable-line

  const calculateSummaryData = () => {
    setTimeout(() => {
      if (itemFields.length) {
        let tempTotal = 0;

        $clone(itemFields).forEach((value: any, index: number) => {
          tempTotal += _.get(value, 'amount', 0) * 1;
        });

        summaryData.total.set(tempTotal);
      }
    }, 100);
  };

  const selectedItemIndex = useState(-1);
  const showItemModal = useState(false);

  const itemLoading = useState(false);
  const {
    handleSubmit: itemHandleSubmit,
    register: itemRegister,
    control: itemControl,
    errors: itemErrors,
    reset: itemReset,
    setError: itemSetError,
    setValue: itemSetValue
  } = useForm<ItemInputs>({
    resolver: yupResolver(itemSchema)
  });
  const onItemSubmit = itemHandleSubmit(data => {
    const tempData = {
      ...data
    };

    if (selectedItemIndex.get() === -1) {
      itemAppend(tempData);
    } else {
      itemInsert(selectedItemIndex.get() + 1, tempData);
      itemRemove(selectedItemIndex.get());
    }

    showItemModal.set(false);
  });

  const itemDeleteIndex = useState(0);
  const showItemDeleteModal = useState(false);

  const handleItemClickDelete = (index: number) => {
    itemDeleteIndex.set(index);
    showItemDeleteModal.set(true);
  };

  const handleItemDelete = () => {
    itemRemove(itemDeleteIndex.get());
    showItemDeleteModal.set(false);
    itemDeleteIndex.set(0);
  };

  const handleShowItemProduct = async (show: boolean, index: number = -1) => {
    itemReset();
    selectedItemIndex.set(index);

    if (index != -1) {
      const selectedData = $clone(itemFields[index]);

      setTimeout(() => {
        itemSetValue('id', _.get(selectedData, 'id'));
        itemSetValue('expense_category_id', _.get(selectedData, 'expense_category_id'));
        itemSetValue('description', _.get(selectedData, 'description'));
        itemSetValue('amount', _.get(selectedData, 'amount'));
      }, 200);
    }

    showItemModal.set(show);
  };

  const expenseItemColumns = [
    {
      dataField: 'expense_category_id.name',
      text: 'Category'
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell: any, row: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleShowItemProduct(true, rowIndex)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handleItemClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const breadcrumbList = [
    {
      label: t('Finance'),
      link: '/finance'
    },
    {
      label: t('Expense'),
      link: '/finance/expense'
    },
    {
      label: id ? id : t('Create'),
      link: '/finance/expense/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
        <div className='default-page-layout layout-reverse'>
          <div className='information-section'>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={informationData.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.user_id
                  })}
                >
                  <label className={'form-label'}>Beneficiary</label>
                  <div className={'form-with-button-wrapper'}>
                    <div className={'form-input-wrapper'}>
                      <Controller
                        name={'user_id'}
                        control={informationControl}
                        render={props => (
                          <VuiSelect selectRepository={UserRepository} defaultValue={props.value} onChange={props.onChange} />
                        )}
                      />
                    </div>
                    {/* <button type={'button'} className={'btn btn-primary form-btn'}>
                          <FontAwesomeIcon className={'icon'} icon={faPlus} />
                        </button>*/}
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.user_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.date
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <Controller
                    name={'date'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.payment_method_id
                  })}
                >
                  <label className={'form-label'}>Method</label>
                  <Controller
                    name={'payment_method_id'}
                    control={informationControl}
                    render={props => (
                      <Select
                        classNamePrefix='select'
                        isClearable={true}
                        defaultValue={props.value}
                        value={props.value}
                        options={paymentMethodOptions}
                        onChange={props.onChange}
                        getOptionLabel={options => options['name']}
                        getOptionValue={options => options['id']}
                      />
                      // <VuiSelect selectRepository={PaymentMethodRepository} defaultValue={props.value} onChange={props.onChange} />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.payment_method_id, 'message')}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='multiple-paper-section'>
            <div className='card-paper'>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Expense List</h6>
              </div>
              <div className='card-content'>
                <BootstrapTable keyField='key' data={$clone(itemFields)} columns={expenseItemColumns} />

                <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleShowItemProduct(true)}>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                  {t('Add Expense')}
                </button>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'></div>
              <div className='col-md-6'>
                <div className='card-paper'>
                  <div className={'card-header'}>
                    <h6 className={'card-header-title'}>Summary</h6>
                  </div>
                  <div className='card-content'>
                    <table className='table-summary type-right'>
                      <tbody>
                        <tr>
                          <td>Total</td>
                          <td>
                            <VuiNumberFormat value={summaryData.total.get()} data={summaryData.total.get()} prefix={'IDR '} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm loading={informationLoading.get()} cancelLink={'/finance/expense'} />
      </form>

      <Modal show={showItemModal.get()} onHide={() => showItemModal.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItemIndex.get() === -1 ? t('Add Expense') : t('Edit Expense')}</Modal.Title>
        </Modal.Header>
        <form className={'form-wrapper'} onSubmit={onItemSubmit}>
          <Modal.Body>
            <Controller
              name={'id'}
              control={itemControl}
              defaultValue={0}
              render={props => <input type={'hidden'} defaultValue={props.value} />}
            />

            <div
              className={clsx({
                'form-group': true,
                error: itemErrors.expense_category_id
              })}
            >
              <label className={'form-label'}>Category</label>
              <Controller
                name={'expense_category_id'}
                control={itemControl}
                render={props => (
                  <VuiSelect
                    selectRepository={ExpenseCategoryRepository}
                    defaultValue={props.value}
                    onChange={val => props.onChange(val)}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(itemErrors.expense_category_id, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: itemErrors.description
              })}
            >
              <label className={'form-label'}>Description</label>
              <textarea name='description' rows={3} className='form-control' ref={itemRegister} />
              <label className={'label-help'}>{_.get(itemErrors.description, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: itemErrors.amount
              })}
            >
              <label className={'form-label'}>Amount</label>
              <Controller
                name={'amount'}
                control={itemControl}
                defaultValue={null}
                render={props => (
                  <NumberFormat
                    className='form-control'
                    value={props.value}
                    allowNegative={false}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    placeholder={'IDR 0'}
                    prefix={'IDR '}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
              <label className={'label-help'}>{itemErrors.amount?.message}</label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showItemModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={itemLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal show={showItemDeleteModal.get()} onHide={() => showItemDeleteModal.set(false)} onContinue={handleItemDelete} />
    </>
  );
};

export default FinanceExpenseForm;

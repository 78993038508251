// @ts-nocheck
import React from 'react';
import VuiDateRangePicker from '../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import VuiNumberFormat from '../../vodea/@vodea-ui/components/VuiNumberFormat';
import { TableVirtualizeWidgetProps } from '../widget.interface';
import DataTableInfiniteScroll, { ITableColumn } from '../../vodea/@vodea-ui/components/DataTableInfiniteScroll';

const ListProductWidget: React.FC<TableVirtualizeWidgetProps> = ({
  handleSearch,
  tableData,
  isFetchingMoreData,
  handleChangeDate,
  tableConfig,
  callback,
  hasMore = false,
  hasInitialize = false,
  onTableChange,
  totalData = 0
}) => {
  const { t } = useTranslation();

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'quantity',
      text: 'T Quantity',
      sort: true,
      orderBy: 'default',
      render: (cell: any) => {
        return <VuiNumberFormat data={cell} />;
      }
    },
    {
      dataField: 'amount',
      text: 'T Sales Amount',
      sort: true,
      render: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    }
  ];

  return (
    <>
      <div className={'card-paper'}>
        <div className={'card-header'}>
          <div className={'card-header-filter-wrapper'}>
            <div className={'title-wrapper'}>
              <h6 className={'card-header-title'}>{t(`List Product`)}</h6>
            </div>
            <div className={'filter-item'}>
              <VuiDateRangePicker
                startDate={tableConfig.date_from}
                endDate={tableConfig.date_to}
                callback={handleChangeDate}
                useRanges={true}
              />
            </div>
          </div>
        </div>

        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item type-search-full'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input
                  defaultValue={tableConfig.search}
                  type='text'
                  className='form-control'
                  placeholder='Search'
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>

          {tableConfig.is_skeleton_loading ? (
            <div className={'table-box is-table-loading'} />
          ) : (
            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData}
              propKey={'id'}
              isLoading={tableConfig.loading}
              isFetchingMoreData={isFetchingMoreData}
              isVirtualized={true}
              callback={callback}
              hasMore={hasMore}
              hasInitialize={hasInitialize}
              onTableChange={onTableChange}
              totalData={totalData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListProductWidget;

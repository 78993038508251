// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { InformationInputs, informationSchema, InformationBaseModel } from './validation';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import StorageRepository from '../../../../../repositories/StorageRepository';
import ProductRepository from '../../../../../repositories/ProductRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { defaultStatusOptions } from '../../../../../constants';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import NumberFormat from 'react-number-format';
import { formatSetValueForm, formatFormData } from '../../../../../vodea/utilities/helpers/form';

const SettingStorageForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();

  const title = id ? t('Edit Storage') : t('Create Storage');

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    StorageRepository.show(id, {
      with: ['product']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(InformationBaseModel, data);

        _.forEach(formattedData, (value, name) => {
          informationSetValue(name, value);
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValue
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema)
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const formData = formatFormData(data);

    if (id === undefined) {
      StorageRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, response.data.success ? 'success' : 'error');

          navigate('/setting/storage');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      StorageRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Storage'),
      link: '/setting/storage'
    },
    {
      label: id ? id : t('Create'),
      link: '/setting/storage/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'product-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className='row'>
                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input type='text' name='name' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-4'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.section
                        })}
                      >
                        <label className={'form-label'}>Section</label>
                        <input type='text' name='section' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.section?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-4'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.rack
                        })}
                      >
                        <label className={'form-label'}>Rack</label>
                        <input type='text' name='rack' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.rack?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-4'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.palette
                        })}
                      >
                        <label className={'form-label'}>Palette</label>
                        <input type='text' name='palette' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.palette?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.product_id
                        })}
                      >
                        <label className={'form-label'}>Product</label>
                        <Controller
                          name={'product_id'}
                          control={informationControl}
                          render={props => (
                            <VuiSelect selectRepository={ProductRepository} defaultValue={props.value} onChange={props.onChange} />
                          )}
                        />
                        <label className={'label-help'}>{_.get(informationErrors.product_id, 'message')}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.capacity
                        })}
                      >
                        <label className={'form-label'}>Capacity</label>
                        <Controller
                          name={'capacity'}
                          control={informationControl}
                          render={props => (
                            <NumberFormat
                              className='form-control'
                              value={props.value}
                              allowNegative={false}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                              onValueChange={({ value }) => props.onChange(value)}
                            />
                          )}
                        />
                        <label className={'label-help'}>{informationErrors.capacity?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'visibility-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Visibility')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.is_visible
                      })}
                    >
                      <label className={'form-label'}>Status</label>
                      <div className={'form-select-wrapper'}>
                        <Controller
                          name={'is_visible'}
                          control={informationControl}
                          defaultValue={defaultStatusOptions[0]}
                          render={props => (
                            <VuiSelect options={defaultStatusOptions} defaultValue={props.value} onChange={props.onChange} />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.is_visible, 'message')}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/setting/storage'} />
        </form>
      </div>
    </>
  );
};

export default SettingStorageForm;

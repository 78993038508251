// @ts-nocheck
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faTrash,
  faFileAudio,
  faFileVideo,
  faFileAlt,
  faFilePdf,
  faFileExcel,
  faFileWord
} from '@fortawesome/free-solid-svg-icons';
import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { fileTypes } from '../../../../../types';
import { none, useState } from '@hookstate/core/dist';
import { BaseModelMedia, MediaProps } from '../../../../interfaces/media';
import _ from 'lodash';
import clsx from 'clsx';
import { VuiButton } from '../../VuiButton';

interface VuiUploadFilesProps {
  accept?: fileTypes;
  parentRef?: any;
}

const VuiUploadFiles: React.FC<VuiUploadFilesProps> = ({ accept = '', parentRef = false }) => {
  const { t } = useTranslation();

  const mediaList = useState<MediaProps[]>([]);
  const useMediaList = mediaList.get();

  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(200);

  useLayoutEffect(() => {
    function updateSize() {
      cardHeight.set(_.get(ref, 'current.offsetWidth', 0));
    }
    window.addEventListener('resize', _.debounce(updateSize, 500));
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [useMediaList.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnDrop = (acceptedFiles: any) => {
    acceptedFiles.map((file: any, key: number) => {
      Object.assign(file, {
        url: URL.createObjectURL(file)
      });

      mediaList[mediaList.get().length].set({
        ...BaseModelMedia,
        ...file,
        id: 0,
        extension: file.type,
        selected: true
      });

      return file;
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: handleOnDrop,
    onDropRejected: event => {},
    multiple: true
  });

  const handleSelectMedia = (key: number) => {
    mediaList[key].set((item: MediaProps) => {
      return {
        ...item,
        selected: !item.selected
      };
    });
  };

  const removeSelectedItem = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    mediaList.set((items: Array<MediaProps>) => {
      return items.filter((item: MediaProps) => {
        return !item.selected;
      });
    });
  };

  const handleDelete = (e: React.MouseEvent<any>, key: number) => {
    e.stopPropagation();
    mediaList[key].set(none);
  };

  const renderFilledCard = (item: MediaProps) => {
    if (item.extension.includes('image')) {
      return <img className='image' src={item.url} alt='media' />;
    } else if (item.extension.includes('audio')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFileAudio} />;
    } else if (item.extension.includes('video')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFileVideo} />;
    } else if (item.extension.includes('pdf')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFilePdf} />;
    } else if (item.extension.includes('pdf')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFilePdf} />;
    } else if (item.extension.includes('sheet')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFileExcel} />;
    } else if (item.extension.includes('document')) {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFileWord} />;
    } else {
      return <FontAwesomeIcon className={'filled-icon'} icon={faFileAlt} />;
    }
  };

  return (
    <div className='card-upload-wrapper'>
      <div className='upload-dropzone' {...getRootProps()}>
        <input {...getInputProps()} />
        <FontAwesomeIcon className='icon' icon={faFileUpload} />
        <div className='upload-text'>
          <h3 className='title'>{t('Select Files')}</h3>
          <p className='summary'>{t('Drop files here or click browse thorough your machine')}</p>
        </div>
      </div>

      {mediaList.get().length ? (
        <div className='default-btn-action-wrapper'>
          {_.filter(mediaList.get(), ['selected', true]).length ? (
            <VuiButton
              customClass={'btn-small'}
              variant={'danger'}
              label={`Remove (${_.filter(mediaList.get(), ['selected', true]).length}) Files`}
              loading={false}
              onClick={removeSelectedItem}
            />
          ) : (
            ''
          )}
          <VuiButton customClass={'btn-small'} variant={'success'} label={`Upload (${mediaList.get().length}) Files`} loading={false} />
        </div>
      ) : (
        ''
      )}

      <div className='card-files-section'>
        {mediaList.get().map((item, key) => {
          return (
            <div
              key={key}
              className={clsx([
                'card-file',
                {
                  checked: item.selected,
                  filled: true
                }
              ])}
              style={{
                height: cardHeight.get()
              }}
              onClick={() => handleSelectMedia(key)}
            >
              <div className='card-inner'>
                <div className='card-filled-wrapper'>
                  {renderFilledCard(item)}

                  <div className='info-wrapper'>
                    <span className='badge rounded-pill bg-info text-dark info-size'>1 MB</span>
                    <span className='info-name'>{item.path}</span>
                  </div>

                  <div className='action-wrapper'>
                    <input className='action-check form-check-input' type='checkbox' checked={item.selected} readOnly />
                    <FontAwesomeIcon className={'icon-delete-file'} icon={faTrash} onClick={e => handleDelete(e, key)} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div
          className='card-file invisible'
          ref={ref}
          style={{
            height: cardHeight.get()
          }}
        ></div>
      </div>
    </div>
  );
};

export default VuiUploadFiles;

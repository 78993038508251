import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  customer_id: string;
  name: string;
  email: string;
  phone_number: string;
  dob: object;
  role: object;
  photo: object;
  monthly_payroll: string;
  daily_payroll: string;
};

export const informationSchema = yup.object().shape({
  customer_id: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().required(),
  phone_number: yup.string().required(),
  dob: yup.mixed().required(),
  role: yup.mixed().required(),
  photo: yup.mixed().required(),
  monthly_payroll: yup.string().required(),
  daily_payroll: yup.string().required()
});

import * as yup from 'yup';
import moment from 'moment';

export type InformationInputs = {
  driver_id: object;
  pic_id: object;
  date: object;
};

export const informationSchema = yup.object().shape({
  driver_id: yup.mixed().required().label('Driver'),
  pic_id: yup.mixed().required().label('PIC'),
  date: yup.mixed().required().label('Date')
});

export const InformationBaseModel: InformationInputs = {
  driver_id: {},
  pic_id: {},
  date: moment()
};

// @ts-nocheck
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from '@hookstate/core/dist';
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { Controller, useForm } from 'react-hook-form';
import { InformationInputs, informationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import VuiActionForm from '../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import clsx from 'clsx';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import UserRepository from '../../../../repositories/UserRepository';
import AttendanceRepository from '../../../../repositories/AttendanceRepository';
import _ from 'lodash';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { formatFormData, formatSetValueForm } from '../../../../vodea/utilities/helpers/form';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../vodea/utilities/helpers/global';

const AttendanceForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();

  const title = id ? t('Edit Attendance') : t('Create Attendance');

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line

  const getData = () => {
    AttendanceRepository.show(id, {
      with: ['user']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(
          {
            user_id: null,
            date: null,
            time_in: null,
            time_out: null
          },
          data
        );

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else if (name === 'time_in' || name === 'time_out') {
            informationSetValue(name, value ? moment(value, 'HH:mm:ss').format('HH:mm') : '');
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValue
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      date: moment(),
      time_in: moment().format('HH:mm')
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const formData = formatFormData(data);

    if (id === undefined) {
      AttendanceRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, response.data.success ? 'success' : 'error');
          informationLoading.set(false);
          navigate('/attendance');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      AttendanceRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });
            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  const breadcrumbList = [
    {
      label: t('Attendance'),
      link: '/attendance'
    },
    {
      label: id ? id : t('Create'),
      link: '/attendance/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
        <div className='default-page-layout'>
          <div className='information-section'>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.user_id
                      })}
                    >
                      <label className={'form-label'}>Employee</label>
                      <Controller
                        name={'user_id'}
                        control={informationControl}
                        render={props => (
                          <VuiSelect
                            selectParams={{
                              only: ['cashier', 'employee']
                            }}
                            selectRepository={UserRepository}
                            defaultValue={props.value}
                            onChange={props.onChange}
                          />
                        )}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.user_id, 'message')}</label>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.date
                      })}
                    >
                      <label className={'form-label'}>Date</label>
                      <Controller
                        name={'date'}
                        control={informationControl}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={false} />
                        )}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Time Details')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.time_in
                  })}
                >
                  <label className={'form-label'}>Clock In</label>
                  <Controller
                    name={'time_in'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <Cleave
                        value={value}
                        className='form-control'
                        placeholder={'HH:MM'}
                        options={{
                          time: true,
                          timePattern: ['h', 'm']
                        }}
                        onChange={onChange}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.time_in, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.time_out
                  })}
                >
                  <label className={'form-label'}>Clock Out</label>
                  <Controller
                    name={'time_out'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <Cleave
                        value={value}
                        className='form-control'
                        placeholder={'HH:MM'}
                        options={{
                          time: true,
                          timePattern: ['h', 'm']
                        }}
                        onChange={onChange}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.time_out, 'message')}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm loading={informationLoading.get()} cancelLink={'/attendance'} />
      </form>
    </>
  );
};

export default AttendanceForm;

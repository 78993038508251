import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { useState } from '@hookstate/core/dist';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import * as _ from 'lodash';
import moment from 'moment';
import { $clone } from '../../../../vodea/utilities';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { LoadingIndication, NoDataIndication } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import ReportRepository from '../../../../repositories/ReportRepository';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalDetailDelivery from './ModalDetailDelivery';

const DeliveryDriver = () => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [driverId, setDriverId] = React.useState();
  const tableConfig = useState({
    sorted_by: 'desc',
    order_by: 'name',

    // additional
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const [tableLoading, setTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'type',
      text: 'Type'
    },
    {
      dataField: 'total_delivery',
      text: 'Total Delivery'
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell: any, row: any) => {
        return (
          <div className={'table-btn-wrapper'}>
            <div
              className={'btn btn-primary btn-small'}
              onClick={() => {
                setDriverId(row.id);
                setIsOpenModal(true);
              }}
            >
              View
            </div>
          </div>
        );
      }
    }
  ];

  const getTableData = useCallback(async () => {
    setTableData([]);
    setTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    await ReportRepository.deliveryPerDriver(params)
      .then((response: AxiosResponse) => {
        setTableData(response.data.data);
        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        setTableLoading(false);
      });
  }, [tableConfig.get()]);

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableData().then();
  };

  useMemo(() => {
    getTableData().then();
  }, []);

  const breadcrumbList = [
    {
      label: 'Report',
      link: '/report'
    },
    {
      label: 'Report Delivery Driver and PIC',
      link: '/report/delivery-per-driver'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Delivery per Driver')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Delivery per Driver')}</h3>
      </div>

      <ModalDetailDelivery
        open={isOpenModal}
        driverId={driverId}
        params={{
          date_to: moment(tableConfig.date_to.get()).format('YYYY-MM-DD'),
          date_from: moment(tableConfig.date_from.get()).format('YYYY-MM-DD')
        }}
        onClose={() => {
          setIsOpenModal(false);
          setDriverId(undefined);
        }}
      />

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special'}>
              <VuiDateRangePicker
                startDate={tableConfig.date_from.get()}
                endDate={tableConfig.date_to.get()}
                callback={handleTableDateRange}
                useRanges={true}
              />
            </div>
          </div>

          <BootstrapTable
            wrapperClasses={'table-responsive'}
            keyField={'id'}
            data={tableData}
            columns={tableColumns}
            noDataIndication={() => (tableLoading ? <LoadingIndication /> : <NoDataIndication />)}
          />
        </div>
      </div>
    </>
  );
};

export default DeliveryDriver;

import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  is_visible: object;
  code: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  is_visible: yup.mixed().required().label('status'),
  code: yup.string().required()
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  is_visible: {},
  code: '',
};

import { Helmet } from 'react-helmet-async';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores';
import { useTranslation } from 'react-i18next';
import PurchaseOrderWidget from '../../../widgets/PurchaseOrderWidget';
import SalesOrderWidget from '../../../widgets/SalesOrderWidget';
import ReceivePaymentWidget from '../../../widgets/ReceivePaymentWidget';
import SendPaymentWidget from '../../../widgets/SendPaymentWidget';
import ListCustomerWidget from '../../../widgets/ListCustomerWidget';
import ListProductWidget from '../../../widgets/ListProductWidget';
import { useNavigate } from 'react-router-dom';
import { useState } from '@hookstate/core';
import moment from 'moment';
import _ from 'lodash';
import { $clone } from '../../../vodea/utilities';
import WidgetRepository from '../../../repositories/WidgetRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../vodea/utilities/hooks/useIsMounted';
import { IOrderDirectionType } from '../../../interfaces/helpers.interface';

const Dashboard: React.FC<any> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const [hasMoreProduct, setHasMoreProduct] = React.useState<boolean>(false);
  const [isFetchingMoreDataProduct, setIsFetchingMoreDataProduct] = React.useState<boolean>(false);
  const [hasInitializeProduct, setHasInitializeProduct] = React.useState<boolean>(false);

  const [hasMoreCustomer, setHasMoreCustomer] = React.useState<boolean>(false);
  const [isFetchingMoreDataCustomer, setIsFetchingMoreDataCustomer] = React.useState<boolean>(false);
  const [hasInitializeCustomer, setHasInitializeCustomer] = React.useState<boolean>(false);
  const [totalDataCustomer, setTotalDataCustomer] = React.useState<number>(0);
  const [totalDataProduct, setTotalDataProduct] = React.useState<number>(0);

  const isMounted = useIsMounted();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  useEffect(() => {
    if (user.role_user.role_id === '4') {
      navigate('/purchase/purchase-order');
    }
  }, []);

  // sales-order

  const salesOrderConfig = useState({
    total: 0,
    amount: 0,
    loading: true,
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
    channel: null
  });
  const chartLabelSalesOrder = useState<string[]>([]);
  const chartDataSalesOrder = useState<string[]>([]);

  const getDataSalesOrder = async () => {
    if (!permissions.includes('widgetsales-order')) {
      return;
    }

    if (isMounted.current) salesOrderConfig.loading.set(true);
    const conf = _.omit($clone(salesOrderConfig.value), ['loading', 'total', 'amount']);

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    let days = moment(params.date_to).diff(moment(params.date_from), 'days');

    await WidgetRepository.salesOrder({
      ...params,
      for: 'sales-order'
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const data = response.data;
          salesOrderConfig.total.set(data.total);
          salesOrderConfig.amount.set(data.amount);
          chartLabelSalesOrder.set([]);
          chartDataSalesOrder.set([]);
          if (days <= 30) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i <= days; i++) {
                chartLabelSalesOrder[i].set(moment(params.date_from).add(i, 'days').format('YYYY-MM-DD'));
                if (moment(params.date_from).add(i, 'days').format('YYYY-MM-DD') === item.label) {
                  chartDataSalesOrder[i].set(item.grand_total);
                }
              }
            });
          } else if (days / 30 <= 3) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i <= Math.ceil(days / 7); i++) {
                chartLabelSalesOrder[i].set(moment(params.date_from).add(i, 'week').format('YYYY-ww'));
                if (moment(params.date_from).add(i, 'week').format('YYYY-ww') === item.label) {
                  chartDataSalesOrder[i].set(item.grand_total);
                }
              }
            });
          } else if (days >= 91 && days <= 363) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i < Math.ceil(days / 30); i++) {
                chartLabelSalesOrder[i].set(moment(params.date_from).add(i, 'month').format('YYYY-MM'));
                if (moment(params.date_from).add(i, 'month').format('YYYY-MM') === item.label) {
                  chartDataSalesOrder[i].set(item.grand_total);
                }
              }
            });
          } else {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i < Math.ceil(days / 364); i++) {
                chartLabelSalesOrder[i].set(moment(params.date_from).add(i, 'year').format('YYYY'));
                if (moment(params.date_from).add(i, 'year').format('YYYY') === item.label) {
                  chartDataSalesOrder[i].set(item.grand_total);
                }
              }
            });
          }
          salesOrderConfig.loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  const handleChangeDateSalesOrder = (start: any, end: any) => {
    salesOrderConfig.date_from.set(start.format('YYYY-MM-DD'));
    salesOrderConfig.date_to.set(end.format('YYYY-MM-DD'));

    getDataSalesOrder();
  };

  const receivePaymentConfig = useState({
    total: 0,
    amount: 0,
    loading: true,
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const chartLabelReceivePayment = useState<string[]>([]);
  const chartDataReceivePayment = useState<string[]>([]);

  const getDataReceivePayment = async () => {
    if (!permissions.includes('widgetreceive-payment')) {
      return;
    }

    if (isMounted.current) receivePaymentConfig.loading.set(true);

    const conf = _.omit($clone(receivePaymentConfig.value), 'loading');

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    let days = moment(params.date_to).diff(moment(params.date_from), 'days');

    await WidgetRepository.receivePayment({
      ...params,
      for: 'receive-payment'
    })
      .then((response: AxiosResponse) => {
        const data = response.data;
        receivePaymentConfig.total.set(data.total);
        receivePaymentConfig.amount.set(data.amount);
        chartLabelReceivePayment.set([]);
        chartDataReceivePayment.set([]);
        if (days <= 30) {
          data.chart.forEach((item: { label: string; amount: string }) => {
            for (let i = 0; i <= days; i++) {
              chartLabelReceivePayment[i].set(moment(params.date_from).add(i, 'days').format('YYYY-MM-DD'));
              if (moment(params.date_from).add(i, 'days').format('YYYY-MM-DD') === item.label) {
                chartDataReceivePayment[i].set(item.amount);
              }
            }
          });
        } else if (days / 30 <= 3) {
          data.chart.forEach((item: { label: string; amount: string }) => {
            for (let i = 0; i <= Math.ceil(days / 7); i++) {
              chartLabelReceivePayment[i].set(moment(params.date_from).add(i, 'week').format('YYYY-ww'));
              if (moment(params.date_from).add(i, 'week').format('YYYY-ww') === item.label) {
                chartDataReceivePayment[i].set(item.amount);
              }
            }
          });
        } else if (days >= 91 && days <= 363) {
          data.chart.forEach((item: { label: string; amount: string }) => {
            for (let i = 0; i < Math.ceil(days / 30); i++) {
              chartLabelReceivePayment[i].set(moment(params.date_from).add(i, 'month').format('YYYY-MM'));
              if (moment(params.date_from).add(i, 'month').format('YYYY-MM') === item.label) {
                chartDataReceivePayment[i].set(item.amount);
              }
            }
          });
        } else {
          data.chart.forEach((item: { label: string; amount: string }) => {
            for (let i = 0; i < Math.ceil(days / 364); i++) {
              chartLabelReceivePayment[i].set(moment(params.date_from).add(i, 'year').format('YYYY'));
              if (moment(params.date_from).add(i, 'year').format('YYYY') === item.label) {
                chartDataReceivePayment[i].set(item.amount);
              }
            }
          });
        }
        receivePaymentConfig.loading.set(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  const handleChangeDateReceivePayment = (start: any, end: any) => {
    receivePaymentConfig.date_from.set(start.format('YYYY-MM-DD'));
    receivePaymentConfig.date_to.set(end.format('YYYY-MM-DD'));

    getDataReceivePayment();
  };

  const sendPaymentConfig = useState({
    total: 0,
    amount: 0,
    loading: true,
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const chartLabelSendPayment = useState<string[]>([]);
  const chartDataSendPayment = useState<string[]>([]);

  const getDataSendPayment = async () => {
    if (!permissions.includes('widgetsend-payment')) {
      return;
    }

    if (isMounted.current) sendPaymentConfig.loading.set(true);
    const conf = _.omit($clone(sendPaymentConfig.value), 'loading');

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    let days = moment(params.date_to).diff(moment(params.date_from), 'days');

    await WidgetRepository.sendPayment({
      ...params,
      for: 'send-payment'
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const data = response.data;
          sendPaymentConfig.total.set(data.total);
          sendPaymentConfig.amount.set(data.amount);
          chartLabelSendPayment.set([]);
          chartDataSendPayment.set([]);
          if (days <= 30) {
            data.chart.forEach((item: { label: string; amount: string }) => {
              for (let i = 0; i <= days; i++) {
                chartLabelSendPayment[i].set(moment(params.date_from).add(i, 'days').format('YYYY-MM-DD'));
                if (moment(params.date_from).add(i, 'days').format('YYYY-MM-DD') === item.label) {
                  chartDataSendPayment[i].set(item.amount);
                }
              }
            });
          } else if (days / 30 <= 3) {
            data.chart.forEach((item: { label: string; amount: string }) => {
              for (let i = 0; i <= Math.ceil(days / 7); i++) {
                chartLabelSendPayment[i].set(moment(params.date_from).add(i, 'week').format('YYYY-ww'));
                if (moment(params.date_from).add(i, 'week').format('YYYY-ww') === item.label) {
                  chartDataSendPayment[i].set(item.amount);
                }
              }
            });
          } else if (days >= 91 && days <= 363) {
            data.chart.forEach((item: { label: string; amount: string }) => {
              for (let i = 0; i < Math.ceil(days / 30); i++) {
                chartLabelSendPayment[i].set(moment(params.date_from).add(i, 'month').format('YYYY-MM'));
                if (moment(params.date_from).add(i, 'month').format('YYYY-MM') === item.label) {
                  chartDataSendPayment[i].set(item.amount);
                }
              }
            });
          } else {
            data.chart.forEach((item: { label: string; amount: string }) => {
              for (let i = 0; i < Math.ceil(days / 364); i++) {
                chartLabelSendPayment[i].set(moment(params.date_from).add(i, 'year').format('YYYY'));
                if (moment(params.date_from).add(i, 'year').format('YYYY') === item.label) {
                  chartDataSendPayment[i].set(item.amount);
                }
              }
            });
          }
          sendPaymentConfig.loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  const handleChangeDateSendPayment = (start: any, end: any) => {
    sendPaymentConfig.date_from.set(start.format('YYYY-MM-DD'));
    sendPaymentConfig.date_to.set(end.format('YYYY-MM-DD'));

    getDataSendPayment();
  };

  const purchaseOrderConfig = useState({
    total: 0,
    amount: 0,
    loading: true,
    is_ppn: null,
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const chartLabelPurchaseOrder = useState<string[]>([]);
  const chartDataPurchaseOrder = useState<string[]>([]);

  const getDataPurchaseOrder = async () => {
    if (!permissions.includes('widgetpurchase-order')) {
      return;
    }

    if (isMounted.current) purchaseOrderConfig.loading.set(true);
    const conf = _.omit($clone(purchaseOrderConfig.value), 'loading');

    let params: any = {};

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    let days = moment(params.date_to).diff(moment(params.date_from), 'days');

    await WidgetRepository.purchaseOrder({
      ...params,
      for: 'purchase-order'
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const data = response.data;
          purchaseOrderConfig.total.set(data.total);
          purchaseOrderConfig.amount.set(data.amount);
          chartLabelPurchaseOrder.set([]);
          chartDataPurchaseOrder.set([]);
          if (days <= 30) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i <= days; i++) {
                chartLabelPurchaseOrder[i].set(moment(params.date_from).add(i, 'days').format('YYYY-MM-DD'));
                if (moment(params.date_from).add(i, 'days').format('YYYY-MM-DD') === item.label) {
                  chartDataPurchaseOrder[i].set(item.grand_total);
                }
              }
            });
          } else if (days / 30 <= 3) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i <= Math.ceil(days / 7); i++) {
                chartLabelPurchaseOrder[i].set(moment(params.date_from).add(i, 'week').format('YYYY-ww'));
                if (moment(params.date_from).add(i, 'week').format('YYYY-ww') === item.label) {
                  chartDataPurchaseOrder[i].set(item.grand_total);
                }
              }
            });
          } else if (days >= 91 && days <= 363) {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i < Math.ceil(days / 30); i++) {
                chartLabelPurchaseOrder[i].set(moment(params.date_from).add(i, 'month').format('YYYY-MM'));
                if (moment(params.date_from).add(i, 'month').format('YYYY-MM') === item.label) {
                  chartDataPurchaseOrder[i].set(item.grand_total);
                }
              }
            });
          } else {
            data.chart.forEach((item: { label: string; discount: string; grand_total: string; subtotal: string }) => {
              for (let i = 0; i < Math.ceil(days / 364); i++) {
                chartLabelPurchaseOrder[i].set(moment(params.date_from).add(i, 'year').format('YYYY'));
                if (moment(params.date_from).add(i, 'year').format('YYYY') === item.label) {
                  chartDataPurchaseOrder[i].set(item.grand_total);
                }
              }
            });
          }
          purchaseOrderConfig.loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  const handleChangeDatePurchaseOrder = (start: any, end: any) => {
    purchaseOrderConfig.date_from.set(start.format('YYYY-MM-DD'));
    purchaseOrderConfig.date_to.set(end.format('YYYY-MM-DD'));

    getDataPurchaseOrder();
  };

  const tableCustomerData = useState<any[]>([]);

  const tableCustomerConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'value',
    loading: false,
    total: 0,
    is_skeleton_loading: true,

    // additional

    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const changeTableCustomerLoading = (val: boolean) => {
    if (isMounted.current) {
      tableCustomerConfig.loading.set(val);
    }
  };

  const handleTableCustomerDateRange = (start: any, end: any) => {
    tableCustomerConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableCustomerConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableCustomerData(true);
  };

  const getTableCustomerData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (!permissions.includes('widgetcustomer-favourite')) {
      return;
    }

    if (reset) tableCustomerConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreDataCustomer(true);
      tableCustomerConfig.page.set(tableCustomerConfig.page.get() + 1);
    } else {
      changeTableCustomerLoading(true);
    }

    if (isMounted.current) {
      setHasMoreCustomer(true);
    }

    const conf = _.omit($clone(tableCustomerConfig.value), ['loading', 'total', 'is_skeleton_loading']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) {
      tableCustomerConfig.is_skeleton_loading.set(false);
      if (!forNextPage) tableCustomerData.set([]);
    }

    await WidgetRepository.listCustomer({ ...params })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableCustomerData.merge(responseData);
          setIsFetchingMoreDataCustomer(false);
          setHasInitializeCustomer(true);
          if (responseData.length < tableCustomerConfig.per_page.get()) {
            setHasMoreCustomer(false);
          }
          if (reset) setTotalDataCustomer(response.data.total_data);
        }

        changeTableCustomerLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setIsFetchingMoreDataCustomer(false);
          setHasInitializeCustomer(true);
          setTotalDataCustomer(0);
        }
        changeTableCustomerLoading(false);
      });
  };

  const handleTableCustomerSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    tableCustomerConfig.search.set(e.target.value);
    tableCustomerConfig.page.set(1);

    getTableCustomerData(true);
  }, 300);

  // widget product
  const tableProductConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'amount',
    loading: false,
    total: 0,
    is_skeleton_loading: true,

    // additional
    date_from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  });

  const tableProductData = useState<any[]>([]);

  const changeTableProductLoading = (val: boolean) => {
    if (isMounted.current) {
      tableProductConfig.loading.set(val);
    }
  };

  const getTableProductData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (!permissions.includes('widgetproduct-favourite')) {
      return;
    }

    if (reset) tableProductConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreDataProduct(true);
      tableProductConfig.page.set(tableProductConfig.page.get() + 1);
    } else {
      changeTableProductLoading(true);
    }

    if (isMounted.current) {
      setHasMoreProduct(true);
    }

    const conf = _.omit($clone(tableProductConfig.value), ['loading', 'total', 'is_skeleton_loading']);

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) {
      tableProductConfig.is_skeleton_loading.set(false);
      if (!forNextPage) tableProductData.set([]);
    }

    await WidgetRepository.listProduct({ ...params })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableProductData.merge(responseData);
          setIsFetchingMoreDataProduct(false);
          setHasInitializeProduct(true);
          if (responseData.length < tableProductConfig.per_page.get()) {
            setHasMoreProduct(false);
          }
          if (reset) setTotalDataProduct(response.data.total_data);
        }

        changeTableProductLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setIsFetchingMoreDataProduct(false);
          setHasInitializeProduct(true);
          setTotalDataProduct(0);
        }
        changeTableProductLoading(false);
      });
  };

  const handleTableProductDateRange = (start: any, end: any) => {
    tableProductConfig.date_from.set(start.format('YYYY-MM-DD'));
    tableProductConfig.date_to.set(end.format('YYYY-MM-DD'));

    getTableProductData(true);
  };

  const handleTableProductSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    tableProductConfig.search.set(e.target.value);
    tableProductConfig.page.set(1);

    getTableProductData(true);
  }, 300);

  const handleOnTableChangeCustomer = ({ orderBy, sortedBy }: IOrderDirectionType) => {
    if (orderBy) tableCustomerConfig.sorted_by.set(orderBy);
    if (sortedBy) tableCustomerConfig.order_by.set(sortedBy);

    getTableCustomerData(true);
  };

  const handleOnTableChangeProduct = ({ orderBy, sortedBy }: IOrderDirectionType) => {
    if (orderBy) tableProductConfig.sorted_by.set(orderBy);
    if (sortedBy) tableProductConfig.order_by.set(sortedBy);

    getTableProductData(true);
  };

  useMemo(() => {
    (async () => {
      await getDataSalesOrder().catch(() => {});
      await getDataReceivePayment().catch(() => {});
      await getDataSendPayment().catch(() => {});
      await getDataPurchaseOrder().catch(() => {});
      await getTableCustomerData(true).catch(() => {});
      await getTableProductData(true).catch(() => {});
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>

      <div id={'dashboard-section'}>
        {permissions.includes('widgetsales-order') && (
          <div className='mt-3'>
            <SalesOrderWidget
              handleChangeDate={handleChangeDateSalesOrder}
              config={salesOrderConfig.get()}
              chartData={$clone(chartDataSalesOrder.value)}
              chartLabel={$clone(chartLabelSalesOrder.value)}
              callback={getDataSalesOrder}
              stateConfig={salesOrderConfig}
            />
          </div>
        )}

        {permissions.includes('widgetreceive-payment') && (
          <div className='mt-3'>
            <ReceivePaymentWidget
              handleChangeDate={handleChangeDateReceivePayment}
              config={receivePaymentConfig.get()}
              chartData={$clone(chartDataReceivePayment.value)}
              chartLabel={$clone(chartLabelReceivePayment.value)}
            />
          </div>
        )}

        {permissions.includes('widgetsend-payment') && (
          <div className='mt-3'>
            <SendPaymentWidget
              handleChangeDate={handleChangeDateSendPayment}
              config={sendPaymentConfig.get()}
              chartData={$clone(chartDataSendPayment.value)}
              chartLabel={$clone(chartLabelSendPayment.value)}
            />
          </div>
        )}

        {permissions.includes('widgetpurchase-order') && (
          <div className='mt-3'>
            <PurchaseOrderWidget
              handleChangeDate={handleChangeDatePurchaseOrder}
              config={purchaseOrderConfig.get()}
              chartData={$clone(chartDataPurchaseOrder.value)}
              chartLabel={$clone(chartLabelPurchaseOrder.value)}
              callback={getDataPurchaseOrder}
              stateConfig={purchaseOrderConfig}
            />
          </div>
        )}

        {permissions.includes('widgetcustomer-favourite') && (
          <div className='grid-section one-section mt-3'>
            <ListCustomerWidget
              tableData={tableCustomerData.value}
              handleChangeDate={handleTableCustomerDateRange}
              callback={getTableCustomerData}
              tableConfig={tableCustomerConfig.value}
              handleSearch={handleTableCustomerSearch}
              hasMore={hasMoreCustomer}
              hasInitialize={hasInitializeCustomer}
              isFetchingMoreData={isFetchingMoreDataCustomer}
              onTableChange={handleOnTableChangeCustomer}
              totalData={totalDataCustomer}
            />
          </div>
        )}

        {permissions.includes('widgetproduct-favourite') && (
          <div className='grid-section one-section mt-3'>
            <ListProductWidget
              isFetchingMoreData={isFetchingMoreDataProduct}
              tableData={tableProductData.value}
              handleChangeDate={handleTableProductDateRange}
              tableConfig={tableProductConfig.value}
              callback={getTableProductData}
              handleSearch={handleTableProductSearch}
              hasMore={hasMoreProduct}
              hasInitialize={hasInitializeProduct}
              onTableChange={handleOnTableChangeProduct}
              totalData={totalDataProduct}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;

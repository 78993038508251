import * as yup from 'yup';

export type InformationInputs = {
    month: object;
    year: string;
};

export const informationSchema = yup.object().shape({
    month: yup.mixed().required(),
    year: yup.string().min(4, 'Must be exactly 4 digits').required(),
})

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import _ from 'lodash';
import VuiUploadImage from '../../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage';
import { useNavigate, useParams } from 'react-router-dom';
import { InformationBaseModel, InformationInputs, informationSchema } from './validation';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import CustomerRepository from '../../../../../repositories/CustomerRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import MembershipRepository from '../../../../../repositories/MembershipRepository';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import moment from "moment";

const ContactCustomerForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const title = id ? t('Edit Customer') : t('Create Customer');

  const informationData = useState({
    number: ''
  });

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    CustomerRepository.show(id, { with: ['membership', 'photo'] })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        if (isMounted.current) {
          informationData.set(data);
        }

        _.forEach(formattedData, (value, name) => {
          informationSetValue(name, value);
        });
      })
      .catch((e: AxiosError) => {
        //showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValue
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      date_of_birth: moment()
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const formData = formatFormData(data);

    if (id === undefined) {
      CustomerRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          navigate('/contact/customer');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });
            showToast(e.response.data.message, 'error');
          }
          informationLoading.set(false);
        });
    } else {
      CustomerRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });
            showToast(e.response.data.message, 'error');
          }
          informationLoading.set(false);
        });
    }
  });

  const breadcrumbList = [
    {
      label: t('Contact'),
      link: '/contact'
    },
    {
      label: t('Customer'),
      link: '/contact/customer'
    },
    {
      label: id ? id : t('Create'),
      link: '/contact/customer/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'contact-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout type-profile'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true
                        })}
                      >
                        <label className={'form-label'}>Customer ID</label>
                        <input type='text' name='code' className='form-control' value={informationData.number.get()} disabled />
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input type='text' name='name' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.email
                        })}
                      >
                        <label className={'form-label'}>Email</label>
                        <input type='text' name='email' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.email?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.mobile_phone
                        })}
                      >
                        <label className={'form-label'}>Phone Number</label>
                        <input type='text' name='mobile_phone' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.mobile_phone?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.date_of_birth
                        })}
                      >
                        <label className={'form-label'}>Date of Birth</label>
                        <Controller
                          name={'date_of_birth'}
                          control={informationControl}
                          render={({ value, onChange }) => (
                            <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={false} />
                          )}
                        />
                        <label className={'label-help'}>{_.get(informationErrors.date_of_birth, 'message')}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.membership_id
                        })}
                      >
                        <label className={'form-label'}>Membership</label>
                        <Controller
                          name={'membership_id'}
                          control={informationControl}
                          render={props => (
                            <VuiSelect
                              selectRepository={MembershipRepository}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              isMulti={false}
                            />
                          )}
                        />
                        <label className={'label-help'}>{_.get(informationErrors.membership_id, 'message')}</label>
                      </div>
                    </div>

                    <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.address
                        })}
                    >
                      <label className={'form-label'}>Address</label>
                      <textarea name='address' rows={5} className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.address?.message}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'profile-picture-section'}>
              <div className={'card-paper floating-content'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Profile Picture')}</h6>
                </div>
                <div className={'card-content'}>
                  <div
                    className={clsx({
                      'form-group': true,
                      error: informationErrors.photo_id
                    })}
                  >
                    <Controller
                      name='photo_id'
                      control={informationControl}
                      render={({ value, onChange }) => <VuiUploadImage defaultValue={value} onChange={onChange} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/contact/customer'} />
        </form>
      </div>
    </>
  );
};

export default ContactCustomerForm;

import { api } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  update: function (data: any) {
    return api.post(`${endPoint()}/api/account`, {
      ...data
    });
  },
  all: function (data: any = null) {
    return api.get(`${endPoint()}/api/account`, {
      ...data
    });
  },
  updatePassword: function (data: any) {
    return api.post(`${endPoint()}/api/account/update-password`, {
      ...data
    });
  }
};

export default Repository;

// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useNavigate, useParams } from 'react-router-dom';
import DeliveryOrderRepository from '../../../../repositories/DeliveryOrderRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { formatFormData, formatSetValueForm } from '../../../../vodea/utilities/helpers/form';
import { InformationBaseModel, InformationInputs, informationSchema } from './validation';
import _ from 'lodash';
import { mapHookErrors, showToast } from '../../../../vodea/utilities/helpers/global';
import { none, useState } from '@hookstate/core/dist';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import clsx from 'clsx';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import moment from 'moment';
import VuiActionForm from '../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExternalLinkAlt, faReceipt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SalesOrderRepository from '../../../../repositories/SalesOrderRepository';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { $clone } from '../../../../vodea/utilities';
import { useReactToPrint } from 'react-to-print';
import PrintDeliveryOrder from '../../../../prints/PrintDeliveryOrder';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReceivePaymentInputs, receivePaymentSchema } from '../../Sales/SalesOrder/Form/validation';
import PaymentMethodRepository from '../../../../repositories/PaymentMethodRepository';
import NumberFormat from 'react-number-format';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import ReceivePaymentRepository from '../../../../repositories/ReceivePaymentRepository';
import DriverRepository from '../../../../repositories/DriverRepository';

const endPoint = () => window._env_.REACT_APP_URL;

const DeliveryForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const grandTotalModal = useState<number>(0);
  const title = id ? t('Edit Delivery') : t('Create Delivery');
  const salesOrderForPrint = useState<any[]>([]);
  const salesOrderIds = useState<any[]>([]);
  const salesOrders = useState<any[]>([]);
  const showPaymentModal = useState(false);
  const paymentLoading = useState(false);
  const disablePrint = useState(false);
  const paymentData = useState<any[]>([]);
  const modalData = useState({
    number: '',
    payment: '',
    view_summary: {
      grand_total: ''
    }
  });

  const {
    handleSubmit: paymentHandleSubmit,
    control: paymentControl,
    errors: paymentErrors,
    reset: paymentReset,
    setError: paymentSetError
  } = useForm<ReceivePaymentInputs>({
    resolver: yupResolver(receivePaymentSchema),
    defaultValues: {
      date: moment()
    }
  });

  const getDataWithSummary = async () => {
    if (isMounted.current) disablePrint.set(true);
    await DeliveryOrderRepository.showWithSummary(id, {
      with: ['salesOrders.customer', 'creator']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(
          {
            driver: null,
            date: null,
            sales_orders: null
          },
          data
        );
        if (isMounted.current) {
          disablePrint.set(false);
          salesOrderForPrint.set(formattedData.sales_orders);
        }
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
        if (isMounted.current) disablePrint.set(false);
      });
  };

  const informationData = useState({
    number: '',
    creatorName: '',
    driverName: ''
  });

  const getPaymentData = async () => {
    await ReceivePaymentRepository.all({
      'sales-order': id,
      with: ['paymentMethod']
    })
      .then(async (response: AxiosResponse) => {
        const data = response.data.data;

        await paymentData.set(data);

        // calculateSummaryData();
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  useEffect(() => {
    if (id) {
      getData();
      // getDataWithSummary();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    DeliveryOrderRepository.show(id, {
      with: ['salesOrders.customer', 'creator', 'driverDetail', 'pic']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(
          {
            driver_id: null,
            pic_id: null,
            date: null,
            sales_orders: null
          },
          data
        );

        salesOrders.set(formattedData.sales_orders);

        if (isMounted.current) {
          disablePrint.set(false);
          informationData.number.set(_.get(data, 'number', ''));
          informationData.creatorName.set(_.get(data, 'creator.name', ''));
          informationData.driverName.set(_.get(data, 'driver_detail.name', ''));

          _.forEach(formattedData, (value: any, name: string) => {
            if (name === 'date') {
              informationSetValue(name, moment(value));
            } else if (name === 'driver_id') {
              informationSetValue(name, _.get(data, 'driver_detail'), {});
            } else if (name === 'pic_id') {
              informationSetValue(name, _.get(data, 'pic'), {});
            } else {
              informationSetValue(name, value);
            }
          });
        }

        salesOrderForPrint.set(formattedData.sales_orders);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValue,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: InformationBaseModel
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    const tempSalesOrders = salesOrders.get();
    const salesOrderIds = _.map(tempSalesOrders, 'id');

    Object.assign(formData, {
      sales_order_ids: salesOrderIds
    });

    if (id === undefined) {
      DeliveryOrderRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, response.data.success ? 'success' : 'error');

          navigate('/delivery');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      DeliveryOrderRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  // Sales Order Table
  const salesOrderColumns = [
    {
      dataField: 'number',
      text: 'Number',
      formatter: (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
        const { errors } = formatExtraData;
        return (
          <div
            className={clsx({
              'table-slot': true,
              'table-error': _.get(errors, `sales_order_ids[${rowIndex}]`)
            })}
          >
            <span className='table-data-content'>
              <a target='_blank' href={endPoint() + '/sales/sales-order/' + row.id}>
                {cell}
              </a>
            </span>

            {_.get(errors, `sales_order_ids[${rowIndex}]`) ? (
              <OverlayTrigger
                key={`table-error-${rowIndex}`}
                placement={'top'}
                overlay={<Tooltip id={`tooltip-table-${rowIndex}`}>{_.get(errors, `sales_order_ids[${rowIndex}].message`)}</Tooltip>}
              >
                <FontAwesomeIcon icon={faExclamationCircle} className='table-error-icon' />
              </OverlayTrigger>
            ) : (
              ''
            )}
          </div>
        );
      },
      formatExtraData: {
        errors: $clone(informationErrors)
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button
              type={'button'}
              className={'btn btn-primary btn-small'}
              onClick={() => {
                modalData.set({ ...row });
                grandTotalModal.set(row.grand_total);
                paymentReset();
                showPaymentModal.set(true);
              }}
            >
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faExternalLinkAlt} />
              View
            </button>
            <button
              type={'button'}
              className={'btn btn-danger btn-small'}
              style={{ padding: '0.55rem 0.75rem' }}
              onClick={() => {
                salesOrders[rowIndex].set(none);
                salesOrderIds[rowIndex].set(none);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const addSalesOrder = (val: any) => {
    const tempSalesOrders = salesOrders.get();
    const tempSalesOrderForPrint = salesOrderForPrint.get();

    if (_.filter(tempSalesOrders, o => o.id === val.id).length === 0) {
      salesOrders.merge([val]);
      salesOrderIds.merge([val.id]);
    }

    if (_.filter(tempSalesOrderForPrint, o => o.id === val.id).length === 0) {
      salesOrderForPrint.merge([val]);
    }
  };

  // Print DO
  const printDeliveryOrderRef = useRef<any>();
  const openPrintDO = useState(
    useReactToPrint({
      content: () => printDeliveryOrderRef.current
    })
  );

  const handlePrintDeliveryOrder = useReactToPrint({
    content: () => printDeliveryOrderRef.current
  });

  const handlePrintDO = async () => {
    await DeliveryOrderRepository.print(id)
      .then((response: AxiosResponse) => {
        openPrintDO.set(handlePrintDeliveryOrder);
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  const onPaymentSubmit = paymentHandleSubmit(data => {
    paymentLoading.set(true);

    const formData = formatFormData(data);

    Object.assign(formData, {
      sales_order_id: id
    });

    ReceivePaymentRepository.create(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        paymentLoading.set(false);
        showPaymentModal.set(false);
        getPaymentData();
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            paymentSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        paymentLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: t('Delivery'),
      link: '/delivery'
    },
    {
      label: id ? id : t('Create'),
      link: '/delivery/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>

        {id ? (
          <div className='action-wrapper'>
            <button disabled={disablePrint.get()} type={'button'} className={'btn btn-success action-item'} onClick={handlePrintDO}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faReceipt} />
              {t('Print DO')}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>

      <form className='form-wrapper' onSubmit={onInformationSubmit}>
        <div className={'default-page-layout layout-reverse'}>
          <div className={'information-section'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={informationData.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.driver_id
                  })}
                >
                  <label className={'form-label'}>Driver</label>
                  <div className={'form-select-wrapper'}>
                    <Controller
                      name={'driver_id'}
                      control={informationControl}
                      defaultValue={null}
                      render={props => (
                        <VuiSelect
                          selectParams={{
                            is_pic: 0
                          }}
                          selectRepository={DriverRepository}
                          defaultValue={props.value}
                          onChange={props.onChange}
                        />
                      )}
                    />
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.driver_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.pic_id
                  })}
                >
                  <label className={'form-label'}>PIC</label>
                  <div className={'form-select-wrapper'}>
                    <Controller
                      name={'pic_id'}
                      control={informationControl}
                      defaultValue={null}
                      render={props => (
                        <VuiSelect
                          selectParams={{
                            is_pic: 1
                          }}
                          selectRepository={DriverRepository}
                          defaultValue={props.value}
                          onChange={props.onChange}
                        />
                      )}
                    />
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.pic_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.date
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <Controller
                    name={'date'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Sales Order List')}</h6>
              </div>
              <div className={'card-content'}>
                <div className='form-group'>
                  <VuiSelect
                    propKey={$clone(salesOrderIds.get())}
                    selectParams={{
                      exclude: $clone(salesOrderIds.get())
                    }}
                    selectRepository={SalesOrderRepository}
                    clearable={true}
                    defaultValue={null}
                    labelKey={'number'}
                    onChange={addSalesOrder}
                  />
                </div>

                <BootstrapTable keyField='id' data={$clone(salesOrders.get())} columns={salesOrderColumns} />
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm loading={informationLoading.get()} cancelLink={'/delivery'} />
      </form>

      <Modal show={showPaymentModal.get()} onHide={() => showPaymentModal.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>Receive Payment</Modal.Title>
        </Modal.Header>

        <form className={'form-wrapper'} onSubmit={onPaymentSubmit}>
          <Modal.Body>
            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.date
              })}
            >
              <label className={'form-label'}>Number</label>
              <input type='text' className='form-control' disabled value={modalData.number.value} />
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.date
              })}
            >
              <label className={'form-label'}>Date</label>
              <Controller
                name={'date'}
                control={paymentControl}
                render={({ value, onChange }) => (
                  <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={false} />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.date, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.payment_method_id
              })}
            >
              <label className={'form-label'}>Payment Method</label>
              <Controller
                name={'payment_method_id'}
                control={paymentControl}
                render={props => (
                  <VuiSelect selectRepository={PaymentMethodRepository} defaultValue={props.value} onChange={props.onChange} />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.payment_method_id, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.date
              })}
            >
              <label className={'form-label'}>Total</label>
              <input type='text' className='form-control' disabled value={grandTotalModal.value} />
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.amount
              })}
            >
              <label className={'form-label'}>Amount</label>
              <Controller
                name={'amount'}
                control={paymentControl}
                render={props => (
                  <NumberFormat
                    className={'form-control'}
                    value={props.value}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    prefix={'IDR '}
                    placeholder={'IDR 0'}
                    max={5000000}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.amount, 'message')}</label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showPaymentModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={paymentLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>

      <div className='visually-hidden'>
        <PrintDeliveryOrder
          data={{
            ...informationWatch(),
            ...$clone(informationData.get()),
            salesOrders: $clone(salesOrderForPrint.get())
          }}
          componentRef={printDeliveryOrderRef}
        />
      </div>
    </>
  );
};

export default DeliveryForm;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { useState } from '@hookstate/core/dist';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InformationInputs, informationSchema, ReturnItemInputs, returnItemSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import clsx from 'clsx';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import _ from 'lodash';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import CustomerRepository from '../../../../../repositories/CustomerRepository';
import SalesOrderRepository from '../../../../../repositories/SalesOrderRepository';
import SalesReturnRepository from '../../../../../repositories/SalesReturnRepository';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import { AxiosError, AxiosResponse } from 'axios';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import { $clone } from '../../../../../vodea/utilities';
import NumberFormat from 'react-number-format';
import { salesReturnItemStockTreatOptions, salesReturnItemTypeOptions } from '../../../../../constants';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';

const SalesReturnItemForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const isClosed = useState(false);

  const title = id ? t('Edit Return Item') : t('Create Return Item');

  const informationData = useState({
    number: ''
  });

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line

  const getData = () => {
    SalesReturnRepository.show(id, {
      with: [
        'customer',
        'salesOrder',
        'salesReturnItems.unit',
        'salesReturnItems.product',
        'salesReturnItems.type',
        'salesReturnItems.stockTreat'
      ]
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        isClosed.set(data.is_close && data.is_close == 1);

        const formattedData = formatSetValueForm(
          {
            customer_id: null,
            date: null,
            sales_order_id: null,
            sales_return_items: null
          },
          data
        );

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));

          if (formattedData?.sales_return_items?.length > 0) {
            formattedData.sales_return_items.forEach((item: any, key: number) => {
              formattedData.sales_return_items[key] = formatSetValueForm(
                {
                  id,
                  ...{
                    sales_order_item_id: null,
                    type_id: null,
                    stock_treat_id: null,
                    quantity: 0
                  }
                },
                item
              );

              formattedData.sales_return_items[key].unit_name = _.get(item, 'unit.name');

              formattedData.sales_return_items[key].sales_order_item_id = {
                id: _.get(item, 'sales_order_item_id'),
                name: _.get(item, 'product.name'),
                unit_name: _.get(item, 'unit.name')
              };
            });
          }
        }

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        // showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      customer_id: null,
      sales_order_id: null,
      date: moment()
    }
  });

  const watchCustomerId = informationWatch('customer_id');
  const watchSalesOrderId = informationWatch('sales_order_id');

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    Object.assign(formData, {
      sales_return_items: []
    });

    if (itemFields.length !== 0) {
      itemFields.forEach((item: any, key: number) => {
        formData.sales_return_items[key] = formatFormData(_.omit(item, ['key']));
      });
    }

    (id ? SalesReturnRepository.update(id, formData) : SalesReturnRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/sales/return-item');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });
          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  // Table Product
  const { fields: itemFields, append: itemAppend, insert: itemInsert, remove: itemRemove } = useFieldArray({
    keyName: 'key',
    name: 'sales_return_items',
    control: informationControl
  });

  const productColumns = [
    {
      dataField: 'sales_order_item_id.name',
      text: 'Name'
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      formatter: (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
        console.log(row);
        const { errors } = formatExtraData;
        return (
          <div
            className={clsx({
              'table-slot': true,
              'table-error': _.get(errors, `sales_return_items[${rowIndex}]quantity`)
            })}
          >
            <span className='table-data-content'>{cell}</span>

            {_.get(errors, `sales_return_items[${rowIndex}]quantity`) ? (
              <OverlayTrigger
                key={`table-error-${rowIndex}`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-table-${rowIndex}`}>{_.get(errors, `sales_return_items[${rowIndex}]quantity.message`)}</Tooltip>
                }
              >
                <FontAwesomeIcon icon={faExclamationCircle} className='table-error-icon' />
              </OverlayTrigger>
            ) : (
              ''
            )}
          </div>
        );
      },
      formatExtraData: {
        errors: $clone(informationErrors)
      }
    },
    {
      dataField: 'sales_order_item_id.unit_name',
      text: 'Unit'
    },
    {
      dataField: 'type_id.name',
      text: 'Type'
    },
    {
      dataField: 'stock_treat_id.name',
      text: 'Stock Treat'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleItemModal(true, rowIndex)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handleDifferenceClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const itemDeleteIndex = useState(0);
  const showItemDeleteModal = useState(false);

  const handleDifferenceClickDelete = (index: number) => {
    itemDeleteIndex.set(index);
    showItemDeleteModal.set(true);
  };

  const handleItemDelete = () => {
    itemRemove(itemDeleteIndex.get());
    showItemDeleteModal.set(false);
    itemDeleteIndex.set(0);
  };

  const {
    handleSubmit: itemHandleSubmit,
    control: itemControl,
    setValue: itemSetValue,
    errors: itemErrors,
    reset: itemReset
  } = useForm<ReturnItemInputs>({
    resolver: yupResolver(returnItemSchema),
    defaultValues: {
      id: 0,
      sales_order_item_id: null,
      type_id: salesReturnItemTypeOptions[0],
      stock_treat_id: salesReturnItemStockTreatOptions[0]
    }
  });

  const onItemSubmit = itemHandleSubmit(data => {
    const tempData = {
      ...data
    };

    if (selectedItemIndex.get() === -1) {
      itemAppend(tempData);
    } else {
      itemInsert(selectedItemIndex.get() + 1, tempData);
      itemRemove(selectedItemIndex.get());
    }

    showItemModal.set(false);
  });

  const showItemModal = useState(false);
  const selectedItemIndex = useState(-1);

  const handleItemModal = (show: boolean, index: number = -1) => {
    showItemModal.set(show);
    selectedItemIndex.set(index);
    itemReset();

    if (index !== -1) {
      const selectedData = itemFields[index];

      setTimeout(() => {
        itemSetValue('id', _.get(selectedData, 'id'));
        itemSetValue('sales_order_item_id', _.get(selectedData, 'sales_order_item_id'));
        itemSetValue('type_id', _.get(selectedData, 'type_id'));
        itemSetValue('stock_treat_id', _.get(selectedData, 'stock_treat_id'));
        itemSetValue('quantity', _.get(selectedData, 'quantity'));
      }, 500);
    }
  };

  useEffect(() => {
    getProductOptions();
  }, [watchSalesOrderId]);

  const productOptions = useState<any[]>([]);

  const getProductOptions = () => {
    productOptions.set([]);
    if (watchSalesOrderId) {
      SalesOrderRepository.show(_.get(watchSalesOrderId, 'id'), {
        with: ['salesOrderItems.product', 'salesOrderItems.unit']
      })
        .then((response: AxiosResponse) => {
          const data = response.data.data;

          if (data.sales_order_items.length) {
            data.sales_order_items.forEach((item: any) => {
              productOptions.merge([
                {
                  id: item.id,
                  name: _.get(item, 'product.name'),
                  unit_name: _.get(item, 'unit.name')
                }
              ]);
            });
          }
        })
        .catch((e: AxiosError) => {
          showToast(e.message, 'error');
        });
    }
    // else {
    //   productOptions.set([]);
    // }
  };

  const breadcrumbList = [
    {
      label: t('Sales'),
      link: '/sales'
    },
    {
      label: t('Return Item'),
      link: '/sales/return-item'
    },
    {
      label: id ? id : t('Create'),
      link: '/sales/return-item/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
        <div className='default-page-layout layout-reverse'>
          <div className={'information-section'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>

              <div className='card-content'>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={informationData.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.date
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <Controller
                    name={'date'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.customer_id
                  })}
                >
                  <label className={'form-label'}>Customer</label>
                  <Controller
                    name={'customer_id'}
                    control={informationControl}
                    render={props => (
                      <VuiSelect
                        selectRepository={CustomerRepository}
                        defaultValue={props.value}
                        onChange={value => {
                          props.onChange(value);
                          informationSetValue('sales_order_id', null);
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.customer_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.sales_order_id
                  })}
                >
                  <label className={'form-label'}>Sales Order</label>
                  <Controller
                    name={'sales_order_id'}
                    control={informationControl}
                    render={props => (
                      <VuiSelect
                        propKey={_.get(watchCustomerId, 'id')}
                        selectParams={{
                          for: 'sales-return',
                          customer: _.get(watchCustomerId, 'id')
                        }}
                        labelKey={'number'}
                        selectRepository={SalesOrderRepository}
                        defaultValue={props.value}
                        onChange={value => {
                          props.onChange(value);
                        }}
                        isDisabled={watchCustomerId === null}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.sales_order_id, 'message')}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='multiple-paper-section'>
            <div className='card-paper'>
              <div className='card-header'>
                <h6 className={'card-header-title'}>{t('Product List')}</h6>
              </div>

              <div className='card-content'>
                {watchSalesOrderId !== null ? (
                  <>
                    <BootstrapTable keyField='key' data={itemFields} columns={productColumns} />

                    <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleItemModal(true)}>
                      <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                      {t('Add Product')}
                    </button>
                  </>
                ) : (
                  <div className='card-empty-content'>please select Sales Order first</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {!isClosed.get() && <VuiActionForm loading={informationLoading.get()} cancelLink={'/sales/return-item'} />}
      </form>

      <Modal show={showItemModal.get()} onHide={() => showItemModal.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItemIndex.get() === -1 ? t('Add Product') : t('Edit Product')}</Modal.Title>
        </Modal.Header>

        <form className={'form-wrapper'} onSubmit={onItemSubmit}>
          <Modal.Body>
            <Controller
              name={'id'}
              control={itemControl}
              defaultValue={0}
              render={props => <input type={'hidden'} defaultValue={props.value} />}
            />

            <div
              className={clsx({
                'form-group': true,
                error: itemErrors.sales_order_item_id
              })}
            >
              <label className={'form-label'}>Product</label>
              <Controller
                name={'sales_order_item_id'}
                control={itemControl}
                render={props => (
                  <VuiSelect
                    options={$clone(productOptions.get())}
                    defaultValue={props.value}
                    onChange={value => {
                      props.onChange(value);
                    }}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(itemErrors.sales_order_item_id, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: itemErrors.quantity
              })}
            >
              <label className={'form-label'}>Quantity</label>
              <Controller
                name={'quantity'}
                control={itemControl}
                defaultValue={null}
                render={props => (
                  <NumberFormat
                    className='form-control'
                    value={props.value}
                    allowNegative={false}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    placeholder={'0'}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(itemErrors, 'quantity.message')}</label>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: itemErrors.type_id
                  })}
                >
                  <label className={'form-label'}>Type</label>
                  <Controller
                    name={'type_id'}
                    control={itemControl}
                    render={props => (
                      <VuiSelect
                        options={salesReturnItemTypeOptions}
                        defaultValue={props.value}
                        onChange={value => {
                          props.onChange(value);
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(itemErrors.type_id, 'message')}</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: itemErrors.stock_treat_id
                  })}
                >
                  <label className={'form-label'}>Stock Treat</label>
                  <Controller
                    name={'stock_treat_id'}
                    control={itemControl}
                    render={props => (
                      <VuiSelect
                        options={salesReturnItemStockTreatOptions}
                        defaultValue={props.value}
                        onChange={value => {
                          props.onChange(value);
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(itemErrors.stock_treat_id, 'message')}</label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showItemModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={false} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal show={showItemDeleteModal.get()} onHide={() => showItemDeleteModal.set(false)} onContinue={handleItemDelete} />
    </>
  );
};

export default SalesReturnItemForm;

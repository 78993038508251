import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  email: string;
  telephone: string;
  address: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required().email(),
  telephone: yup.string().phone('ID', true).required().label('phone number'),
  address: yup.string()
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  email: '',
  telephone: '',
  address: '',
};

// @ts-nocheck
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import useScroll from '../../../utilities/hooks/useScroll';

const UpButton: FC<any> = () => {
  const { showButton } = useScroll();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      className={clsx({
        'button-arrow-up': true,
        show: showButton
      })}
    >
      <FontAwesomeIcon icon={faChevronUp} className={'icon icon-prefix'} onClick={scrollToTop} />
    </div>
  );
};

export default UpButton;

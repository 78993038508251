import * as yup from 'yup';
import 'yup-phone';

export type ProductInputs = {
  id: number | null;
  type_id: object | null;
  product_id: object | null;
  storage_id: object | null;
  unit_id: object | null;
  quantity: number;
  available_stock: number;
  price: number;
  discount: number;
  total: number;
};

export const productSchema = yup.object().shape({
  id: yup.mixed(),
  type_id: yup.mixed().required().label('type'),
  product_id: yup.mixed().required().label('product'),
  unit_id: yup.mixed().required().label('unit'),
  storage_id: yup.mixed().required().label('storage'),
  price: yup.number().typeError('field must be a number.').label('price').required(),
  quantity: yup.number().typeError('field must be a number.').required(),
  available_stock: yup.number().typeError('field must be a number.').required(),
  discount: yup.number().typeError('field must be a number.').required(),
  total: yup.number().typeError('field must be a number.')
});

export type InformationInputs = {
  customer_id: object | null;
  sales_id: object | null;
  date: object;
  term_of_payment: number;
  channel_id: object | null;
  payment: number;
  note: string;
  discount: number;
  sales_order_items: ProductInputs[];
};

export const informationSchema = yup.object().shape({
  customer_id: yup.mixed().required().label('Customer'),
  sales_id: yup.mixed().required().label('Sales'),
  date: yup.mixed().required().label('Date'),
  term_of_payment: yup.number().typeError('field must be a number.').required().label('Term of Payment'),
  channel_id: yup.mixed().required().label('channel'),
  payment: yup.number().typeError('field must be a number.').required()
});

export type ReceivePaymentInputs = {
  date: object;
  payment_method_id: object;
  amount: number;
};

export const receivePaymentSchema = yup.object().shape({
  date: yup.mixed().required().label('Date'),
  payment_method_id: yup.mixed().required().label('payment method'),
  amount: yup.number().typeError('field must be a number.').required()
});

import * as yup from 'yup';
import 'yup-phone';

export type ProductConversionInputs = {
  id?: number | null;
  unit_id: object | null;
  quantity: number | null;
};

export type ProductTierInputs = {
  id?: number | null;
  quantity?: number | null;
  unit_id: object | null;
  product_tier_prices: ProductTierPriceInputs[];
};

export type ProductTierPriceInputs = {
  id?: number | null;
  membership_id: number;
  price: object;
};

export type InformationInputs = {
  name: string;
  sku: string;
  barcode: string;
  description: string;
  product_photos: object;
  unit_id: object;
  default_price: number;

  is_visible: object;
  category_id: any;
  supplier_id: object;
  minimum_stock: number;

  product_conversions: ProductConversionInputs[];
  product_tiers: ProductTierInputs[];
  profit?: number;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  sku: yup.string(),
  barcode: yup.string(),
  description: yup.string(),
  product_photos: yup.mixed().required(),
  unit_id: yup.mixed().required().label('unit'),
  default_price: yup.number().typeError('field must be a number.').required().label('default price'),

  is_visible: yup.mixed().required().label('status'),
  category_id: yup.mixed().required().label('category'),
  supplier_id: yup.mixed().required().label('supplier'),
  minimum_stock: yup.number().typeError('field must be a number.').required().label('minimum stock'),

  product_conversions: yup.array().of(
    yup.object().shape({
      unit_id: yup.mixed().required(),
      quantity: yup.number().typeError('field must be a number.').required()
    })
  ),

  product_tiers: yup.array().of(
    yup.object().shape({
      unit_id: yup.mixed(),
      quantity: yup.number().typeError('field must be a number.'),
      product_tier_prices: yup.array().of(
        yup.object().shape({
          membership_id: yup.mixed(),
          price: yup.number().typeError('field must be a number.')
        })
      )
    })
  )
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  sku: '',
  barcode: '',
  description: '',
  product_photos: [],
  unit_id: {},
  default_price: 0,
  is_visible: {},
  category_id: {},
  supplier_id: {},
  minimum_stock: 0,
  product_conversions: [],
  product_tiers: [],
  profit: 0
};

export const ProductConversionBaseModel: ProductConversionInputs = {
  unit_id: null,
  quantity: null
};

export const ProductTierBaseModel: ProductTierInputs = {
  unit_id: null,
  quantity: null,
  product_tier_prices: []
};

import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/customer`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/customer/${id}`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
    });
  },
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/customer`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.select.name].handleRequestCancellation().token
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/customer`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/customer/${id}`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/customer/${id}`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
    });
  },
  export: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/contact`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.export.name].handleRequestCancellation().token,
      responseType: 'blob'
    });
  }
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

// @ts-nocheck
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import React, { useMemo } from 'react';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useState } from '@hookstate/core';
import { Link } from 'react-router-dom';
import { $clone } from '../../../../vodea/utilities';
import WarehouseStockLogRepository from '../../../../repositories/WarehouseStockLogRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn, LoadingIndication } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import moment from 'moment';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';

const subjectTypeUrl = {
  SalesOrderItem: {
    url: '/sales/sales-order/',
    keyId: 'sales_order_id'
  },
  SalesReturnItem: {
    url: '/inventory/mutation/',
    keyId: 'sales_return_id'
  },
  GoodsReceiptItem: {
    url: '/purchase/receive-item/',
    keyId: 'goods_receipt_id'
  },
  StockOpnameProduct: {
    url: '/inventory/stock-opname/',
    keyId: 'stock_opname_id'
  }
};

export interface ITableMutation {
  defaultParams: any;
  permissions: string[];
}

const TableMutation: React.FC<ITableMutation> = ({ defaultParams, permissions }) => {
  const isMounted = useIsMounted();
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'created_at',
    total: 0,
    loading: false,
    with: ['product', 'subject']
  });

  const [totalQty, setTotalQty] = React.useState<number>(0);
  const [totalData, setTotalData] = React.useState<number>(0);
  const tableData = useState<any[]>([]);
  const tableColumns: ITableColumn[] = [
    {
      dataField: 'number',
      text: 'Number'
    },
    {
      dataField: 'product',
      text: 'Product Name',
      render: (cell: any) => <div>{cell.name}</div>
    },
    {
      dataField: 'quantity',
      text: 'Quantity'
    },
    {
      dataField: 'action',
      text: 'Action',
      render: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const type = row?.subject_type?.split('\\')?.pop();
        const subjectTypeData = _.get(subjectTypeUrl, type) || '';
        const id = _.get(row?.subject, subjectTypeData?.keyId) || '';
        if (type) {
          if (subjectTypeData) {
            return permissions.includes('warehouse-stock-log.update') ? (
              <div className={'table-btn-wrapper'}>
                <Link target='_blank' type={'button'} className={'btn btn-primary btn-small'} to={`${subjectTypeData.url}${id}`}>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faExternalLinkAlt} />
                  View
                </Link>
              </div>
            ) : (
              <div />
            );
          }
        }
        return <div />;
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    const { date_from: dateFrom, date_to: dateTo, ...baseParams } = defaultParams;
    let params: any = {
      ...baseParams,
      date_from: moment(dateFrom).startOf('day').toISOString(),
      date_to: moment(dateTo).endOf('day').toISOString()
    };

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    Object.assign(params, {
      search: params.search ? params.search : null
    });

    await WarehouseStockLogRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) {
            setTotalQty(response.data.available_quantity);
            setTotalData(response.data.total_data);
          } else {
            setTotalQty(prevState => prevState + response.data.available_quantity);
          }
        }
        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (e && e.message !== 'all canceled' && isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
          changeTableLoading(false);
        }
      });
  };

  useMemo(() => {
    getTableData(true);
  }, [defaultParams]);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  return (
    <>
      <h6 className={'mb-3 d-flex'}>
        <span className={'mr-2'}>Total Quantity :</span>
        <b>
          {tableConfig.loading.get() ? (
            <LoadingIndication styles={{ width: 18, height: 18 }} />
          ) : (
            <VuiNumberFormat data={totalQty} value={totalQty} />
          )}
        </b>
      </h6>
      <DataTableInfiniteScroll
        columns={tableColumns}
        data={tableData.get()}
        propKey={'id'}
        isLoading={tableConfig.loading.get()}
        isFetchingMoreData={isFetchingMoreData}
        totalData={totalData}
      />
    </>
  );
};

export default TableMutation;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import { useState } from '@hookstate/core/dist';
import VuiDataTable from '../../../../../vodea/@vodea-ui/components/VuiDataTable';
import { defaultActionFormatter } from '../../../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../stores';

const products = [
  {
    id: 'E201201',
    name: 'John Doe',
    role: 'Cashier',
    created: '12 Dec 2020'
  },
  {
    id: 'E201202',
    name: 'Budi Santoso',
    role: 'Cashier',
    created: '15 Dec 2020'
  }
];

const options = [
  { value: 'cashier', label: 'Cashier' },
  { value: 'admin', label: 'Admin' }
];

const EmployeeList: React.FC<any> = () => {
  const location = useLocation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const tableLoading = useState(false);
  const tablePage = useState(1);
  const tableSizePerPage = useState(5);
  const tableData = useState(products.slice(0, tableSizePerPage.get()));

  const tableColumns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'role',
      text: 'Role',
      sort: true
    },
    {
      dataField: 'created',
      text: 'Created Date'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname
      }
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    tableLoading.set(true);

    setTimeout(() => {
      tableData.set(products.slice(currentIndex, currentIndex + sizePerPage));
      tablePage.set(page);
      tableSizePerPage.set(sizePerPage);
      tableLoading.set(false);
    }, 500);
  };

  const breadcrumbList = [
    {
      label: 'Contact',
      link: '/customer'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Employee</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>Employee</h3>
        <div className={'btn-wrapper'}>
          <Link to={'/employee/create'}>
            <button type={'button'} className={'btn btn-primary'}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
              Add Employee
            </button>
          </Link>
        </div>
      </div>

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special type-search'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input type='text' className='form-control' placeholder='Search' />
              </div>
            </div>

            <div className={'filter-item'}>
              <div className={'form-select-wrapper'}>
                <Select className='basic-single' classNamePrefix='select' isClearable={true} options={options} />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableLoading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tablePage.get()}
            sizePerPage={tableSizePerPage.get()}
            totalSize={products.length}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeList;

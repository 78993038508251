import { api } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/profit-and-loss`, {
      params
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/profit-and-loss`, {
      params
    });
  },
  deliveryPerDriver: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/delivery-per-driver`, {
      params
    });
  },
  showDeliveryPerDriver: function (id: string | number, params: any = null) {
    return api.get(`${endPoint()}/api/report/delivery-per-driver/${id}`, {
      params
    });
  },
  salesRevenue: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/sales-revenue`, {
      params
    });
  },
  showSalesRevenue: function (id: string | number, params: any = null) {
    return api.get(`${endPoint()}/api/report/sales-revenue/${id}`, {
      params
    });
  },
  exportProductMutation: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/product-mutation`, {
      params,
      responseType: 'blob'
    });
  },
  exportProductMutationDetail: function (id: string | number, params: any = null) {
    return api.get(`${endPoint()}/api/report/product-mutation/${id}`, {
      params,
      responseType: 'blob'
    });
  }
};

export default Repository;

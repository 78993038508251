import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WarehouseStockProductRepository from '../../../../repositories/WarehouseStockProductRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import VuiDataTable from '../../../../vodea/@vodea-ui/components/VuiDataTable';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import TableMutation from './TableMutation';
import TableProductMutation from './TableProduct';
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";

type MutationProduct = {
  id: number;
  number: string;
  date: Date | string;
  name: string;
  total_sales: string;
};

type Props = {
  productId?: number;
  params: any;
  open: boolean;
  onClose: () => void;
};

const ModalDetailProductMutation = ({ productId, onClose, open, params }: Props) => {
  const [currentTab, setCurrentTab] = React.useState<string>('StockIn');
  const [stockInData, setStockInData] = useState<MutationProduct[]>([]);
  const [stockInLoading, setStockInLoading] = useState<boolean>(false);
  const [stockInQuery, setStockInQuery] = useState({
    page: 1,
    per_page: 10
  });
  const [totalStockInData, setTotalStockInData] = useState<number>(0);

  const [stockOutData, setStockOutData] = useState<MutationProduct[]>([]);
  const [stockOutLoading, setStockOutLoading] = useState<boolean>(false);
  const [stockOutQuery, setStockOutQuery] = useState({
    page: 1,
    per_page: 10
  });
  const [totalStockOutData, setTotalStockOutData] = useState<number>(0);

  useEffect(() => {
    loadStockInData().then();
  }, [productId, params, stockInQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadStockOutData().then();
  }, [productId, params, stockOutQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadStockInData = useCallback(async () => {
    if (!productId || !params) {
      return;
    }

    setStockInLoading(true);

    await WarehouseStockProductRepository.showIn(productId, {
      ...params,
      ...stockInQuery
    })
      .then((response: AxiosResponse) => {
        setStockInData(response.data.data);
        setTotalStockInData(response.data.meta.total);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      })
      .finally(() => {
        setStockInLoading(false);
      });
  }, [productId, stockInQuery, params]);

  const loadStockOutData = useCallback(async () => {
    if (!productId || !params) {
      return;
    }

    setStockOutLoading(true);

    await WarehouseStockProductRepository.show(productId, {
      ...params,
      ...stockOutQuery
    })
      .then((response: AxiosResponse) => {
        setStockOutData(response.data.data);
        setTotalStockOutData(response.data.meta.total);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      })
      .finally(() => {
        setStockOutLoading(false);
      });
  }, [productId, stockOutQuery, params]);

  const stockInTableColumns = [
    {
      dataField: 'number',
      text: 'GR Number'
    },
    {
      dataField: 'name',
      text: 'Supplier'
    },
    {
      dataField: 'total_receipt',
      text: 'Total',
      formatter: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    }
  ];

  const stockOutTableColumns = [
    {
      dataField: 'number',
      text: 'Sales Order Number'
    },
    {
      dataField: 'name',
      text: 'Customer'
    },
    {
      dataField: 'total_sales',
      text: 'Total Sales',
      formatter: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    }
  ];

  const onTableStockInChange: TableChangeHandler<any> = (type, { page, sizePerPage }) => {
    setStockInQuery({
      page: page,
      per_page: sizePerPage
    });
  };

  const onTableStockOutChange: TableChangeHandler<any> = (type, { page, sizePerPage }) => {
    setStockOutQuery({
      page: page,
      per_page: sizePerPage
    });
  };

  return (
    <Modal show={open} onHide={onClose} backdrop={'static'} centered>
      <Modal.Header closeButton>
        <Modal.Title className='mb-unset'>Detail</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs id='controlled-tab-example' activeKey={currentTab} onSelect={(k: any) => setCurrentTab(k)} className='mb-3'>
          <Tab eventKey='StockIn' title='Stock In'>
            <VuiDataTable
              loading={stockInLoading}
              columns={stockInTableColumns}
              data={stockInData}
              page={stockInQuery.page}
              sizePerPage={stockInQuery.per_page}
              totalSize={totalStockInData}
              onTableChange={onTableStockInChange}
            />
          </Tab>
          <Tab eventKey='StockOut' title='Stock Out'>
            <VuiDataTable
              loading={stockOutLoading}
              columns={stockOutTableColumns}
              data={stockOutData}
              page={stockOutQuery.page}
              sizePerPage={stockOutQuery.per_page}
              totalSize={totalStockOutData}
              onTableChange={onTableStockOutChange}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailProductMutation;

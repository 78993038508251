// @ts-nocheck
import { useTranslation } from 'react-i18next';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { useNavigate, useParams } from 'react-router-dom';
import { none, useState } from '@hookstate/core/dist';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InformationInputs, informationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import clsx from 'clsx';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import SupplierRepository from '../../../../../repositories/SupplierRepository';
import GoodsReceiptRepository from '../../../../../repositories/GoodsReceiptRepository';
import SendPaymentRepository from '../../../../../repositories/SendPaymentRepository';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import { $clone } from '../../../../../vodea/utilities';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';

const PurchaseSendPaymentForm = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const isClosed = useState(false);

  const title = id ? t('Edit Send Payment') : t('Create Send Payment');

  const goodReceiptIds = useState<any[]>([]);

  const informationData = useState({
    number: ''
  });

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    SendPaymentRepository.show(id, {
      with: ['sendPaymentItems.goodsReceipt', 'supplier']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        isClosed.set(data.is_close && data.is_close == 1);

        const formattedData = formatSetValueForm(
          {
            supplier_id: null,
            date: null,
            send_payment_items: null
          },
          data
        );

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));
          goodReceiptIds.set(_.map(formattedData.send_payment_items, 'goods_receipt_id'));

          _.forEach(formattedData, (value: any, name: string) => {
            if (name === 'date') {
              informationSetValue(name, moment(value));
            } else {
              informationSetValue(name, value);
            }
          });
        }
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      supplier_id: null,
      date: moment()
    }
  });

  const watchSupplier = informationWatch('supplier_id');

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    Object.assign(formData, {
      send_payment_items: itemFields
    });

    (id ? SendPaymentRepository.update(id, formData) : SendPaymentRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/purchase/send-payment');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const { fields: itemFields, append: itemAppend, remove: itemRemove, insert: itemInsert } = useFieldArray({
    keyName: 'key',
    name: 'send_payment_items',
    control: informationControl
  });

  const itemColumns = [
    {
      dataField: 'goods_receipt.number',
      text: 'Receive Number'
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button
              type={'button'}
              className={'btn btn-danger btn-small'}
              onClick={() => {
                itemRemove(rowIndex);
                goodReceiptIds[rowIndex].set(none);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const addItem = (val: any) => {
    const tempItems = $clone(itemFields);

    if (_.filter(tempItems, o => o.goods_receipt_id * 1 === val.id).length === 0) {
      const tempItem = {
        goods_receipt_id: _.get(val, 'id'),
        goods_receipt: val,
        amount: _.get(val, 'amount')
      };

      itemAppend(tempItem);

      goodReceiptIds.merge([val.id]);
    }
  };

  const breadcrumbList = [
    {
      label: t('Purchase'),
      link: '/purchase'
    },
    {
      label: t('Send Payment'),
      link: '/purchase/send-payment'
    },
    {
      label: id ? id : t('Create'),
      link: '/purchase/send-payment/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div id={'purchase-section'}>
        <div className={'page-header-component'}>
          <h3 className={'title'}>{title}</h3>
        </div>
      </div>

      <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
        <div className='default-page-layout layout-reverse'>
          <div className={'information-section'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={informationData.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.supplier_id
                  })}
                >
                  <label className={'form-label'}>Supplier</label>
                  <Controller
                    name={'supplier_id'}
                    control={informationControl}
                    render={props => (
                      <VuiSelect
                        selectRepository={SupplierRepository}
                        defaultValue={props.value}
                        onChange={props.onChange}
                        isDisabled={id !== undefined}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.supplier_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.date
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <Controller
                    name={'date'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={true} />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='multiple-paper-section'>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Items')}</h6>
              </div>
              <div className={'card-content'}>
                {watchSupplier !== null ? (
                  <>
                    <div className='form-group'>
                      <VuiSelect
                        propKey={$clone(goodReceiptIds.get())}
                        selectParams={{
                          supplier: _.get(watchSupplier, 'id'),
                          'exclude-send-payment': id,
                          for: 'send-payment'
                        }}
                        selectRepository={GoodsReceiptRepository}
                        clearable={true}
                        defaultValue={null}
                        labelKey={'number'}
                        onChange={addItem}
                      />
                    </div>

                    <BootstrapTable keyField='key' data={$clone(itemFields)} columns={itemColumns} />
                  </>
                ) : (
                  <div className='card-empty-content'>please select supplier first</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {!isClosed.get() && <VuiActionForm loading={informationLoading.get()} cancelLink={'/purchase/send-payment'} />}
      </form>
    </>
  );
};

export default PurchaseSendPaymentForm;

import * as yup from 'yup';
import 'yup-phone';

export type GoodsReceiptItemInputs = {
  purchase_order_item_id: number;
  quantity: number;
}

export type GoodsReceiptDifferenceInputs = {
    id?: number,
    purchase_order_item_id: object | null;
    type_id: object | null;
    payment_treat_id: object | null,
    quantity: number | null
}

export const goodsReceiptDifferenceSchema = yup.object().shape({
    id: yup.mixed(),
    purchase_order_item_id: yup.mixed().required().label('product'),
    type_id: yup.mixed().required().label('type'),
    payment_treat_id: yup.mixed().required().label('payment treat'),
    quantity: yup.number().typeError("field must be a number.").required()
})

export const DifferenceBaseModel: GoodsReceiptDifferenceInputs = {
    id: 0,
    purchase_order_item_id: null,
    type_id: null,
    payment_treat_id: null,
    quantity: null
}

export type InformationInputs = {
  purchase_order_id: object | null;
  date: object;
  goods_receipt_items: GoodsReceiptItemInputs[];
  goods_receipt_differences: GoodsReceiptDifferenceInputs[]
};

export const informationSchema = yup.object().shape({
  purchase_order_id: yup.mixed().required(),
  date: yup.mixed().required(),
  goods_receipt_items: yup.array().of(
      yup.object().shape({
        purchase_order_item_id: yup.mixed(),
        quantity: yup.number().typeError("field must be a number.")
      })
  ),
    goods_receipt_differences: yup.array().of(
        yup.object().shape({
            purchase_order_item_id: yup.mixed(),
            type_id: yup.mixed(),
            payment_treat_id: yup.mixed(),
            quantity: yup.number().typeError("field must be a number.")
        })
    )
});

export const InformationBaseModel: InformationInputs = {
    purchase_order_id: {},
    date: {},
    goods_receipt_items: [],
    goods_receipt_differences: []
}

import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  product_id: object;
  unit_id: object;
  point: number;
  membership_ids: Array<Object>;
  is_visible: object;
};

export const informationSchema = yup.object().shape({
  product_id: yup.mixed().required().label('product'),
  unit_id: yup.mixed().required().label('product'),
  point: yup.number().typeError("field must be a number.").required(),
  membership_ids: yup.array(yup.mixed()).required().label('membership'),
  is_visible: yup.mixed().required().label('status')
});

export const InformationBaseModel: InformationInputs = {
  product_id: {},
  unit_id: {},
  point: 0,
  membership_ids: [],
  is_visible: {},
};

import { SystemState, SystemActionTypes, UPDATE_SYSTEM } from './types';

const initialState: SystemState = {
  loggedIn: false,
  user: null
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_SYSTEM: {
      return Object.assign({}, state, {
        loggedIn: action.payload.loggedIn,
        user: action.payload.user
      });
    }
    default:
      return state;
  }
}

import * as yup from 'yup';

export type ItemInputs = {
    id: number;
    goods_receipt_id: object | null;
    amount: number;
}

export const itemSchema = yup.object().shape({
    goods_receipt_id: yup.mixed().required().label('receive item'),
    amount: yup.number().typeError("field must be a number.").required(),
})

export type InformationInputs = {
    date: object;
    supplier_id: object | null;
    send_payment_items: ItemInputs[];
};

export const informationSchema = yup.object().shape({
    supplier_id: yup.mixed().required(),
    date: yup.mixed().required().label('Date'),
    send_payment_items: yup.array().of(
        yup.object().shape({
            goods_receipt_id: yup.mixed(),
            amount: yup.number().typeError("field must be a number."),
        })
    )
})

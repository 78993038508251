// @ts-nocheck
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import useIsMounted from '../../../utilities/hooks/useIsMounted';
import _ from 'lodash';

interface IVuiSearch {
  value: string;
  callback: (value: string) => void | null;
  placeholder?: string;
  delay?: number;
}

const VuiSearch: React.FC<IVuiSearch> = ({ value, callback = null, placeholder = 'Search...', delay = 300 }) => {
  const [inputValue, setInputValue] = useState(value);
  const isMounted = useIsMounted();

  const handleChange = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMounted.current) {
      setInputValue(event.target.value);
      if (callback) {
        callback(event.target.value);
      }
    }
  }, delay);

  return (
    <div className='input-group prefix-group'>
      <span className='input-group-text'>
        <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
      </span>
      <input defaultValue={inputValue} type='text' className='form-control' placeholder={placeholder} onChange={handleChange} />
    </div>
  );
};

export default VuiSearch;

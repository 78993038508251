/* eslint-disable-line react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { InformationInputs, informationSchema } from './validation';
import CashMonitoringRepository from '../../../../../repositories/CashMonitoringRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import UserRepository from '../../../../../repositories/UserRepository';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const CashMonitoringCreate: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const title = id ? t('Edit Cash Monitoring') : t('Add Cash Monitoring');

  const informationData = useState({
    number: ''
  });

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      date: moment()
    }
  });

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    (id ? CashMonitoringRepository.update(id, formData) : CashMonitoringRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        if (id) {
          informationLoading.set(false);
        } else {
          navigate('/finance/cash-monitoring');
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: 'Finance',
      link: '/finance'
    },
    {
      label: 'Cash Monitoring',
      link: '/finance/cash-monitoring'
    },
    {
      label: id ? id : 'Create',
      link: '/finance/cash-monitoring/create'
    }
  ];

  const getData = async () => {
    if (!id) {
      return;
    }
    await CashMonitoringRepository.show(id, {
      with: ['cashier']
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(
          {
            number: null,
            date: null,
            cashier_id: null,
            amount: null
          },
          data
        );

        _.forEach(formattedData, (value, name) => {
          if (name === 'number') {
            informationData.set({ number: value });
          }
          informationSetValue(name, value);
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Add Cash Monitoring</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'cash-monitoring-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true
                        })}
                      >
                        <label className={'form-label'}>Number</label>
                        <input type='text' name='number' className='form-control' disabled value={informationData.number.get()} />
                      </div>
                    </div>

                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.date
                        })}
                      >
                        <label className={'form-label'}>Date</label>
                        <Controller
                          name={'date'}
                          control={informationControl}
                          render={({ value, onChange }) => (
                            <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={false} />
                          )}
                        />
                        <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'request-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Request Detail</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.cashier_id
                        })}
                      >
                        <label className={'form-label'}>Cashier</label>
                        <div className={'form-select-wrapper'}>
                          <Controller
                            name={'cashier_id'}
                            control={informationControl}
                            render={({ value, onChange }) => (
                              <VuiSelect
                                selectParams={{
                                  only: ['employee']
                                }}
                                selectRepository={UserRepository}
                                defaultValue={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.amount
                        })}
                      >
                        <label className={'form-label'}>Request Amount</label>
                        {/*<input type='text' name='amount' className='form-control' ref={informationRegister} />*/}
                        <Controller
                          name={'amount'}
                          control={informationControl}
                          render={props => (
                            <NumberFormat
                              placeholder={'IDR 0'}
                              className='form-control'
                              value={props.value}
                              allowNegative={false}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                              onValueChange={({ value }) => props.onChange(value)}
                              prefix={'IDR '}
                            />
                          )}
                        />
                        <label className={'label-help'}>{informationErrors.amount?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/finance/cash-monitoring'} />
        </form>
      </div>
    </>
  );
};

export default CashMonitoringCreate;

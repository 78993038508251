import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../../vodea/utilities/helpers/global';
import { Modal } from 'react-bootstrap';
import VuiDataTable from '../../../../../vodea/@vodea-ui/components/VuiDataTable';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import ReportRepository from '../../../../../repositories/ReportRepository';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';

type DetailSalesRevenue = {
  number: string;
  date: Date | string;
  customer_name: string;
  total_cigarette_order: number;
  total_non_cigarette_order: number;
  grand_total: number;
};

type Props = {
  salesId?: number;
  params: any;
  open: boolean;
  onClose: () => void;
};

const ModalDetailSalesRevenue = ({ salesId, onClose, open, params }: Props) => {
  const [data, setData] = useState<DetailSalesRevenue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState({
    page: 1,
    per_page: 10
  });
  const [totalData, setTotalData] = useState<number>(0);

  useEffect(() => {
    loadData().then();
  }, [salesId, params, query]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = useCallback(async () => {
    if (!salesId) {
      return;
    }

    setLoading(true);

    await ReportRepository.showSalesRevenue(salesId, {
      ...query,
      ...params
    })
      .then((response: AxiosResponse) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [salesId, query, params]);

  const tableColumns = [
    {
      dataField: 'number',
      text: 'So Number'
    },
    {
      dataField: 'date',
      text: 'So Date'
    },
    {
      dataField: 'customer_name',
      text: 'Customer'
    },
    {
      dataField: 'total_cigarette_order',
      text: 'Total Rokok',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={Number(cell)} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'total_non_cigarette_order',
      text: 'Total Non Rokok',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={Number(cell)} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'grand_total',
      text: 'Grand Total',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={Number(cell)} prefix={'IDR '} />;
      }
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage }) => {
    setQuery({
      page: page,
      per_page: sizePerPage
    });
  };

  return (
    <Modal
      size='lg'
      show={open}
      onHide={() => {
        onClose();
        setData([]);
        setTotalData(0);
        setLoading(true);
      }}
      backdrop={'static'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='mb-unset'>Detail</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <VuiDataTable
          loading={loading}
          columns={tableColumns}
          data={data}
          page={query.page}
          sizePerPage={query.per_page}
          totalSize={totalData}
          onTableChange={onTableChange}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailSalesRevenue;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import {
  faBox,
  faBoxes,
  faBuilding,
  faIdBadge,
  faKey,
  faLock,
  faRuler,
  faTicketAlt,
  faUser,
  faUsers,
  faUserTag,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores';

interface SettingCardProps {
  name: string;
  link: string;
  icon: IconDefinition;
  permission: string;
}

const settingCardsList: Array<SettingCardProps> = [
  {
    name: 'Company',
    link: '/setting/company',
    icon: faBuilding,
    permission: 'setting-company.show'
  },
  {
    name: 'Storage',
    link: '/setting/storage',
    icon: faBox,
    permission: 'storage.index'
  },
  {
    name: 'Membership',
    link: '/setting/membership',
    icon: faIdBadge,
    permission: 'membership.index'
  },
  {
    name: 'Driver',
    link: '/setting/driver',
    icon: faUserTag,
    permission: 'driver.index'
  },
  {
    name: 'Sales',
    link: '/setting/sales',
    icon: faUserTie,
    permission: 'sales.index'
  },
  {
    name: 'Redeem',
    link: '/setting/redeem',
    icon: faTicketAlt,
    permission: 'redeem.index'
  },
  {
    name: 'User',
    link: '/setting/user',
    icon: faUser,
    permission: 'user.index'
  },
  {
    name: 'Unit',
    link: '/setting/unit',
    icon: faRuler,
    permission: 'unit.index'
  },
  {
    name: 'Category',
    link: '/setting/category',
    icon: faBoxes,
    permission: 'category.index'
  },
  {
    name: 'Role',
    link: '/setting/role',
    icon: faUsers,
    permission: 'role.index'
  },
  {
    name: 'Permission',
    link: '/setting/permission',
    icon: faKey,
    permission: 'any'
  },
  {
    name: 'Closing',
    link: '/setting/closing',
    icon: faLock,
    permission: 'any'
  }
];

const compareSettingCardWithPermission = (menu: any, permissions: any) => {
  const response: SettingCardProps[] = [];
  menu.forEach((item: any) => {
    if (item.permission === 'any') {
      response.push(item);
    } else if (permissions.includes(item.permission)) {
      response.push(item);
    }
  });
  return response;
};

const Setting: React.FC<any> = () => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const sections = compareSettingCardWithPermission(settingCardsList, permissions);

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Setting')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />
      <div id={'setting-section'}>
        <div className={'page-header-component'}>
          <h3 className={'title'}>{t('Setting')}</h3>
        </div>
        <div className={'card-setting-wrapper'}>
          {sections.map((item, key) => {
            return (
              <Link to={item.link} key={key}>
                <div className={'card-setting'}>
                  <div className={'inner-content'}>
                    <FontAwesomeIcon className={'icon icon-prefix type-setting'} icon={item.icon} />
                    <h3 className={'title'}>{item.name}</h3>
                  </div>
                </div>
              </Link>
            );
          })}

          {/*
                    <Link to={'/setting/category'}>
                        <div className={'card-paper'}>
                            <div className={'card-content'}>
                                <div className={'card-wrapper'}>
                                    <FontAwesomeIcon
                                        className={
                                            'icon icon-prefix type-setting'
                                        }
                                        icon={faBox}
                                    />
                                    <div className={'title'}>Category</div>
                                </div>
                            </div>
                        </div>
                    </Link>*/}
        </div>
      </div>
    </>
  );
};

export default Setting;

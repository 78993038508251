import * as yup from 'yup';
import 'yup-phone';

export type UserPayrollInputs = {
  monthly: number;
  daily: number;
}

export type InformationInputs = {
  name: string;
  email: string;
  mobile_phone: string;
  dob: object;
  role_id: object;
  photo_id: number | null;
  user_payroll: UserPayrollInputs
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_phone: yup.string().phone('ID', true).required().label('phone number'),
  dob: yup.mixed().required().label('date of birth'),
  role_id: yup.mixed().required().label('role'),
  photo_id: yup.mixed(),
  user_payroll: yup.object().shape({
    monthly: yup.number().typeError("field must be a number.").required().label('monthly payroll'),
    daily: yup.number().typeError("field must be a number.").required().label('daily payroll'),
  })
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  email: '',
  mobile_phone: '',
  dob: {},
  role_id: {},
  photo_id: null,
  user_payroll: {
    monthly: 0,
    daily: 0
  }
};



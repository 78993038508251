import { api } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  company: function () {
    return api.get(`${endPoint()}/api/company-setting`);
  },
  updateCompany: function (data: any) {
    return api.post(`${endPoint()}/api/company-setting`, {
      ...data
    });
  }
};

export default Repository;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import { useState } from '@hookstate/core/dist';
import VuiDataTable from '../../../../vodea/@vodea-ui/components/VuiDataTable';
import { Link } from 'react-router-dom';
import { $clone } from '../../../../vodea/utilities';

const products = [
  {
    id: 1,
    name: 'Profit and Loss',
    link: '/report/profit-and-loss'
  },
  {
    id: 2,
    name: 'Report Delivery Driver and PIC',
    link: '/report/delivery-per-driver'
  },
  {
    id: 3,
    name: 'Sales',
    link: '/report/sales'
  }
];

const ReportList = () => {
  const tableLoading = useState(false);
  const tablePage = useState(1);
  const tableSizePerPage = useState(10);
  const tableData = useState(products);

  const config = useState({
    search: '',
    total: 0,
    page: 1,
    per_page: 10,
    loading: true
  });

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any) => {
        return (
          <div className={'table-btn-wrapper'}>
            <Link type={'button'} className={'btn btn-primary btn-small'} to={row.link || '/'}>
              View
            </Link>
          </div>
        );
      }
    }
  ];

  const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
    config.page.set(page);
    config.per_page.set(sizePerPage);
  };

  const breadcrumbList = [
    {
      label: 'Report',
      link: '/'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Report</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>Report List</h3>
      </div>

      <div className={'card-paper'}>
        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input type='text' className='form-control' placeholder='Search' />
              </div>
            </div>
          </div>
          <VuiDataTable
            loading={tableLoading.get()}
            columns={tableColumns}
            data={$clone(tableData.get())}
            page={tablePage.get()}
            sizePerPage={tableSizePerPage.get()}
            totalSize={products.length}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default ReportList;

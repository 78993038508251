import { Helmet } from 'react-helmet-async';
import { HeaderAuth } from '../../../layouts/Auth';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { VuiButton } from '../../../vodea/@vodea-ui/components/VuiButton';
import { useState } from '@hookstate/core/dist';
import { useTranslation } from 'react-i18next';
import { Inputs, schema } from './validation';
import AuthenticationRepository from '../../../repositories/AuthenticationRepository';
import { AxiosResponse, AxiosError } from 'axios';
import useIsMounted from '../../../vodea/utilities/hooks/useIsMounted';
import { mapHookErrors, showToast } from '../../../vodea/utilities/helpers/global';

const url = () => window._env_.REACT_APP_URL;

const ForgotPassword: React.FC<any> = () => {
  const { t } = useTranslation();
  const loading = useState(false);
  const isMounted = useIsMounted();

  const { register, handleSubmit, errors, setError } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });
  const onSubmit = handleSubmit(data => {
    loading.set(true);

    AuthenticationRepository.forgotPassword({
      email: data.email,
      url: url() + '/auth/reset-password'
    })
      .then((response: AxiosResponse) => {
        showToast('Success, reset link has send to your email', 'success');

        loading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);

          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          showToast(e.response.data.message, 'error');
        }

        loading.set(false);
      });
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <HeaderAuth title={t('Forgot Password')} subtitle={t('Input your email address to get reset link')} />
      <form className={'form-wrapper'} onSubmit={onSubmit}>
        <div
          className={clsx({
            'form-group mb-4': true,
            error: errors.email
          })}
        >
          <label className={'form-label'}>Email</label>
          <input type='text' name={'email'} className='form-control' ref={register} />
          <label className={'label-help'}>{errors.email?.message}</label>
        </div>

        <VuiButton label={t('Send Reset Password Link')} loading={loading.get()} forSubmit={true} fullWidth={true} />

        <hr className={'separator'} />

        <p>
          {t('Remember Password')}? <Link to={'/auth/login'}>{t('Sign In')}</Link>
        </p>
      </form>
    </>
  );
};

export default ForgotPassword;

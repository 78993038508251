// @ts-nocheck
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useState } from '@hookstate/core/dist';

interface VuiHeaderSearchProps {}

const VuiHeaderSearch: React.FC<VuiHeaderSearchProps> = () => {
  const show = useState(false);

  const closeNotification = () => {
    show.set(false);
  };

  return (
    <>
      <FontAwesomeIcon
        className={'icon'}
        icon={faSearch}
        onClick={() => {
          show.set(!show.get());
        }}
      />

      <div
        className={clsx([
          'app-backdrop',
          {
            show: show.get()
          }
        ])}
        onClick={closeNotification}
      />

      <div
        className={clsx([
          'app-slide-popup',
          {
            show: show.get()
          }
        ])}
      >
        <div className={'app-slide-header'}>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon className={'icon'} icon={faSearch} />
            </span>
            <input type='text' className='form-control' placeholder='Search...' />
          </div>
          <FontAwesomeIcon className={'icon'} icon={faTimes} onClick={closeNotification} />
        </div>
        <div className={'app-slide-content'}>
          <div className={'slide-list'}>
            <div className={'slide-item type-search'}>
              <h4 className={'title'}>Dashboard</h4>
              <h6 className={'subtitle'}>Installation</h6>
              <p className={'description'}>
                There should be no extra setup needed to get the best build option. Load from CDN You can also There should be no extra
                setup needed to get the best build option. Load from CDN You can also
              </p>
            </div>

            <div className={'slide-item type-search'}>
              <h6 className={'subtitle'}>Installation</h6>
              <p className={'description'}>
                There should be no extra setup needed to get the best build option. Load from CDN You can also There should be no extra
                setup needed to get the best build option. Load from CDN You can also
              </p>
            </div>

            <div className={'slide-item type-search'}>
              <p className={'description'}>
                There should be no extra setup needed to get the best build option. Load from CDN You can also There should be no extra
                setup needed to get the best build option. Load from CDN You can also
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VuiHeaderSearch;

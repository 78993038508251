/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { InformationBaseModel, InformationInputs, informationSchema } from './validation';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import RoleRepository from '../../../../../repositories/RoleRepository';

const SettingRoleForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();

  const title = id ? t('Edit Role') : t('Create Role');

  const dataRole = useState<InformationInputs>(InformationBaseModel);

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    reset: informationReset,
    setValue: informationSetValue
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: InformationBaseModel
  });

  const getData = () => {
    if (!id) {
      return;
    }
    RoleRepository.show(id)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(InformationBaseModel, data);

        _.forEach(formattedData, (value, name) => {
          informationSetValue(name, value);
        });
        dataRole.set(data);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  useMemo(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!id) {
      dataRole.set({
        code: '',
        name: ''
      });
    }
  }, []);

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    dataRole.name.set(data.name);
    dataRole.code.set(data.code);

    const formData = formatFormData(dataRole.get());
    if (id === undefined) {
      RoleRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, response.data.success ? 'success' : 'error');
          navigate('/setting/role');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      RoleRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Role'),
      link: '/setting/role'
    },
    {
      label: id ? id : t('Create'),
      link: '/setting/role/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'product-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout layout-full'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className='row'>
                    <div className={'col-md-4'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.code
                        })}
                      >
                        <label className={'form-label'}>Code</label>
                        <input
                          type='text'
                          defaultValue={dataRole.code.get()}
                          name='code'
                          className='form-control'
                          ref={informationRegister}
                          placeholder={'Code'}
                        />
                        <label className={'label-help'}>{informationErrors.code?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-8'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input
                          type='text'
                          defaultValue={dataRole.name.get()}
                          name='name'
                          className='form-control'
                          ref={informationRegister}
                          placeholder={'Name'}
                        />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/setting/role'} />
        </form>
      </div>
    </>
  );
};

export default SettingRoleForm;

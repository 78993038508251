import { api, createCancelTokenHandler } from '../vodea/services';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/member-point`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/member-point/${id}`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

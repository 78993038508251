// @ts-nocheck
import { MediaProps } from '../../../../interfaces/media';
import React, { useLayoutEffect, useRef } from 'react';
import { none, useState } from '@hookstate/core/dist';
import clsx from 'clsx';
import _ from 'lodash';
import VuiMediaModal from '../../Modal/VuiMediaModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { $clone } from '../../../../utilities';
import { VuiButton } from '../../VuiButton';

interface VuiSelectMediaProps {
  max?: number;
  defaultValue: MediaProps[];
  onChange: (val: MediaProps[]) => void;
}

const VuiSelectMedia: React.FC<VuiSelectMediaProps> = ({ max = 1, defaultValue, onChange }) => {
  const { t } = useTranslation();

  const modalShow = useState(false);
  const mediaList = useState<MediaProps[]>(defaultValue);

  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(200);

  useLayoutEffect(() => {
    function updateSize() {
      cardHeight.set(_.get(ref, 'current.offsetWidth', 0));
    }
    window.addEventListener('resize', _.debounce(updateSize, 500));
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [mediaList.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectMedia = (media: MediaProps) => {
    mediaList.set(items => {
      return items.map(item => {
        if (item.id === media.id) {
          return {
            ...item,
            selected: !item.selected
          };
        }

        return {
          ...item
        };
      });
    });
  };

  const mediaCallback = (media: MediaProps[]) => {
    mediaList.set(media);

    if (onChange) {
      onChange(media);
    }
  };

  const removeSelectedItem = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    mediaList.set((items: Array<MediaProps>) => {
      return items.filter((item: MediaProps) => {
        return !item.selected;
      });
    });
  };

  const handleDelete = (e: React.MouseEvent<any>, key: number) => {
    e.stopPropagation();
    mediaList[key].set(none);
  };

  const cancelSelection = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    mediaList.set(items => {
      return items.map(item => {
        return {
          ...item,
          selected: false
        };
      });
    });
  };

  return (
    <>
      {_.filter(mediaList.get(), ['selected', true]).length ? (
        <div className='default-btn-action-wrapper'>
          <VuiButton customClass={'btn-small'} variant={'primary'} label={t('Cancel')} loading={false} onClick={cancelSelection} />
          <VuiButton
            customClass={'btn-small'}
            variant={'danger'}
            label={`Remove (${_.filter(mediaList.get(), ['selected', true]).length}) Media`}
            loading={false}
            onClick={removeSelectedItem}
          />
        </div>
      ) : (
        ''
      )}

      <div className='card-files-section select-media'>
        {mediaList.get().map((item, key) => {
          return (
            <div
              key={key}
              className={clsx([
                'card-file',
                {
                  checked: item.selected,
                  filled: true
                }
              ])}
              style={{
                height: cardHeight.get()
              }}
              onClick={() => handleSelectMedia(item)}
            >
              <div className='card-inner'>
                <div className='card-filled-wrapper'>
                  <img className='image' src={item.url} alt='media' />
                  <div className='action-wrapper'>
                    <input className='action-check form-check-input' type='checkbox' checked={item.selected} readOnly />
                    <FontAwesomeIcon className={'icon-delete-file'} icon={faTrash} onClick={e => handleDelete(e, key)} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div
          className={clsx([
            'card-file',
            {
              invisible: mediaList.get().length === max
            }
          ])}
          ref={ref}
          style={{
            height: cardHeight.get()
          }}
          onClick={() => modalShow.set(true)}
        >
          <div className='card-inner'>
            <p className='card-label'>{`Select media (${mediaList.get().length}/${max})`}</p>
            <FontAwesomeIcon className={'icon'} icon={faCloudUploadAlt} />
          </div>
        </div>
      </div>

      <VuiMediaModal
        multiple
        max={max}
        defaultValue={$clone(mediaList.value)}
        callback={mediaCallback}
        show={modalShow.get()}
        onHide={() => modalShow.set(false)}
      />
    </>
  );
};

export default VuiSelectMedia;

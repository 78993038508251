// @ts-nocheck
import React, { useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import { useState } from '@hookstate/core/dist';
import { useTranslation } from 'react-i18next';
import { faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { BaseModelMedia, MediaProps } from '../../../../interfaces/media';
import { fileTypes } from '../../../../../types';
import useMediaService from '../../../../utilities/hooks/useMediaService';
import useIsMounted from '../../../../utilities/hooks/useIsMounted';

interface VuiUploadImageProps {
  className?: string;
  defaultValue: MediaProps | null;
  accept?: fileTypes;
  onChange: (val: any) => void;
  onDelete?: () => void;
}

const VuiUploadImage: React.FC<VuiUploadImageProps> = ({
  className = '',
  defaultValue = BaseModelMedia,
  accept = 'image/*',
  onChange,
  onDelete
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(200);
  //const scopedDefaultValue = useState<any>(defaultValue);
  const { storeMedia } = useMediaService();
  const isMounted = useIsMounted();

  const handleOnDrop = (acceptedFiles: any) => {
    const formData = {
      files: acceptedFiles,
      path: 'file',
      disk: 'public'
    };

    storeMedia(formData).then((response: any) => {
      /*scopedDefaultValue.set({
        ...BaseModelMedia,
        ...response
      });*/

      onChange(response);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: handleOnDrop,
    onDropRejected: event => {},
    multiple: false
  });

  const handleDelete = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    onChange(null);
    //scopedDefaultValue.set(null);

    if (onDelete) {
      onDelete();
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (isMounted.current) {
        cardHeight.set(_.get(ref, 'current.offsetWidth', 0));
      }
    }
    window.addEventListener('resize', _.debounce(updateSize, 500));
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`card-files-section single-file ${className}`}>
      <div
        className={clsx(['card-file'], {
          filled: _.get(defaultValue, 'url', '')
        })}
        ref={ref}
        style={{
          height: cardHeight.get()
        }}
      >
        <div className='card-inner' {...getRootProps()}>
          <input {...getInputProps()} />
          {_.get(defaultValue, 'url', '') ? (
            <div className='card-filled-wrapper'>
              <img className='image' src={_.get(defaultValue, 'url')} alt='media' />
              <div className='action-wrapper'>
                <FontAwesomeIcon className={'icon-delete-file'} icon={faTrash} onClick={handleDelete} />
              </div>
            </div>
          ) : (
            <>
              <p className='card-label'>{t('Upload an Image')}</p>
              <FontAwesomeIcon className={'icon'} icon={faCloudUploadAlt} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VuiUploadImage;

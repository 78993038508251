import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faBoxes,
  faBox,
  faCashRegister,
  faTachometerAlt,
  faBook,
  faWallet,
  faFileInvoiceDollar,
  faFileExport,
  faCogs,
  faTruck,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';

export interface VuiSidebarProps {
  show: boolean;
  onClose: () => void;
}

export interface MenuChildrenProps {
  name: string;
  link: string;
  activeRoutes: Array<string>;
  badge?: number;
  permissions: Array<string> | string;
}

export interface MenuStructureProps {
  name: string;
  link: string;
  icon: IconDefinition;
  activeRoutes: Array<string>;
  children?: Array<MenuChildrenProps>;
  permissions: string[] | string;
}

const getBadgeMenu = () => {
  return 3;
};

export const MenuStructure: Array<MenuStructureProps> = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    icon: faTachometerAlt,
    activeRoutes: ['dashboard'],
    permissions: 'any'
  },
  {
    name: 'Sales',
    link: '/sales',
    icon: faCashRegister,
    activeRoutes: ['sales-order', 'return-item'],
    permissions: [],
    children: [
      {
        name: 'Sales Order',
        link: '/sales/sales-order',
        activeRoutes: ['sales-order'],
        permissions: 'sales-order.index'
      },
      {
        name: 'Return Item',
        link: '/sales/return-item',
        activeRoutes: ['return-item'],
        permissions: 'sales-return.index'
      }
    ]
  },
  {
    name: 'Delivery',
    link: '/delivery',
    icon: faTruck,
    activeRoutes: ['delivery'],
    permissions: 'delivery-order.index'
  },
  {
    name: 'Purchase',
    link: '/purchase',
    icon: faBoxes,
    activeRoutes: ['purchase-order', 'receive-item', 'send-payment'],
    permissions: [],
    children: [
      {
        name: 'Purchase Order',
        link: '/purchase/purchase-order',
        activeRoutes: ['purchase-order'],
        permissions: 'purchase-order.index'
      },
      {
        name: 'Receive Item',
        link: '/purchase/receive-item',
        activeRoutes: ['receive-item'],
        permissions: 'goods-receipt.index'
        //badge: getBadgeMenu()
      },
      {
        name: 'Send Payment',
        link: '/purchase/send-payment',
        activeRoutes: ['send-payment'],
        permissions: 'send-payment.index'
      }
    ]
  },
  {
    name: 'Contact',
    link: '/contact',
    icon: faBook,
    activeRoutes: ['customer', 'supplier'],
    permissions: [],
    children: [
      {
        name: 'Customer',
        link: '/contact/customer',
        activeRoutes: ['customer'],
        permissions: 'customer.index'
      },
      {
        name: 'Supplier',
        link: '/contact/supplier',
        activeRoutes: ['supplier'],
        permissions: 'supplier.index'
      }
    ]
  },
  {
    name: 'Inventory',
    link: '/inventory',
    icon: faBox,
    activeRoutes: ['product', 'mutation', 'stock-opname'],
    permissions: [],
    children: [
      {
        name: 'Product',
        link: '/inventory/product',
        activeRoutes: ['product'],
        permissions: 'product.index'
      },
      {
        name: 'Mutation',
        link: '/inventory/mutation',
        activeRoutes: ['mutation'],
        permissions: 'warehouse-stock-log.index'
      },
      {
        name: 'Stock Opname',
        link: '/inventory/stock-opname',
        activeRoutes: ['stock-opname'],
        permissions: 'stock-opname.index'
      }
    ]
  },
  {
    name: 'Finance',
    link: '/finance',
    icon: faFileInvoiceDollar,
    activeRoutes: ['payroll', 'expense', 'cash-monitoring'],
    permissions: [],
    children: [
      {
        name: 'Payroll',
        link: '/finance/payroll',
        activeRoutes: ['payroll'],
        permissions: 'cut-off.index'
      },
      {
        name: 'Expense',
        link: '/finance/expense',
        activeRoutes: ['expense'],
        permissions: 'expense.index'
      },
      {
        name: 'Cash Monitoring',
        link: '/finance/cash-monitoring',
        activeRoutes: ['cash-monitoring'],
        permissions: 'cash-request.index'
      }
    ]
  },
  {
    name: 'Report',
    link: '/report',
    icon: faFileExport,
    activeRoutes: ['report'],
    permissions: ['report.profit-and-loss', 'report.sales-revenue', 'report.delivery-per-driver']
  },
  {
    name: 'Attendance',
    link: '/attendance',
    icon: faCalendarPlus,
    activeRoutes: ['attendance'],
    permissions: 'attendance.index'
  },
  {
    name: 'Setting',
    link: '/setting',
    icon: faCogs,
    activeRoutes: ['setting'],
    permissions: 'any'
  }
];

import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  number: string;
  // request_by: object;
  date: object;
  cashier_id: object;
  amount: string;
};

export const informationSchema = yup.object().shape({
  number: yup.string(),
  // request_by: yup.mixed().required(),
  date: yup.mixed().required(),
  cashier_id: yup.mixed().required().label('cashier'),
  amount: yup.mixed().required()
});

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone } from '../../../../vodea/utilities';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import _ from 'lodash';
import moment from 'moment';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';
import { Button, Modal } from "react-bootstrap";
import ClosingRepository from "../../../../repositories/ClosingRepository";
import DayOffRepository from "../../../../repositories/DayOffRepository";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

const SettingClosing: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const showActionModal = useState(false);
  const [loadingActionModal, setLoadingActionModal] = React.useState<boolean>(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    setLoadingActionModal(true)
    ClosingRepository.create()
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showActionModal.set(false);
        setLoadingActionModal(false)
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        setLoadingActionModal(false)
        showToast(e.message, 'error');
      });
  };

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'date',
    with: 'user',
    total: 0,
    loading: false
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'date',
      text: 'Date',
      render: (cell: any) => {
        return moment(cell).format('D MMMM YYYY h:m A');
      }
    },
    {
      dataField: 'user',
      text: 'Close By',
      render: cell => cell.name
    },
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await ClosingRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) setTotalData(response.data.total_data);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  useMemo(() => {
    getTableData(true);

  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Closing'),
      link: '/setting/closing'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Closing')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Closing')}</h3>
        {permissions.includes('close-transaction.store') || true ? (
          <div className={'btn-wrapper'}>
            <button onClick={() => showActionModal.set(true)} type={'button'} className={'btn btn-primary'}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faLock} />
              {t('Close Now')}
            </button>
          </div>
        ) : null}
      </div>

      {permissions.includes('close-transaction.show') || true ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      <Modal show={showActionModal.get()} onHide={() => showActionModal.set(false)} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-aciton-wrapper'>
            <p className='title'>Are you sure want to continue this action?</p>
            <p className='description'>Once closed, the transaction cannot be edited</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'light'} onClick={() => showActionModal.set(false)}>
            {t('Cancel')}
          </Button>
          <VuiButton label={t('Continue')} loading={loadingActionModal} variant="danger" onClick={tableHandleOnContinue}></VuiButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingClosing;

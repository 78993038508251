import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import Select from 'react-select';
import _ from 'lodash';
import VuiUploadImage from '../../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage';
import { Link } from 'react-router-dom';
import { InformationInputs, informationSchema } from './validation';

const roleOptions = [
  { value: '1', label: 'Admin' },
  { value: '2', label: 'Cashier' },
  { value: '3', label: 'Employee' }
];

const EmployeeCreate: React.FC<any> = () => {
  const { t } = useTranslation();
  const title = `Add Employee`;

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema)
  });
  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    setTimeout(() => {
      informationLoading.set(false);
    }, 1000);
  });

  const breadcrumbList = [
    {
      label: title,
      link: '/example/1'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Add Employee</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>
      <div id={'contact-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout type-profile'}>
            <div className={'information-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.customer_id
                        })}
                      >
                        <label className={'form-label'}>Customer ID</label>
                        <input type='text' name='customer_id' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.customer_id?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input type='text' name='name' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true
                        })}
                      >
                        <label className={'form-label'}>Email</label>
                        <input type='text' name='email' className='form-control' ref={informationRegister} />
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.phone_number
                        })}
                      >
                        <label className={'form-label'}>Phone Number</label>
                        <input type='text' name='phone_number' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.phone_number?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.dob
                        })}
                      >
                        <label className={'form-label'}>Date of Birth</label>
                        <Controller
                          name={'dob'}
                          control={informationControl}
                          defaultValue={new Date()}
                          render={({ value, onChange }) => <VuiDateRangePicker onChange={onChange} single={true} timePicker={false} />}
                        />
                        <label className={'label-help'}>{_.get(informationErrors.dob, 'message')}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.role
                        })}
                      >
                        <label className={'form-label'}>Role</label>
                        <div className={'form-select-wrapper'}>
                          <Controller
                            name={'role'}
                            defaultValue={'1'}
                            control={informationControl}
                            render={props => (
                              <Select
                                classNamePrefix='select'
                                isClearable={true}
                                defaultValue={roleOptions.find(item => item.value === props.value)}
                                options={roleOptions}
                                onChange={e => props.onChange(e?.value)}
                              />
                            )}
                          />
                        </div>
                        <label className={'label-help'}>{_.get(informationErrors.role, 'message')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper mt-3'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Payroll</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.monthly_payroll
                        })}
                      >
                        <label className={'form-label'}>Monthly Payroll</label>
                        <input type='text' name='monthly_payroll' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.monthly_payroll?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.daily_payroll
                        })}
                      >
                        <label className={'form-label'}>Daily Payroll</label>
                        <input type='text' name='daily_payroll' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.daily_payroll?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'profile-picture-section'}>
              <div className={'card-paper floating-content'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Profile Picture')}</h6>
                </div>
                <div className={'card-content'}>
                  <div
                    className={clsx({
                      'form-group': true,
                      error: informationErrors.photo
                    })}
                  >
                    <Controller
                      name='photo'
                      control={informationControl}
                      defaultValue={null}
                      render={({ value, onChange }) => <VuiUploadImage defaultValue={value} onChange={onChange} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'card-paper mt-3'}>
            <div className={'card-content type-button'}>
              <div className={'button-submit-wrapper'}>
                <div className={'label-wrapper'}>
                  <div className={'title'}>Save Change ?</div>
                </div>
                <div className={'button-group-wrapper'}>
                  <div className={'button-group'}>
                    <Link to={'/employee'}>
                      <div className={'btn btn-sm btn-secondary'}>Cancel</div>
                    </Link>
                  </div>
                  <div className={'button-group'}>
                    <VuiButton forSubmit={true} label={'Save'} loading={informationLoading.get()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmployeeCreate;

// @ts-nocheck
import { Button, Modal, ModalProps, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { none, useState } from '@hookstate/core/dist';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import { ExampleListMedia, MediaProps } from '../../../../interfaces/media';
import clsx from 'clsx';
import { $clone } from '../../../../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import VuiSelect from '../../Forms/VuiSelect';
import VuiUploadFiles from '../../Forms/VuiUploadFiles';

interface VuiMediaModalProps extends ModalProps {
  multiple?: boolean;
  defaultValue: any[];
  callback: (media: MediaProps[]) => void;
  max?: number;
}

const VuiMediaModal: React.FC<VuiMediaModalProps> = ({ multiple = false, defaultValue, callback, max = 0, ...props }) => {
  const { t } = useTranslation();

  const tab = useState<string | null>('media');

  const filterByOptions = [
    {
      key: 'all',
      label: 'All Media'
    },
    {
      key: 'image',
      label: 'Images'
    },
    {
      key: 'audio',
      label: 'Audio'
    },
    {
      key: 'video',
      label: 'Video'
    },
    {
      key: 'document',
      label: 'Document'
    }
  ];

  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(200);

  const useTab = tab.get();

  useLayoutEffect(() => {
    function updateSize() {
      cardHeight.set(_.get(ref, 'current.offsetWidth', 0));
    }
    window.addEventListener('resize', _.debounce(updateSize, 500));
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [props.show, useTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const mediaList = useState(ExampleListMedia);
  const selectedMedia = useState<MediaProps[]>([]);

  useEffect(() => {
    if (defaultValue.length) {
      selectedMedia.set(defaultValue);
    } else {
      selectedMedia.set([]);
    }
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectMedia = (media: MediaProps) => {
    const mediaSelectedIndex = $clone(selectedMedia.value).findIndex((item: MediaProps) => item.id === media.id);

    if (mediaSelectedIndex !== -1) {
      selectedMedia[mediaSelectedIndex].set(none);
    } else {
      let selected = null;

      if (max === 0 || max > $clone(selectedMedia.value).length) {
        selected = $clone(mediaList.value).find((item: MediaProps) => {
          return item.id === media.id;
        });
      }

      if (selected) {
        if (multiple) {
          selectedMedia.merge([selected]);
        } else {
          selectedMedia.set([selected]);
        }
      }
    }
  };

  const handleChoose = () => {
    callback($clone(selectedMedia.value));
    selectedMedia.set([]);
    props.onHide();
  };

  return (
    <>
      <Modal {...props} size='lg' centered backdrop={'static'}>
        <Tabs activeKey={tab.get()} onSelect={k => tab.set(k)}>
          <Tab eventKey='media' title={t('Media Library')}>
            <Modal.Body>
              <div className={'default-filter-section'}>
                <div className={'filter-item filter-special'}>
                  <div className='input-group prefix-group'>
                    <span className='input-group-text'>
                      <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                    </span>
                    <input type='text' className='form-control' placeholder='Search' />
                  </div>
                </div>

                <div className={'filter-item'}>
                  <div className={'form-select-wrapper'}>
                    <VuiSelect defaultValue={filterByOptions[0]} options={filterByOptions} isMulti={false} />
                  </div>
                </div>
              </div>
              <div className='modal-image-wrapper'>
                <div className='card-files-section'>
                  {mediaList.get().map((item, key) => {
                    return (
                      <div
                        key={key}
                        className={clsx([
                          'card-file',
                          {
                            checked: _.indexOf(_.map(selectedMedia.get(), 'id'), item.id) !== -1,
                            filled: true
                          }
                        ])}
                        ref={ref}
                        style={{
                          height: cardHeight.get()
                        }}
                        onClick={() => handleSelectMedia(item)}
                      >
                        <div className='card-inner'>
                          <div className='card-filled-wrapper'>
                            <img className='image' src={item.url} alt='media' />
                            <div className='action-wrapper'>
                              <input
                                className='action-check form-check-input'
                                type='checkbox'
                                checked={_.indexOf(_.map(selectedMedia.get(), 'id'), item.id) !== -1}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal.Body>
          </Tab>
          <Tab eventKey='upload' title={t('Upload')}>
            <Modal.Body>
              <div className='modal-upload-wrapper'>
                <VuiUploadFiles parentRef={tab.get()} />
              </div>
            </Modal.Body>
          </Tab>
        </Tabs>

        <Modal.Footer>
          <Button variant={'light'} onClick={props.onHide}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleChoose}>{t('Choose')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VuiMediaModal;

import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  role: object;
  email: string;
  phone_number: string;
  date_single: object;
  date_range: object;
  tags: object;
  description: string;
  image_single: any;
  select_media: any;
};

export type PasswordInputs = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  role: yup.mixed().required(),
  email: yup.string().required().email(),
  phone_number: yup.string().phone('ID', true).required().label('phone number'),
  date_single: yup.mixed().required().label('date single'),
  date_range: yup.mixed().required().label('date range'),
  tags: yup.mixed(),
  description: yup.string().required(),
  image_single: yup.mixed().label('image single'),
  select_media: yup.mixed().label('select media')
});

export const passwordSchema = yup.object().shape({
  current_password: yup.string().min(8).required().label('current password'),
  new_password: yup.string().min(8).required().label('new password'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null])
    .required()
    .label('confirm new password')
});

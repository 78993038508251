import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  section: string;
  rack: string;
  palette: string;
  product_id: object;
  capacity: number;
  is_visible: object;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  section: yup.string(),
  rack: yup.string(),
  palette: yup.string(),
  product_id: yup.mixed().required().label('product'),
  capacity: yup.number().typeError("field must be a number.").required(),
  is_visible: yup.mixed().required().label('status')
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  section: '',
  rack: '',
  palette: '',
  product_id: {},
  capacity: 0,
  is_visible: {}
};

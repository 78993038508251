import { Button, Modal, ModalProps } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface VuiActionModalProps extends ModalProps {
  header?: string;
  title?: string;
  description?: string;
  btnVariant?: string;
  onContinue: (e: React.MouseEvent) => void;
}

const VuiActionModal: React.FC<VuiActionModalProps> = ({ header = null, title = null, description = null, btnVariant = 'danger', onContinue, ...props }) => {
  const { t } = useTranslation();

  if (header === null) header = t('Please Confirm');
  if (title === null) title = t('Are you sure want to continue this action?');
  if (description === null) description = t("You can't view this item if you continue");

  return (
    <Modal {...props} centered backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-aciton-wrapper'>
          <p className='title'>{title}</p>
          <p className='description'>{description}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'light'} onClick={props.onHide}>
          {t('Cancel')}
        </Button>
        <Button variant={btnVariant} onClick={onContinue}>{t('Continue')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VuiActionModal;

import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

export interface OAuth2TokenContract {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

const KEY_ACCESS_TOKEN = 'auth.access_token';
const KEY_REFRESH_TOKEN = 'auth.refresh_token';
const KEY_EXPIRES_IN = 'auth.expires_in';
const KEY_EXPIRES_AT = 'auth.expires_at';

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;
const clientId = () => window._env_.REACT_APP_PASSPORT_CLIENT_ID;
const clientSecret = () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET;

class PassportService {
  constructor() {
    const accessToken = this.getAccessToken();

    /*const accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTk2YzJhNmZmOTJkYTg4YWFhNjA4OWZlNmVhNWI2Yzg3NDliYmE2NzMxNDA1YzYwZGM4ZTY0ZGM2OGQwOTE5ZDM4ZjgwM2Q5MmViYjZmMTAiLCJpYXQiOjE2MTQyNDMwMjMsIm5iZiI6MTYxNDI0MzAyMywiZXhwIjoxNjQ1Nzc5MDIzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.WmSGjKvQR-c9Jn5KGv-W8Nj_vo97rfrS5reOLuFq9j4OAlpCsfGnkDdo9Lnnc9JnT8oZ4hMjBkLDm8Yx46pASMWotoSi7REgnJuRCA-yDaYFmYqGU0lxeOCEUguhA_9mZhorpPB0Bu8el4oMmf0MOKvWb67H0owwvAlwLxf3Mr3Y5StplFblBUP3inCF8e46r_M9J_lOoZZgLZpKI2SrwUyj22DmzaSjfOzUHDDzF_WXz263K03XnjkK6vXXIME7VTFJ71JAmrEthvDF8JNy2pwZQ2G0Ce8wTMWQdEUDUBuavncvDEYdg0XOV75obHhdcGijz2Q1QMBecmEIKjfLUv9610szBFRe24ig9vGEi63Odei2gSYn_p1huNCKw6lG2AKVkz3fdG6VgxkJ7fqU1D4rc0m73S3bK_VzwRbg452ZT6o2AsFLmv6P4iF2r92ghvB18CsCtLNcEq1QSTFjkpScf868NYD6WUldGee8tg5NCHJGJeSUByv_1a7GJ7oxUKBz3uRV6u6Bho4uwYLCmAwgRQzDt_EXFkyXYVXerhMyiecGVIyWww3mDlUf-Tz2bSPXq2U9mQCc1zMoSyS2gAX6TmjmPpHmSagelAbLRF8KnveaVy6fhfMfj3J2P1CvbozR2pmn94vbJ0q7uQibEyamG4Izd8fJV0ox5LZWQO0'*/

    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  getAccessToken = () => {
    const accessToken = window.localStorage.getItem(KEY_ACCESS_TOKEN);
    if (!accessToken) {
      return false;
    }

    const expiresAt = window.localStorage.getItem(KEY_EXPIRES_AT);
    if (expiresAt && expiresAt <= moment().format('YYYY-MM-DD H:m:s')) {
      return false;
    }

    return accessToken;
  };

  setToken = (oauthToken: OAuth2TokenContract | null) => {
    if (oauthToken) {
      const expiredAt = moment().add(oauthToken.expires_in, 'seconds').format('YYYY-MM-DD H:m:s');

      localStorage.setItem(KEY_ACCESS_TOKEN, oauthToken.access_token);
      localStorage.setItem(KEY_REFRESH_TOKEN, oauthToken.refresh_token);
      localStorage.setItem(KEY_EXPIRES_IN, String(oauthToken.expires_in));
      localStorage.setItem(KEY_EXPIRES_AT, String(expiredAt));

      axios.defaults.headers.common['Authorization'] = `Bearer ${oauthToken.access_token}`;
    } else {
      localStorage.removeItem(KEY_ACCESS_TOKEN);
      localStorage.removeItem(KEY_REFRESH_TOKEN);
      localStorage.removeItem(KEY_EXPIRES_IN);
      localStorage.removeItem(KEY_EXPIRES_AT);

      delete axios.defaults.headers.common['Authorization'];
    }
  };

  login = (username: string, password: string, scope: string = '*') => {
    const credentials = {
      username,
      password,
      scope,
      grant_type: 'password',
      client_id: clientId(),
      client_secret: clientSecret()
    };

    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${endPoint()}/oauth/token`, credentials)
        .then(response => {
          this.setToken(response.data);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          this.setToken(null);
          reject(error);
        });
    });
  };

  logout = async () => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${endPoint()}/api/account/logout`)
        .then((response: AxiosResponse) => resolve(response))
        .catch((error: AxiosError) => reject(error));

      this.setToken(null);
    });
  };

  fetchUser = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${endPoint()}/api/account`)
        .then((response: AxiosResponse) => resolve(response))
        .catch((error: AxiosError) => {
          this.setToken(null);
          reject(error);
        });
    });
  };
}

const instance = new PassportService();

export default instance;

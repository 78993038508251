import { systemReducer } from './system/reducers';
import { combineReducers } from 'redux';
import { permissionReducer } from './permission/reducers';

export const rootReducer = combineReducers({
  system: systemReducer,
  permission: permissionReducer
});

export type RootState = ReturnType<typeof rootReducer>;

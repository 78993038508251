import * as yup from 'yup';

export type InformationInputs = {
    user_id: object | null;
    date: object;
    time_in: string;
    time_out: string;
};

export const informationSchema = yup.object().shape({
    user_id: yup.mixed().required().label('customer'),
    date: yup.mixed().required().label('Date'),
    time_in: yup.mixed().required().label('Date'),
});

// @ts-nocheck
import React from 'react';

import './styles.scss';
import _ from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';

type State = {
  data: object;
  componentRef: React.Ref<any>;
};

const PrintDeliveryOrder: React.FC<State> = ({ data, componentRef }) => {
  console.log(data);
  const total = _.sumBy(_.get(data, 'salesOrders'), function (item) {
    return _.get(item, 'subtotal') * 1;
  });

  const sisabon = _.sumBy(_.get(data, 'salesOrders'), function (item) {
    return _.get(item, 'outstanding') * 1;
  });

  const grandTotal = _.sumBy(_.get(data, 'salesOrders'), function (item) {
    return _.get(item, 'grand_total') * 1;
  });

  return (
    <div ref={componentRef}>
      <div className='print-component'>
        <table className='table-header'>
          <tbody>
            <tr>
              <td>Daftar Faktur Penjualan</td>
            </tr>
            <tr>
              <td>087888221655 / 0215980204</td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>NO. FAKTUR</td>
              <td>:</td>
              <td>{_.get(data, 'number')}</td>
            </tr>
            <tr>
              <td>TANGGAL</td>
              <td>:</td>
              <td>{_.get(data, 'date').format('D MMMM YYYY HH:mm A')}</td>
            </tr>
            <tr>
              <td>SUPIR</td>
              <td>:</td>
              <td>{_.get(data, 'driverName')}</td>
            </tr>
            <tr>
              <td>KASIR</td>
              <td>:</td>
              <td>{_.get(data, 'creatorName')}</td>
            </tr>
          </tbody>
        </table>

        <hr />

        <table className='table-bordered table-list'>
          <thead>
            <tr>
              <th className='no-wrap'></th>
              <th className='text-start'>Tanggal</th>
              <th>Nomor SO</th>
              <th>Customer</th>
              <th className='text-end'>Total</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, 'salesOrders').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  <tr>
                    <td className='no-wrap'>
                      <NumberFormat
                        defaultValue={key + 1}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        suffix={'.'}
                      />
                    </td>
                    <td className='text-start'>{moment(_.get(item, 'date')).format('D MMMM YYYY HH:mm A')}</td>
                    <td>{_.get(item, 'number')}</td>
                    <td>{_.get(item, 'customer.name')}</td>
                    <td className='text-end no-wrap'>
                      <NumberFormat
                        defaultValue={_.get(item, 'subtotal')}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='text-end'>
                      SISABON
                    </td>
                    <td className='text-end no-wrap'>
                      <NumberFormat
                        defaultValue={_.get(item, 'outstanding')}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='text-end'>
                      <strong>TOTAL</strong>
                    </td>
                    <td className='text-end no-wrap'>
                      <strong>
                        <NumberFormat
                          defaultValue={_.get(item, 'grand_total')}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          decimalScale={0}
                        />
                      </strong>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <hr />

        <div className='row'>
          <div className='col-4'></div>
          <div className='col-8'>
            <table className='table-summary'>
              <tbody>
                <tr>
                  <td className='text-end'>JUMLAH SO</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'salesOrders').length}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>TOTAL</td>
                  <td className='text-end'>
                    <NumberFormat value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>SISABON</td>
                  <td className='text-end'>
                    <NumberFormat value={sisabon} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>GRAND TOTAL</td>
                  <td className='text-end'>
                    <NumberFormat value={grandTotal} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PrintDeliveryOrder);

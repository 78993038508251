import { toast, ToastPosition, TypeOptions } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

export const randomString = (length: number = 32, chars: string = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const optionSelected = (option: any, value: any, key: string = 'id') =>
  /*eslint-disable eqeqeq*/
  _.get(option, key) == _.get(value, key);

export const optionLabel = (option: any, key: string = 'name') => _.get(option, key);

export const showToast = (
  message: string,
  type: TypeOptions = toast.TYPE.DEFAULT,
  position: ToastPosition = toast.POSITION.BOTTOM_LEFT
) => {
  toast(message, {
    position: position,
    type: type
  });
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const stringTransform = {
  removeDashUnderScore: (str = '') => {
    return str.replace(/[-_]/g, ' ').replace(/\w\S*/g, txt => txt);
  },
  formatDate: (val = '', format = 'DD MMMM YYYY') => {
    return moment(val).format(format);
  },
  formatTime: (val = '', format = 'HH:mm') => {
    const fullDate = '1997-10-11 ' + val;

    return moment(fullDate).format(format);
  },
  sumStringNumber: (value: any[]) => {
    return value.reduce((a, b) => parseInt(a) + parseInt(b), 0);
  }
};

export const mapHookErrors = (errors: any) => {
  let newErrors: any = {};

  Object.keys(errors).forEach(key => {
    Object.assign(newErrors, {
      [key]: {
        message: errors[key][0]
          .replace(/_id/g, '')
          .replace(/ id/g, '')
          .replace(/\.\d{1,3}\./g, (match: string) => {
            return ` ${match.replace(/\./g, '').replace(/\d{1,3}/g, a => ordinalSuffixOf(parseFloat(a) + 1))} `;
          })
          .replace(/\w\.\w/g, (match: string) => match.replace(/\./g, ' '))
          .replace(/_/g, ' '),
        type: 'manual'
      }
    });
  });

  return newErrors;
};

export const ordinalSuffixOf = (value: any) => {
  if (!Number(value)) {
    return '';
  }

  const j = parseFloat(value) % 10;
  const k = parseFloat(value) % 100;

  if (j === 1 && k !== 11) {
    return value + 'st';
  }

  if (j === 2 && k !== 12) {
    return value + 'nd';
  }

  if (j === 3 && k !== 13) {
    return value + 'rd';
  }

  return value + 'th';
};

export const getAliasesName = (value: string) => {
  const arrayName = value.split(' ');
  let aliasesName = value.charAt(0) + value.charAt(1);

  if (arrayName.length >= 2) {
    aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
  }

  return aliasesName.toUpperCase();
};

export const padLeadingZeros = (num: number, size: number) => {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
};

export const mapParams = (parameters: any) => {
  let params: any = {};

  Object.keys(parameters).forEach((key: string) => {
    const parameter = parameters[key];
    if (parameter !== null) {
      if (Array.isArray(parameter)) {
        params[key] = parameter.map(a => _.get(a, 'id', a));
      } else if (_.has(parameter, 'id')) {
        params[key] = parameter.id;
      } else {
        params[key] = parameter;
      }
    }
  });

  return params;
};

import * as yup from 'yup';
import 'yup-phone';
import moment from 'moment';

export type ProductInputs = {
  id: number | null;
  product_id: object | null;
  unit_id: object | null;
  storage_id: object | null;
  quantity?: number;
  price?: number;
  discount?: number;
};

export const ProductBaseModel: ProductInputs = {
  id: 0,
  product_id: null,
  unit_id: null,
  storage_id: null,
}

export const productSchema = yup.object().shape({
  id: yup.mixed(),
  product_id: yup.mixed().required().label('product'),
  unit_id: yup.mixed().required().label('unit'),
  storage_id: yup.mixed().required().label('storage'),
  quantity: yup.number().typeError("field must be a number.").required(),
  price: yup.number().typeError("field must be a number.").required(),
  discount: yup.number().typeError("field must be a number.").required(),
});

export type InformationInputs = {
  supplier_id: object | null;
  date: object;
  term_of_payment: number;
  purchase_order_items: ProductInputs[];
  is_ppn: string;
};

export const informationSchema = yup.object().shape({
  supplier_id: yup.mixed().required(),
  date: yup.mixed().required().label('Date'),
  term_of_payment: yup.number().typeError("field must be a number.").required().label('Term of Payment'),
  purchase_order_items: yup.array().of(
    yup.object().shape({
      product_id: yup.mixed(),
      unit_id: yup.mixed(),
      storage_id: yup.mixed(),
      quantity: yup.number().typeError("field must be a number."),
      price: yup.number().typeError("field must be a number."),
      discount: yup.number().typeError("field must be a number.")
    })
  )
});

export const InformationBaseModel: InformationInputs = {
  supplier_id: null,
  date: moment(),
  term_of_payment: 15,
  purchase_order_items: [],
  is_ppn: '0',
};

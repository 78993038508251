import React, { useCallback, useMemo } from 'react';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useState } from '@hookstate/core/dist';
import { AxiosError, AxiosResponse } from 'axios';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import * as _ from 'lodash';
import moment from 'moment';
import { $clone } from '../../../../vodea/utilities';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import WarehouseStockProductRepository from '../../../../repositories/WarehouseStockProductRepository';
import VuiNumberFormat from '../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import ReportRepository from '../../../../repositories/ReportRepository';
import fileDownload from 'js-file-download';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';

type Props = {
  defaultParams: any;
  onViewProduct: (id: number) => void;
};

const TableProductMutation = ({ defaultParams, onViewProduct }: Props) => {
  const isMounted = useIsMounted();
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);
  const [exportProductDetailLoading, setExportProductDetailLoading] = React.useState(false);

  const tableConfig = useState({
    page: 1,
    per_page: 10,
    sorted_by: 'asc',
    order_by: 'name',
    total: 0,
    loading: false
  });

  const tableData = useState([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'name',
      text: 'Customer Name'
    },
    {
      dataField: 'opening_stock',
      text: 'Opening Stock',
      render: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    },
    {
      dataField: 'stock_in',
      text: 'Stock In',
      render: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    },
    {
      dataField: 'stock_out',
      text: 'Stock Out',
      render: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    },
    {
      dataField: 'closing_stock',
      text: 'Closing Stock',
      render: (cell: any, row: any) => {
        return cell != 0 ? <VuiNumberFormat data={cell} suffix={` ${row.unit_name}`} /> : '';
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      render: (cell: any, row: any) => {
        return (
          <div className={'table-btn-wrapper'}>
            <div className={'btn btn-primary btn-small'} onClick={() => onViewProduct(row.id)}>
              View
            </div>
            <VuiButton
              variant='secondary'
              customClass='btn-small'
              label='Export'
              loading={exportProductDetailLoading}
              onClick={() => handleExportProductDetail(row.id)}
            />
          </div>
        );
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    const { date_from: dateFrom, date_to: dateTo, ...baseParams } = defaultParams;
    let params: any = {
      ...baseParams,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    };

    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await WarehouseStockProductRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) {
            setTotalData(response.data.total_data);
          }
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  const handleExportProductDetail = useCallback(
    async (id: number) => {
      setExportProductDetailLoading(true);

      const { date_from, date_to } = defaultParams;

      const formatParams = {
        date_from: moment(date_from).format('YYYY-MM-DD'),
        date_to: moment(date_to).format('YYYY-MM-DD')
      };

      await ReportRepository.exportProductMutationDetail(id, formatParams)
        .then((response: AxiosResponse) => {
          fileDownload(response.data, 'product-mutation-detail.xls');
        })
        .catch((err: AxiosError) => {})
        .finally(() => {
          setExportProductDetailLoading(false);
        });
    },
    [defaultParams]
  );

  useMemo(() => {
    getTableData(true);
  }, [defaultParams]);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  return (
    <DataTableInfiniteScroll
      columns={tableColumns}
      totalDataTitle={'Total Product'}
      data={tableData.get()}
      propKey={'id'}
      isLoading={tableConfig.loading.get()}
      isFetchingMoreData={isFetchingMoreData}
      totalData={totalData}
    />
  );
};

export default TableProductMutation;

// @ts-nocheck
import { faExclamationCircle, faExternalLinkAlt, faPencilAlt, faPlus, faReceipt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { none, useState } from '@hookstate/core/dist';
import { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Helmet } from 'react-helmet-async';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { salesOrderChannelOptions, salesOrderItemTypeOptions } from '../../../../../constants';
import PrintSalesOrderReceipts from '../../../../../prints/PrintSalesOrderReceipts';
import CustomerRepository from '../../../../../repositories/CustomerRepository';
import MemberPointRepository from '../../../../../repositories/MemberPointRepository';
import PaymentMethodRepository from '../../../../../repositories/PaymentMethodRepository';
import ProductRepository from '../../../../../repositories/ProductRepository';
import ReceivePaymentRepository from '../../../../../repositories/ReceivePaymentRepository';
import SalesOrderRepository from '../../../../../repositories/SalesOrderRepository';
import SalesRepository from '../../../../../repositories/SalesRepository';
import StorageRepository from '../../../../../repositories/StorageRepository';
import UnitRepository from '../../../../../repositories/UnitRepository';
import VuiDateRangePicker from '../../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import VuiActionModal from '../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { VuiButton } from '../../../../../vodea/@vodea-ui/components/VuiButton';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';
import { $clone } from '../../../../../vodea/utilities';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import {
  InformationInputs,
  informationSchema,
  ProductInputs,
  productSchema,
  ReceivePaymentInputs,
  receivePaymentSchema
} from './validation';

const SalesOrderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const showWarningPrice = useState(false);
  const isClosed = useState(false);

  const title = id ? t('Edit Sales Order') : t('Create Sales Order');

  const membershipData = useState({
    divide: 0,
    available: 0,
    receive: 0,
    usage: 0,
    endingBalance: 0
  });

  const informationData = useState({
    number: '',
    dueDate: '',
    creatorName: ''
  });

  const paymentData = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      (async () => {
        await Promise.all([getData(), getPaymentData()]).catch();
      })();
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    await SalesOrderRepository.show(id, {
      with: [
        'channel',
        'customer',
        'salesOrderItems.type',
        'salesOrderItems.product',
        'salesOrderItems.unit',
        'salesOrderItems.storage',
        'creator',
        'sales'
      ]
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        // eslint-disable-next-line eqeqeq
        isClosed.set(data.is_close && data.is_close == 1);

        const formattedData = formatSetValueForm(
          {
            customer_id: null,
            sales_id: null,
            date: null,
            term_of_payment: null,
            channel_id: null,
            sales_order_items: null,
            note: '',
            discount: 0
          },
          data
        );

        if (isMounted.current) {
          informationData.number.set(_.get(data, 'number', ''));
          informationData.creatorName.set(_.get(data, 'creator.name', ''));

          if (formattedData?.sales_order_items?.length > 0) {
            formattedData.sales_order_items.forEach((item: any, key: number) => {
              formattedData.sales_order_items[key] = formatSetValueForm(
                {
                  id,
                  ...{
                    type_id: null,
                    product_id: null,
                    storage_id: null,
                    unit_id: null,
                    quantity: 0,
                    price: 0,
                    discount: 0
                  }
                },
                item
              );
            });
          }
        }

        _.forEach(formattedData, (value, name) => {
          if (name === 'date') {
            informationSetValue(name, moment(value));
          } else {
            informationSetValue(name, value);
          }
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch,
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      sales_id: null,
      customer_id: null,
      date: moment(),
      term_of_payment: 1,
      channel_id: salesOrderChannelOptions[1],
      payment: 0,
      note: '',
      discount: 0
    }
  });

  const watchCustomerId = informationWatch('customer_id');
  const watchPayment = informationWatch('payment');


  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (moment.isMoment(data['date'])) {
      formData['date'] = data['date'].format('YYYY-MM-DD HH:mm:ss');
    }

    Object.assign(formData, {
      sales_order_items: []
    });

    if (productFields.length !== 0) {
      productFields.forEach((item: any, key: number) => {
        formData.sales_order_items[key] = formatFormData(item);
      });
    }

    if (!id) {
      // eslint-disable-next-line array-callback-return
      formData.sales_order_items.map((item: any) => {
        ProductRepository.show(item.product_id, { with: ['stocks'] }).then((Response: AxiosResponse) => {
          informationLoading.set(false);
        });
      });
    }

    if (typeof formData.discount === 'string') {
      if (formData.discount === '') {
        formData.discount = 0;
      } else {
        formData.discount = parseFloat(formData.discount);
      }
    }

    (id ? SalesOrderRepository.update(id, formData) : SalesOrderRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        if (id) {
          informationLoading.set(false);
        } else {
          breadcrumbList[2].label = response.data.data.id;
          navigate(`/sales/sales-order/${response.data.data.id}`);
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });
          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const watchDate = informationWatch('date');
  const watchTermOfPayment = informationWatch('term_of_payment');

  useEffect(() => {
    if (moment.isMoment(watchDate)) {
      informationData.dueDate.set(moment($clone(watchDate)).add(watchTermOfPayment, 'day').format('D MMMM YYYY'));
    }
  }, [watchDate, watchTermOfPayment]); // eslint-disable-line

  // Table Product
  const { fields: productFields, append: productAppend, remove: productRemove, insert: productInsert } = useFieldArray({
    keyName: 'key',
    name: 'sales_order_items',
    control: informationControl
  });

  const productColumns = [
    {
      dataField: 'product_id.name',
      text: 'Name'
    },
    {
      dataField: 'price',
      text: 'Price',
      formatter: (cell: any, row: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'unit_id.name',
      text: 'Unit'
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const { errors } = formatExtraData;
        return (
          <div
            className={clsx({
              'table-slot': true,
              'table-error': _.get(errors, `sales_order_items[${rowIndex}]`)
            })}
          >
            <NumberFormat defaultValue={cell} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} />
            {_.get(errors, `sales_order_items[${rowIndex}]`) ? (
              <OverlayTrigger
                key={`table-error-${rowIndex}`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-table-${rowIndex}`}>{_.get(errors, `sales_order_items[${rowIndex}].product_id.message`)}</Tooltip>
                }
              >
                <FontAwesomeIcon icon={faExclamationCircle} className='table-error-icon' />
              </OverlayTrigger>
            ) : (
              ''
            )}
          </div>
        );
      },
      formatExtraData: {
        errors: $clone(informationErrors)
      }
    },
    {
      dataField: 'discount',
      text: 'Discount',
      formatter: (cell: any) => {
        return <VuiNumberFormat className='text-danger' data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'total',
      text: 'Total',
      isDummyField: true,
      formatter: (cell: any, row: any) => {
        const total = row.price * row.quantity - row.discount;
        return <VuiNumberFormat data={total} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleShowModalProduct(true, rowIndex)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handleProductClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const watchAdditionalDiscount = informationWatch('discount');

  const summaryData = useMemo(() => {
    const tempSummary = {
      subtotal: 0,
      discount: 0,
      total: 0,
      payment: 0,
      balance: 0
    };

    if (productFields.length) {
      productFields.forEach((value: any, index: number) => {
        tempSummary.subtotal += _.get(value, 'price', 0) * 1 * (_.get(value, 'quantity', 0) * 1);
        tempSummary.discount += _.get(value, 'discount', 0) * 1;
      });

      tempSummary.payment = id ? 0 : watchPayment;

      if (paymentData.get().length) {
        tempSummary.payment = 0;

        $clone(paymentData.get()).forEach((item: any) => {
          tempSummary.payment += item.amount * 1;
        });
      }

      tempSummary.total = tempSummary.subtotal - tempSummary.discount - watchAdditionalDiscount;
      tempSummary.balance = tempSummary.total - tempSummary.payment;
    }

    return tempSummary
  }, [id, paymentData, productFields, watchAdditionalDiscount, watchPayment])


  const showModalProduct = useState(false);
  const productLoading = useState(false);

  const {
    handleSubmit: productHandleSubmit,
    control: productControl,
    reset: productReset,
    setValue: productSetValue,
    getValues: productGetValues,
    errors: productErrors,
    watch: productWatch
  } = useForm<ProductInputs>({
    resolver: yupResolver(productSchema),
    defaultValues: {
      id: 0,
      type_id: salesOrderItemTypeOptions[0],
      product_id: null,
      unit_id: null,
      storage_id: null,
      available_stock: 0,
      quantity: 1,
      discount: 0,
      price: 0,
      total: 0
    }
  });

  const selectedProductTiers = useState<any[]>([]);
  const watchProductId = productWatch('product_id');
  const watchUnitId = productWatch('unit_id');
  const watchForTotalProduct = productWatch(['quantity', 'price', 'discount']);
  const watchForProductPrice = productWatch(['product_id', 'unit_id']);

  const comparePriceAndStock = () => {
    showWarningPrice.set(false);
    const getInputPrice = productGetValues('price');
    const getInputProduct = productGetValues('product_id');
    const getInputUnit = productGetValues('unit_id');
    const productConversion = _.get(getInputProduct, 'product_conversions');
    // eslint-disable-next-line eqeqeq
    const findUnit = productConversion?.find((product: any) => product.unit_id == _.get(getInputUnit, 'id'));

    if (findUnit) {
      if (getInputPrice < _.get(getInputProduct, 'fifo_price') * parseInt(_.get(findUnit, 'quantity'))) {
        showWarningPrice.set(true);
      }
    } else {
      if (getInputPrice < _.get(getInputProduct, 'fifo_price')) {
        showWarningPrice.set(true);
      }
    }
  };

  useEffect(() => {
    comparePriceAndStock();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchUnitId]);

  useEffect(() => {
    getProductPrice();
  }, [watchForProductPrice.unit_id, watchForProductPrice.product_id, watchForTotalProduct.quantity]); // eslint-disable-line

  const getProductPrice = () => {
    const quantity = watchForTotalProduct.quantity;

    if (quantity && watchForProductPrice.product_id && watchForProductPrice.unit_id) {
      let price = 0;

      let productTiers = $clone(selectedProductTiers.get());

      productTiers = _.filter(productTiers, o => o.unit_id * 1 === _.get(watchForProductPrice, 'unit_id.id', 0) * 1);

      price = _.get(
        _.find(productTiers, o => quantity >= o.quantity),
        'product_tier_prices[0].price'
      );

      productSetValue('price', price);
    }
  };

  useEffect(() => {
    if (watchProductId) {
      const productTiers = _.orderBy(_.get(watchProductId, 'product_tiers', []), p => p.quantity * 1, 'desc');

      selectedProductTiers.set(productTiers);
    }
  }, [watchProductId]); // eslint-disable-line

  useEffect(() => {
    let total = 0;

    if (watchForTotalProduct.quantity && watchForTotalProduct.price) {
      total = watchForTotalProduct.quantity * 1 * (watchForTotalProduct.price * 1);
    }

    if (watchForTotalProduct.discount) total = total - watchForTotalProduct.discount * 1;

    productSetValue('total', total);
  }, [watchForTotalProduct]); // eslint-disable-line

  const onProductSubmit = productHandleSubmit(data => {
    const tempData = {
      ...data
    };

    if (selectedProductIndex.get() === -1) {
      productAppend(tempData);
    } else {
      productInsert(selectedProductIndex.get() + 1, tempData);
      productRemove(selectedProductIndex.get());
    }

    showModalProduct.set(false);
  });

  const selectedProductIndex = useState(-1);

  const productDeleteIndex = useState(0);
  const showProductDeleteModal = useState(false);

  const handleProductClickDelete = (index: number) => {
    productDeleteIndex.set(index);
    showProductDeleteModal.set(true);
  };

  const handleProductDelete = () => {
    productRemove(productDeleteIndex.get());
    showProductDeleteModal.set(false);
    productDeleteIndex.set(0);
  };

  const handleShowModalProduct = (show: boolean, index: number = -1) => {
    showModalProduct.set(show);
    selectedProductIndex.set(index);
    productReset();
    unitName.set(null);

    if (index !== -1) {
      const selectedData = $clone(productFields[index]);

      setTimeout(() => {
        productSetValue('id', _.get(selectedData, 'id'));
        productSetValue('type_id', _.get(selectedData, 'type_id'));
        productSetValue('product_id', _.get(selectedData, 'product_id'));
        productSetValue('storage_id', _.get(selectedData, 'storage_id'));
        productSetValue('unit_id', _.get(selectedData, 'unit_id'));
        productSetValue('discount', _.get(selectedData, 'discount'));
        productSetValue('quantity', _.get(selectedData, 'quantity'));
        productSetValue('price', _.get(selectedData, 'price'));

        let total = 0;

        const productData = productGetValues();

        if (productData.quantity && productData.price && productData.discount) {
          total = productData.quantity * productData.price - productData.discount;
        }

        ProductRepository.selectProduct(selectedData.product_id.id).then((response: AxiosResponse) => {
          productSetValue('available_stock', _.get(response.data.data, 'quantity'));
          getAvailableStockUpdate(response.data.data.quantity, productData.quantity);
          unitName.set(response.data.data.unit_name);
        });

        productSetValue('total', total);
      }, 200);
    }
  };

  // Table Previous Balance
  const balanceData = useState<any[]>([]);

  const balanceColumns = [
    {
      dataField: 'number',
      text: 'SO Number'
    },
    {
      dataField: 'due_date',
      text: 'Due Date',
      isDummyField: true,
      formatter: (cell: any, row: any) => {
        return moment(_.get(row, 'date')).add(_.get(row, 'term_of_payment'), 'day').format('D MMMM YYYY');
      }
    },
    {
      dataField: 'outstanding_balance',
      text: 'Balance',
      formatter: (cell: any, row: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <Link type={'button'} to={`/sales/sales-order/${row.id}`} className={'btn btn-primary btn-small'} target='_blank'>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
          </div>
        );
      }
    }
  ];

  const getBalanceData = async () => {
    if (watchCustomerId !== null) {
      await SalesOrderRepository.all({
        'has-outstanding': 1,
        customer: _.get(watchCustomerId, 'id', 0),
        exclude: id ?? 0
      })
        .then((response: AxiosResponse) => {
          const data = response.data.data;

          balanceData.set(data);
        })
        .catch((e: AxiosError) => {
          if (e.isAxiosError) showToast(e.message, 'error');
        });
    }
  };

  let unitName = useState(null);

  const getAvailableStock = async (id: any) => {
    if (!id) {
      return;
    }
    await productSetValue('available_stock', _.get(id, 'quantity'));
    unitName.set(id.unit_name);
  };

  useEffect(() => {
    getAvailableStock(watchProductId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProductId]);

  const getAvailableStockUpdate = async (stock: string, quantity: any) => {
    if (!id) {
      return;
    }
    await productSetValue('available_stock', parseInt(stock) + parseInt(quantity))
  };

  const getMemberPointData = async () => {
    if (watchCustomerId !== null) {
      await MemberPointRepository.all({
        customer: _.get(watchCustomerId, 'id', 0)
      })
        .then((response: AxiosResponse) => {
          const data = response.data.data;

          if (data.length) {
            membershipData.available.set(data[0].point);
          }
        })
        .catch((e: AxiosError) => {
          if (e.isAxiosError) showToast(e.message, 'error');
        });
    }
  };

  // Table Payment

  const paymentColumns = [
    {
      dataField: 'number',
      text: 'Payment Number'
    },
    {
      dataField: 'date',
      text: 'Date',
      formatter: (cell: any, row: any) => {
        return moment(cell).format('D MMMM YYYY');
      }
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell: any, row: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            prefix={'IDR '}
          />
        );
      }
    },
    {
      dataField: 'payment_method.name',
      text: 'Method'
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      isDummyField: true,
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={'table-btn-wrapper'}>
            <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => handlePaymentClickDelete(rowIndex)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        );
      }
    }
  ];

  const paymentDeleteIndex = useState(0);
  const showPaymentDeleteModal = useState(false);

  const handlePaymentClickDelete = (index: number) => {
    paymentDeleteIndex.set(index);
    showPaymentDeleteModal.set(true);
  };

  const handlePaymentDelete = () => {
    ReceivePaymentRepository.delete(paymentData.get()[paymentDeleteIndex.get()].id)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');
        showPaymentDeleteModal.set(false);
        paymentData[paymentDeleteIndex.get()].set(none);
        paymentDeleteIndex.set(0);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const getPaymentData = async () => {
    await ReceivePaymentRepository.all({
      'sales-order': id,
      with: ['paymentMethod']
    })
      .then(async (response: AxiosResponse) => {
        const data = response.data.data;

        await paymentData.set(data);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
      });
  };

  const showPaymentModal = useState(false);

  const paymentLoading = useState(false);
  const {
    handleSubmit: paymentHandleSubmit,
    control: paymentControl,
    errors: paymentErrors,
    reset: paymentReset,
    setError: paymentSetError
  } = useForm<ReceivePaymentInputs>({
    resolver: yupResolver(receivePaymentSchema),
    defaultValues: {
      date: moment()
    }
  });

  const onPaymentSubmit = paymentHandleSubmit(data => {
    paymentLoading.set(true);

    const formData = formatFormData(data);

    Object.assign(formData, {
      sales_order_id: id
    });

    ReceivePaymentRepository.create(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, response.data.success ? 'success' : 'error');

        paymentLoading.set(false);
        showPaymentModal.set(false);
        getPaymentData();
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            paymentSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        paymentLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: t('Sales'),
      link: '/sales'
    },
    {
      label: t('Sales Order'),
      link: '/sales/sales-order'
    },
    {
      label: id ? id : t('Create'),
      link: '/sales/sales-order/create'
    }
  ];

  // Print Receipts
  const printReceiptsRef = useRef<any>();
  const openPrint = useState(
    useReactToPrint({
      content: () => printReceiptsRef.current,
      onAfterPrint: () => {}
    })
  );

  const handlePrintReceipts = useReactToPrint({
    content: () => printReceiptsRef.current,
    onAfterPrint: () => {}
  });

  const handlePrint = async () => {
    await SalesOrderRepository.print(id)
      .then((response: AxiosResponse) => {
        openPrint.set(handlePrintReceipts);
      })
      .catch((e: AxiosError) => {});
  };

  useEffect(() => {
    (async () => {
      await getBalanceData().catch();
      await getMemberPointData().catch();
    })();

    if (!id) {
      membershipData.divide.set(_.get(watchCustomerId, 'membership_divide'));
    }
  }, [watchCustomerId]); // eslint-disable-line

  useEffect(() => {
    if (membershipData.divide.get() && summaryData.total) {
      membershipData.receive.set(summaryData.total / (membershipData.divide.get() * 1));
    }
  }, [membershipData.divide.get(), summaryData.total]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>

        {id ? (
          <div className='action-wrapper'>
            <button
              type={'button'}
              className={'btn btn-primary action-item'}
              onClick={() => {
                paymentReset();
                showPaymentModal.set(true);
              }}
            >
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
              {t('Receive Payment')}
            </button>
            <button type={'button'} className={'btn btn-success action-item'} onClick={handlePrint}>
              <FontAwesomeIcon className={'icon icon-prefix'} icon={faReceipt} />
              {t('Print Receipts')}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
        <div className={'default-page-layout layout-reverse'}>
          <div className={'information-section'}>
            <div className={'card-paper'}>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>{t('Information')}</h6>
              </div>
              <div className={'card-content'}>
                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Number</label>
                  <input type='text' className='form-control' disabled value={informationData.number.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.customer_id
                  })}
                >
                  <label className={'form-label'}>Customer</label>
                  <div className={'form-with-button-wrapper'}>
                    <div className={'form-input-wrapper'}>
                      <Controller
                        name={'customer_id'}
                        control={informationControl}
                        render={props => (
                          <VuiSelect selectRepository={CustomerRepository} defaultValue={props.value} onChange={props.onChange} />
                        )}
                      />
                    </div>
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.customer_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.sales_id
                  })}
                >
                  <label className={'form-label'}>Sales</label>
                  <div className={'form-with-button-wrapper'}>
                    <div className={'form-input-wrapper'}>
                      <Controller
                        name={'sales_id'}
                        control={informationControl}
                        render={props => (
                          <VuiSelect selectRepository={SalesRepository} defaultValue={props.value} onChange={props.onChange} />
                        )}
                      />
                    </div>
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.sales_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.date
                  })}
                >
                  <label className={'form-label'}>Date</label>
                  <Controller
                    name={'date'}
                    control={informationControl}
                    render={({ value, onChange }) => (
                      <VuiDateRangePicker
                        startDate={value}
                        onChange={onChange}
                        single={true}
                        timePicker={true}
                        minDate={moment().subtract(6, 'days')}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.date, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.term_of_payment
                  })}
                >
                  <label className={'form-label'}>Term of Payment</label>
                  <div className={'form-select-wrapper type-with-button'}>
                    <Controller
                      name={'term_of_payment'}
                      control={informationControl}
                      render={props => (
                        <NumberFormat
                          className='form-control'
                          value={props.value}
                          allowNegative={false}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          decimalScale={0}
                          onValueChange={({ value }) => props.onChange(value)}
                        />
                      )}
                    />
                  </div>
                  <label className={'label-help'}>{_.get(informationErrors.term_of_payment, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true
                  })}
                >
                  <label className={'form-label'}>Due Date</label>
                  <input type='text' className='form-control' readOnly value={informationData.dueDate.get()} />
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.channel_id
                  })}
                >
                  <label className={'form-label'}>Channel</label>
                  <Controller
                    name={'channel_id'}
                    control={informationControl}
                    render={props => <VuiSelect options={salesOrderChannelOptions} defaultValue={props.value} onChange={props.onChange} />}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.channel_id, 'message')}</label>
                </div>

                <div
                  className={clsx({
                    'form-group': true,
                    error: informationErrors.note
                  })}
                >
                  <label className={'form-label'}>Note</label>
                  <Controller
                    name={'note'}
                    control={informationControl}
                    render={props => <textarea className={'form-control'} value={props.value} onChange={props.onChange} />}
                  />
                  <label className={'label-help'}>{_.get(informationErrors.note, 'message')}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='multiple-paper-section'>
            <div className='card-paper'>
              <div className={'card-header'}>
                <h6 className={'card-header-title'}>Product List</h6>
              </div>
              <div className='card-content'>
                {watchCustomerId !== null ? (
                  <>
                    <BootstrapTable keyField='key' data={productFields} columns={productColumns} />

                    <button type={'button'} className={'btn btn-primary btn-small'} onClick={() => handleShowModalProduct(true)}>
                      <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                      {t('Add Product')}
                    </button>
                  </>
                ) : (
                  <div className='card-empty-content'>please select customer first</div>
                )}
              </div>
            </div>

            {balanceData.get().length ? (
              <div className='card-paper'>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Previous Balance</h6>
                </div>
                <div className='card-content'>
                  <BootstrapTable keyField='id' data={$clone(balanceData.get())} columns={balanceColumns} />
                </div>
              </div>
            ) : (
              ''
            )}

            {paymentData.get().length ? (
              <div className='card-paper'>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Payment</h6>
                </div>
                <div className='card-content'>
                  <BootstrapTable keyField='id' data={$clone(paymentData.get())} columns={paymentColumns} />
                </div>
              </div>
            ) : (
              ''
            )}

            <div className='row'>
              <div className='col-md-6'></div>

              <div className='col-md-6'>
                <div className='card-paper'>
                  <div className={'card-header'}>
                    <h6 className={'card-header-title'}>Summary</h6>
                  </div>
                  <div className='card-content'>
                    <table className='table-summary type-right'>
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>
                            <VuiNumberFormat value={summaryData.subtotal} data={summaryData.subtotal} prefix={'IDR '} />
                          </td>
                        </tr>
                        <tr>
                          <td className='text-danger'>Discount</td>
                          <td>
                            <VuiNumberFormat
                              value={summaryData.discount}
                              className={'text-danger'}
                              data={summaryData.discount}
                              prefix={'IDR '}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className='text-danger'>Additional Discount</td>
                          <td>
                            <Controller
                              name={'discount'}
                              control={informationControl}
                              render={props => (
                                <NumberFormat
                                  className='type-small right form-control'
                                  value={props.value}
                                  displayType='input'
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  decimalScale={0}
                                  prefix={'IDR '}
                                  onValueChange={({ value }) => props.onChange(value)}
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <VuiNumberFormat value={summaryData.total} data={summaryData.total} prefix={'IDR '} />
                          </td>
                        </tr>
                        <tr>
                          <td>Payment</td>
                          <td>
                            <Controller
                              name={'payment'}
                              control={informationControl}
                              render={props => (
                                <NumberFormat
                                  className={clsx(['type-small right form-control'], {
                                    'visually-hidden': id
                                  })}
                                  value={props.value}
                                  displayType={id ? 'text' : 'input'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  decimalScale={0}
                                  prefix={'IDR '}
                                  onValueChange={({ value }) => props.onChange(value)}
                                />
                              )}
                            />

                            <VuiNumberFormat
                              className={clsx({
                                'visually-hidden': !id
                              })}
                              value={summaryData.payment}
                              data={summaryData.payment}
                              prefix={'IDR '}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Balance</td>
                          <td>
                            <VuiNumberFormat value={summaryData.balance} data={summaryData.balance} prefix={'IDR '} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isClosed.get() && (
          <div className={'card-action-form'}>
            <h6 className={'title'}>Save Change?</h6>

            <div className='btn-action-wrapper'>
              <Link to={'/sales/sales-order'} className={'btn btn-sm btn-secondary'}>
                Cancel
              </Link>
              <VuiButton forSubmit={true} label={'Save'} loading={informationLoading.get()} />
              {/*<VuiButton forSubmit={true} label={'Save & Print'} loading={informationLoading.get()} />*/}
            </div>
          </div>
        )}
      </form>

      <Modal show={showModalProduct.get()} onHide={() => showModalProduct.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProductIndex.get() === -1 ? t('Add Product') : t('Edit Product')}</Modal.Title>
        </Modal.Header>
        <form className={'form-wrapper'} onSubmit={onProductSubmit}>
          <Modal.Body>
            <Controller
              name={'id'}
              control={productControl}
              defaultValue={0}
              render={props => <input type={'hidden'} defaultValue={props.value} />}
            />

            <div className='row'>
              <div className='col-md-4'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.type_id
                  })}
                >
                  <label className={'form-label'}>Type</label>
                  <Controller
                    name={'type_id'}
                    control={productControl}
                    render={props => <VuiSelect options={salesOrderItemTypeOptions} defaultValue={props.value} onChange={props.onChange} />}
                  />
                  <label className={'label-help'}>{_.get(productErrors.type_id, 'message')}</label>
                </div>
              </div>
              <div className='col-md-8'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.product_id
                  })}
                >
                  <label className={'form-label'}>Product</label>
                  <Controller
                    name={'product_id'}
                    control={productControl}
                    render={props => (
                      <VuiSelect
                        selectParams={{
                          for: 'sales-order',
                          customer: _.get(watchCustomerId, 'id'),
                          with: ['productConversions']
                        }}
                        selectRepository={ProductRepository}
                        defaultValue={props.value}
                        onChange={val => {
                          props.onChange(val);
                          productSetValue('unit_id', null);
                          productSetValue('storage_id', null);
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{_.get(productErrors.product_id, 'message')}</label>
                </div>
              </div>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: productErrors.storage_id
              })}
            >
              <label className={'form-label'}>Storage</label>
              <Controller
                name={'storage_id'}
                control={productControl}
                defaultValue={null}
                render={props => (
                  <VuiSelect
                    propKey={_.get(watchProductId, 'id')}
                    selectParams={{
                      for: 'sales-order',
                      product: _.get(watchProductId, 'id')
                    }}
                    selectRepository={StorageRepository}
                    defaultValue={props.value}
                    onChange={val => {
                      props.onChange(val);
                    }}
                    isMulti={false}
                    isDisabled={watchProductId === null}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(productErrors.storage_id, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: productErrors.unit_id
              })}
            >
              <label className={'form-label'}>Unit</label>
              <Controller
                name={'unit_id'}
                control={productControl}
                render={props => (
                  <VuiSelect
                    propKey={_.get(watchProductId, 'id')}
                    selectParams={{
                      for: 'sales-order',
                      product: _.get(watchProductId, 'id')
                    }}
                    selectRepository={UnitRepository}
                    defaultValue={props.value}
                    onChange={val => {
                      props.onChange(val);
                      getProductPrice();
                    }}
                    isDisabled={watchProductId === null}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(productErrors.unit_id, 'message')}</label>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.available_stock
                  })}
                >
                  <label className={'form-label'}>Available Stock</label>
                  <Controller
                    name={'available_stock'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        disabled
                        placeholder={'0'}
                        suffix={unitName.value ? ` ${unitName.value}` : ''}
                        onValueChange={({ value }) => {
                          props.onChange(value);
                          getProductPrice();
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{productErrors.available_stock?.message}</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div
                  className={clsx({
                    'form-group': true,
                    error: productErrors.quantity
                  })}
                >
                  <label className={'form-label'}>Quantity</label>
                  <Controller
                    name={'quantity'}
                    control={productControl}
                    defaultValue={null}
                    render={props => (
                      <NumberFormat
                        className='form-control'
                        value={props.value}
                        allowNegative={false}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={0}
                        placeholder={'0'}
                        onValueChange={({ value }) => {
                          props.onChange(value);
                          getProductPrice();
                        }}
                      />
                    )}
                  />
                  <label className={'label-help'}>{productErrors.quantity?.message}</label>
                </div>
              </div>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: productErrors.price
              })}
            >
              <label className={'form-label'}>Price / Unit</label>
              {showWarningPrice.get() ? (
                <OverlayTrigger
                  key={`table-error`}
                  placement={'top'}
                  overlay={<Tooltip id={`tooltip-table`}>Harga jual anda di bawah harga modal</Tooltip>}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} className='price-warning' />
                </OverlayTrigger>
              ) : (
                ''
              )}
              <Controller
                name={'price'}
                control={productControl}
                defaultValue={null}
                render={props => (
                  <NumberFormat
                    className='form-control'
                    value={props.value}
                    allowNegative={false}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    placeholder={'IDR 0'}
                    prefix={'IDR '}
                    onValueChange={({ value }) => {
                      props.onChange(value);
                      comparePriceAndStock();
                    }}
                  />
                )}
              />
              <label className={'label-help'}>{productErrors.price?.message}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: productErrors.discount
              })}
            >
              <label className={'form-label'}>Discount</label>
              <Controller
                name={'discount'}
                control={productControl}
                defaultValue={null}
                render={props => (
                  <NumberFormat
                    className='form-control'
                    value={props.value}
                    allowNegative={false}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    placeholder={'IDR 0'}
                    prefix={'IDR '}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
              <label className={'label-help'}>{productErrors.discount?.message}</label>
            </div>

            <div className='form-group'>
              <label className={'form-label'}>Total</label>
              <Controller
                name={'total'}
                control={productControl}
                render={props => (
                  <h4>
                    <NumberFormat
                      value={props.value}
                      displayType={'text'}
                      allowNegative={false}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                      prefix={'IDR '}
                      placeholder={'IDR 0'}
                    />
                  </h4>
                )}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showModalProduct.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={productLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>

      <VuiActionModal
        show={showProductDeleteModal.get()}
        onHide={() => showProductDeleteModal.set(false)}
        onContinue={handleProductDelete}
      />

      <VuiActionModal
        show={showPaymentDeleteModal.get()}
        onHide={() => showPaymentDeleteModal.set(false)}
        onContinue={handlePaymentDelete}
      />

      <Modal show={showPaymentModal.get()} onHide={() => showPaymentModal.set(false)} backdrop={'static'} centered>
        <Modal.Header closeButton>
          <Modal.Title>Receive Payment</Modal.Title>
        </Modal.Header>

        <form className={'form-wrapper'} onSubmit={onPaymentSubmit}>
          <Modal.Body>
            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.date
              })}
            >
              <label className={'form-label'}>Date</label>
              <Controller
                name={'date'}
                control={paymentControl}
                render={({ value, onChange }) => (
                  <VuiDateRangePicker startDate={value} onChange={onChange} single={true} timePicker={false} />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.date, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.payment_method_id
              })}
            >
              <label className={'form-label'}>Payment Method</label>
              <Controller
                name={'payment_method_id'}
                control={paymentControl}
                render={props => (
                  <VuiSelect selectRepository={PaymentMethodRepository} defaultValue={props.value} onChange={props.onChange} />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.payment_method_id, 'message')}</label>
            </div>

            <div
              className={clsx({
                'form-group': true,
                error: paymentErrors.amount
              })}
            >
              <label className={'form-label'}>Amount</label>
              <Controller
                name={'amount'}
                control={paymentControl}
                render={props => (
                  <NumberFormat
                    className={'form-control'}
                    value={props.value}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    prefix={'IDR '}
                    placeholder={'IDR 0'}
                    max={5000000}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                )}
              />
              <label className={'label-help'}>{_.get(paymentErrors.amount, 'message')}</label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={'light'} onClick={() => showPaymentModal.set(false)}>
              {t('Cancel')}
            </Button>
            <VuiButton forSubmit={true} label={'Save'} loading={paymentLoading.get()} />
          </Modal.Footer>
        </form>
      </Modal>

      <div className='visually-hidden'>
        <PrintSalesOrderReceipts
          id={id}
          data={{
            ...informationWatch(),
            ...$clone(informationData.get()),
            products: productFields,
            summary: $clone(summaryData)
          }}
          componentRef={printReceiptsRef}
        />
      </div>
    </>
  );
};

export default SalesOrderForm;

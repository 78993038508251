import LayoutApp from '../layouts/App';
import Dashboard from '../pages/App/Dashboard';
import ExampleList from '../pages/App/Example/List';
import ExampleDetails from '../pages/App/Example/Details';
import SalesOrder from '../pages/App/Sales/SalesOrder';
import SalesOrderForm from '../pages/App/Sales/SalesOrder/Form';
import SalesReturnItem from '../pages/App/Sales/ReturnItem';
import SalesReturnItemForm from '../pages/App/Sales/ReturnItem/Form';
import PurchaseOrder from '../pages/App/Purchase/PurchaseOrder';
import PurchaseOrderForm from '../pages/App/Purchase/PurchaseOrder/Form';
import PurchaseReceiveItem from '../pages/App/Purchase/ReceiveItem';
import PurchaseReceiveItemForm from '../pages/App/Purchase/ReceiveItem/Form';
import ContactCustomer from '../pages/App/Contact/Customer';
import ContactCustomerForm from '../pages/App/Contact/Customer/Form';
import ContactSupplier from '../pages/App/Contact/Supplier';
import ContactSupplierForm from '../pages/App/Contact/Supplier/Form';
import EmployeeList from '../pages/App/Contact/Employee/List';
import EmployeeCreate from '../pages/App/Contact/Employee/Form';
import CashMonitoringList from '../pages/App/Finance/CashMonitoring/List';
import CashMonitoringCreate from '../pages/App/Finance/CashMonitoring/Form';
import Product from '../pages/App/Inventory/Product';
import ProductForm from '../pages/App/Inventory/Product/Form';
import ProductStock from '../pages/App/Inventory/Product/Stock';
import StockOpname from '../pages/App/Inventory/StockOpname';
import Mutation from '../pages/App/Inventory/Mutation';
import FinanceExpense from '../pages/App/Finance/Expense';
import FinanceExpenseForm from '../pages/App/Finance/Expense/Form';
import ReportList from '../pages/App/Report/List';
import ReportDetails from '../pages/App/Report/Details';
import Setting from '../pages/App/Setting';
import SettingCompany from '../pages/App/Setting/Company';
import SettingStorage from '../pages/App/Setting/Storage';
import SettingStorageForm from '../pages/App/Setting/Storage/Form';
import SettingMembership from '../pages/App/Setting/Membership';
import SettingMembershipForm from '../pages/App/Setting/Membership/Form';
import SettingRedeem from '../pages/App/Setting/Redeem';
import SettingRedeemForm from '../pages/App/Setting/Redeem/Form';
import SettingUser from '../pages/App/Setting/User';
import SettingUserForm from '../pages/App/Setting/User/Form';
import SettingUnit from '../pages/App/Setting/Unit';
import SettingUnitForm from '../pages/App/Setting/Unit/Form';
import SettingCategory from '../pages/App/Setting/Category';
import SettingCategoryForm from '../pages/App/Setting/Category/Form';
import AccountProfile from '../pages/App/Account/Profile';
import { Navigate } from 'react-router-dom';
import Delivery from '../pages/App/Delivery';
import DeliveryForm from '../pages/App/Delivery/Form';
import Attendance from '../pages/App/Attendance';
import AttendanceForm from '../pages/App/Attendance/Form';
import AttendanceDayOff from '../pages/App/Attendance/DayOff';
import AttendanceDayOffForm from '../pages/App/Attendance/DayOff/Form';
import PurchaseSendPayment from '../pages/App/Purchase/SendPayment';
import PurchaseSendPaymentForm from '../pages/App/Purchase/SendPayment/Form';
import FinancePayroll from '../pages/App/Finance/Payroll';
import FinancePayrollForm from '../pages/App/Finance/Payroll/Form';
import FinanceExpenseCategory from '../pages/App/Finance/Expense/Category';
import FinanceExpenseCategoryForm from '../pages/App/Finance/Expense/Category/Form';
import SettingRole from '../pages/App/Setting/Role';
import SettingRoleForm from '../pages/App/Setting/Role/Form';
import SettingPermission from '../pages/App/Setting/Permission';
import SettingPermissionForm from '../pages/App/Setting/Permission/Form';
import StockOpnameForm from '../pages/App/Inventory/StockOpname/Form';
import Home from '../pages/App/Home';
import Notification from '../pages/App/Notification';
import SettingDriver from '../pages/App/Setting/Driver';
import SettingDriverForm from '../pages/App/Setting/Driver/Form';
import DeliveryDriver from '../pages/App/Report/DeliveryDriver';
import SettingSales from '../pages/App/Setting/Sales';
import SettingSalesForm from '../pages/App/Setting/Sales/Form';
import SalesRevenue from '../pages/App/Report/SalesRevenue';
import SettingClosing from "../pages/App/Setting/Closing";

const appRoutes = [
  {
    path: '/',
    element: <LayoutApp />,
    children: [
      { index: true, path: '', element: <Navigate to={'/home'} /> },
      { path: 'home', element: <Home /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'notification', element: <Notification /> },
      {
        path: 'account',
        children: [
          { path: '', element: <Navigate to={'/account/profile'} /> },
          { path: 'profile', element: <AccountProfile /> }
        ]
      },
      {
        path: 'example',
        children: [
          { path: '', element: <ExampleList /> },
          { path: ':id', element: <ExampleDetails /> }
        ]
      },
      {
        path: 'sales',
        children: [
          { path: '', element: <Navigate to={'/sales/sales-order'} /> },
          {
            path: 'sales-order',
            children: [
              {
                path: '',
                element: <SalesOrder />
              },
              {
                path: 'create',
                element: <SalesOrderForm />
              },
              {
                path: ':id',
                element: <SalesOrderForm />
              }
            ]
          },
          {
            path: 'return-item',
            children: [
              {
                path: '',
                element: <SalesReturnItem />
              },
              {
                path: 'create',
                element: <SalesReturnItemForm />
              },
              {
                path: ':id',
                element: <SalesReturnItemForm />
              }
            ]
          }
        ]
      },
      {
        path: 'delivery',
        children: [
          {
            path: '',
            element: <Delivery />
          },
          {
            path: 'create',
            element: <DeliveryForm />
          },
          {
            path: ':id',
            element: <DeliveryForm />
          }
        ]
      },
      {
        path: 'purchase',
        children: [
          { path: '', element: <Navigate to={'/purchase/purchase-order'} /> },
          {
            path: 'purchase-order',
            children: [
              {
                path: '',
                element: <PurchaseOrder />
              },
              {
                path: 'create',
                element: <PurchaseOrderForm />
              },
              {
                path: ':id',
                element: <PurchaseOrderForm />
              }
            ]
          },
          {
            path: 'receive-item',
            children: [
              {
                path: '',
                element: <PurchaseReceiveItem />
              },
              {
                path: 'create',
                element: <PurchaseReceiveItemForm />
              },
              {
                path: ':id',
                element: <PurchaseReceiveItemForm />
              }
            ]
          },
          {
            path: 'send-payment',
            children: [
              {
                path: '',
                element: <PurchaseSendPayment />
              },
              {
                path: 'create',
                element: <PurchaseSendPaymentForm />
              },
              {
                path: ':id',
                element: <PurchaseSendPaymentForm />
              }
            ]
          }
        ]
      },
      {
        path: 'contact',
        children: [
          { path: '', element: <Navigate to={'/contact/customer'} /> },
          {
            path: 'customer',
            children: [
              {
                path: '',
                element: <ContactCustomer />
              },
              {
                path: 'create',
                element: <ContactCustomerForm />
              },
              {
                path: ':id',
                element: <ContactCustomerForm />
              }
            ]
          },
          {
            path: 'supplier',
            children: [
              {
                path: '',
                element: <ContactSupplier />
              },
              {
                path: 'create',
                element: <ContactSupplierForm />
              },
              {
                path: ':id',
                element: <ContactSupplierForm />
              }
            ]
          }
        ]
      },
      {
        path: 'employee',
        children: [
          { path: '', element: <EmployeeList /> },
          { path: 'create', element: <EmployeeCreate /> }
        ]
      },
      {
        path: 'inventory',
        children: [
          { path: '', element: <Navigate to={'/inventory/product'} /> },
          {
            path: 'product',
            children: [
              { path: '', element: <Product /> },
              { path: 'create', element: <ProductForm /> },
              { path: ':id', element: <ProductForm /> },
              { path: 'stock', element: <ProductStock /> }
            ]
          },
          {
            path: 'mutation',
            children: [{ path: '', element: <Mutation /> }]
          },
          {
            path: 'stock-opname',
            children: [
              { path: '', element: <StockOpname /> },
              { path: ':id', element: <StockOpnameForm /> }
            ]
          }
        ]
      },

      {
        path: 'finance',
        children: [
          { path: '', element: <Navigate to={'/finance/payroll'} /> },
          {
            path: 'payroll',
            children: [
              { path: '', element: <FinancePayroll /> },
              { path: ':id', element: <FinancePayrollForm /> }
            ]
          },
          {
            path: 'expense',
            children: [
              { path: '', element: <FinanceExpense /> },
              { path: 'create', element: <FinanceExpenseForm /> },
              {
                path: 'category',
                children: [
                  { path: '', element: <FinanceExpenseCategory /> },
                  { path: 'create', element: <FinanceExpenseCategoryForm /> },
                  {
                    path: ':id',
                    element: <FinanceExpenseCategoryForm />
                  }
                ]
              },

              { path: ':id', element: <FinanceExpenseForm /> }
            ]
          },
          {
            path: 'cash-monitoring',
            children: [
              { path: '', element: <CashMonitoringList /> },
              { path: 'create', element: <CashMonitoringCreate /> },
              { path: ':id', element: <CashMonitoringCreate /> }
            ]
          }
        ]
      },
      {
        path: 'report',
        children: [
          { path: '', element: <ReportList /> },
          { path: 'delivery-per-driver', element: <DeliveryDriver /> },
          { path: 'sales', element: <SalesRevenue /> },
          { path: 'profit-and-loss', element: <ReportDetails /> },
          { path: ':id', element: <ReportDetails /> }
        ]
      },
      {
        path: 'attendance',
        children: [
          { path: '', element: <Attendance /> },
          { path: 'create', element: <AttendanceForm /> },
          { path: ':id', element: <AttendanceForm /> },

          {
            path: 'day-off',
            children: [
              {
                path: '',
                element: <AttendanceDayOff />
              },
              { path: 'create', element: <AttendanceDayOffForm /> },
              { path: ':id', element: <AttendanceDayOffForm /> }
            ]
          }
        ]
      },
      {
        path: 'setting',
        children: [
          { path: '', element: <Setting /> },
          { path: 'company', element: <SettingCompany /> },
          {
            path: 'storage',
            children: [
              {
                path: '',
                element: <SettingStorage />
              },
              {
                path: 'create',
                element: <SettingStorageForm />
              },
              {
                path: ':id',
                element: <SettingStorageForm />
              }
            ]
          },
          {
            path: 'membership',
            children: [
              {
                path: '',
                element: <SettingMembership />
              },
              {
                path: 'create',
                element: <SettingMembershipForm />
              },
              {
                path: ':id',
                element: <SettingMembershipForm />
              }
            ]
          },
          {
            path: 'driver',
            children: [
              {
                path: '',
                element: <SettingDriver />
              },
              {
                path: 'create',
                element: <SettingDriverForm />
              },
              {
                path: ':id',
                element: <SettingDriverForm />
              }
            ]
          },
          {
            path: 'sales',
            children: [
              {
                path: '',
                element: <SettingSales />
              },
              {
                path: 'create',
                element: <SettingSalesForm />
              },
              {
                path: ':id',
                element: <SettingSalesForm />
              }
            ]
          },
          {
            path: 'redeem',
            children: [
              {
                path: '',
                element: <SettingRedeem />
              },
              {
                path: 'create',
                element: <SettingRedeemForm />
              },
              {
                path: ':id',
                element: <SettingRedeemForm />
              }
            ]
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                element: <SettingUser />
              },
              {
                path: 'create',
                element: <SettingUserForm />
              },
              {
                path: ':id',
                element: <SettingUserForm />
              }
            ]
          },
          {
            path: 'unit',
            children: [
              {
                path: '',
                element: <SettingUnit />
              },
              {
                path: 'create',
                element: <SettingUnitForm />
              },
              {
                path: ':id',
                element: <SettingUnitForm />
              }
            ]
          },
          {
            path: 'category',
            children: [
              {
                path: '',
                element: <SettingCategory />
              },
              {
                path: 'create',
                element: <SettingCategoryForm />
              },
              {
                path: ':id',
                element: <SettingCategoryForm />
              }
            ]
          },
          {
            path: 'role',
            children: [
              {
                path: '',
                element: <SettingRole />
              },
              {
                path: 'create',
                element: <SettingRoleForm />
              },
              {
                path: ':id',
                element: <SettingRoleForm />
              }
            ]
          },
          {
            path: 'permission',
            children: [
              {
                path: '',
                element: <SettingPermission />
              },
              {
                path: 'create',
                element: <SettingPermissionForm />
              },
              {
                path: ':id',
                element: <SettingPermissionForm />
              }
            ]
          },
          {
            path: 'closing',
            children: [
              {
                path: '',
                element: <SettingClosing />
              },
            ]
          }
        ]
      }
    ]
  }
];

export default appRoutes;

import React, { CSSProperties, FC, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { IOrderDirectionType } from '../../../../interfaces/helpers.interface';
import VuiNumberFormat from '../VuiNumberFormat';

export type ColumnFormatter<R, C = any, E = any> = (
  cell: C,
  row: R,
  rowIndex: number,
  formatExtraData: E
) => JSX.Element | string | boolean | React.ReactText;

export type OrderDirection = 'desc' | 'asc' | 'default';

export interface ITableColumn<T extends object = any, E = any> {
  dataField: string;
  text: string;
  render?: ColumnFormatter<T, E>;
  headerClasses?: string;
  classes?: string;
  defaultValue?: string;
  headerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  formatExtraData?: {
    tooltipFormatter?: (row: T) => JSX.Element;
  } & E;
  sort?: boolean;
  orderBy?: OrderDirection;
}

interface TableRowProps {
  propKey: string;
  data: any;
  columns: ITableColumn[];
}

const TableRow: FC<TableRowProps> = ({ propKey = 'id', data, columns }) => {
  const renderKey = (row: any) => {
    return row[propKey];
  };

  const renderCell = (item: any, column: ITableColumn, rowIndex: number, formatExtraData: any) => {
    if (column.render) {
      return column.render(item[column.dataField], item, rowIndex, formatExtraData);
    }
    let data = item[column.dataField];
    return data || (data === null ? column.defaultValue : '');
  };

  return (
    <tr key={renderKey(data)}>
      {columns.map((column, index: number) => (
        <td key={index} className={column.classes} style={column.style}>
          {renderCell(data, column, index, column.formatExtraData)}
        </td>
      ))}
    </tr>
  );
};

export const NoDataIndication = () => <div className='table-indication-section'>No data available in table</div>;

export const LoadingIndication = ({ styles }: { styles?: CSSProperties }) => (
  <div className='table-loading-section for-infinite-scroll'>
    <div className='spinner-border text-secondary' role='status' style={styles}>
      <span className='visually-hidden'>Loading...</span>
    </div>
  </div>
);

interface IDataTableInfiniteScroll {
  propKey: string;
  columns: Array<ITableColumn>;
  data: Array<any>;
  isLoading: boolean;
  isFetchingMoreData: boolean;
  isVirtualized?: boolean;
  callback?: any;
  hasMore?: boolean;
  hasInitialize?: boolean;
  onTableChange?: (e: IOrderDirectionType) => void;
  totalData?: number;
  totalDataTitle?: string;
}

const DataTableInfiniteScroll: FC<IDataTableInfiniteScroll> = ({
  propKey = 'id',
  columns = [],
  data = [],
  isLoading = false,
  isFetchingMoreData = false,
  isVirtualized = false,
  callback = null,
  hasMore = false,
  hasInitialize = false,
  onTableChange = null,
  totalData = 0,
  totalDataTitle = 'Total Data'
}) => {
  const tableRef = useRef<any>(null);

  const onScroll = _.debounce((e: any) => {
    let tableEl = tableRef.current;
    if (tableEl.scrollTop >= tableEl.scrollHeight - tableEl.offsetHeight - 20) {
      if (callback && hasMore && hasInitialize) {
        callback(false, true);
      }
    }
  }, 150);

  // const handleSort = (column: ITableColumn) => {
  //   console.log(column.orderBy, column.orderBy === 'default');
  //   if (column.sort) {
  //     if (onTableChange) {
  //       onTableChange({
  //         sortedBy: column.dataField,
  //         orderBy: column.orderBy === 'default' ? 'desc' : column.orderBy === 'desc' ? 'asc' : column.orderBy === 'asc' ? 'desc' : 'default'
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <h6 className={'mb-3 d-flex'}>
        <span className={'mr-2'}>{totalDataTitle} :</span>
        <b>
          {isLoading ? <LoadingIndication styles={{ width: 18, height: 18 }} /> : <VuiNumberFormat data={totalData} value={totalData} />}
        </b>
      </h6>

      <div
        className={clsx({
          'react-bootstrap-table table-responsive table-infinite-scroll': true,
          'no-data': data.length == 0 && !isLoading,
          virtualize: isVirtualized
        })}
        ref={tableRef}
        onScroll={isVirtualized ? onScroll : e => {}}
      >
        <table className='table' style={{ marginBottom: 0 }}>
          <thead className={'table-header'}>
            <tr>
              {columns.map((column, index: number) => (
                <th
                  // onClick={(e: any) => handleSort(column)}
                  key={index}
                  className={clsx(column.headerClasses, {
                    'has-sorting': column.sort
                  })}
                  style={column.headerStyle}
                >
                  {column.text}
                  {/*{column.sort ? (*/}
                  {/*  <span className='order'>*/}
                  {/*    {column.orderBy === 'desc' || column.orderBy === 'default' ? (*/}
                  {/*      <span className='dropdown'>*/}
                  {/*        <span className='caret' />*/}
                  {/*      </span>*/}
                  {/*    ) : null}*/}
                  {/*    {column.orderBy === 'asc' || column.orderBy === 'default' ? (*/}
                  {/*      <span className='dropup'>*/}
                  {/*        <span className='caret' />*/}
                  {/*      </span>*/}
                  {/*    ) : null}*/}
                  {/*  </span>*/}
                  {/*) : null}*/}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className={clsx({
              'table-body': true,
              'for-loading': isLoading
            })}
          >
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className={'text-center'}>
                  <LoadingIndication />
                </td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item: any, index) => <TableRow key={index} propKey={propKey} data={item} columns={columns} />)
            ) : (
              <tr>
                <td colSpan={columns.length} className={'text-center'}>
                  <NoDataIndication />
                </td>
              </tr>
            )}
            {isFetchingMoreData && !isLoading && (
              <tr>
                <td colSpan={columns.length} className={'text-center'}>
                  <LoadingIndication />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(DataTableInfiniteScroll);

// @ts-nocheck
import React, { useEffect } from 'react';

import { useState } from '@hookstate/core/dist';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import nl2br from 'react-nl2br';
import NumberFormat from 'react-number-format';
import SalesOrderRepository from '../repositories/SalesOrderRepository';
import { showToast } from '../vodea/utilities/helpers/global';
import useIsMounted from '../vodea/utilities/hooks/useIsMounted';
import './styles.scss';

type State = {
  id: any;
  data: object;
  componentRef: React.Ref<any>;
};

const PrintSalesOrderReceipts: React.FC<State> = ({ id, data, componentRef }) => {
  const isMounted = useIsMounted();
  const printData = useState<any>({});

  useEffect(() => {
    if (id && isMounted.current) {
      //getData()
    }
  }, [id]);

  const getData = async () => {
    await SalesOrderRepository.show(
      id,
      {
        with: [
          'channel',
          'customer',
          'salesOrderItems.type',
          'salesOrderItems.product',
          'salesOrderItems.unit',
          'salesOrderItems.storage',
          'creator'
        ]
      },
      false
    )
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        printData.set(data);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  return (
    <div ref={componentRef}>
      <div className='print-component'>
        <table className='table-header'>
          <tbody>
            <tr>
              <td>Nota Penjualan TukuBako</td>
            </tr>
            <tr>
              <td>081808530090 / 0215980204</td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>NO. FAKTUR</td>
              <td>:</td>
              <td>{_.get(data, 'number')}</td>
            </tr>
            <tr>
              <td>TANGGAL</td>
              <td>:</td>
              <td>{_.get(data, 'date').format('D MMMM YYYY HH:mm A')}</td>
            </tr>
            <tr>
              <td>JATUH TEMPO</td>
              <td>:</td>
              <td>{_.get(data, 'dueDate')}</td>
            </tr>
            <tr>
              <td>KASIR</td>
              <td>:</td>
              <td>{_.get(data, 'creatorName')}</td>
            </tr>
          </tbody>
        </table>

        <hr />

        <table>
          <tbody>
            <tr>
              <td>PELANGGAN</td>
              <td>:</td>
              <td>{_.get(data, 'customer_id.name')}</td>
            </tr>
            <tr>
              <td>ALAMAT</td>
              <td>:</td>
              <td>{_.get(data, 'customer_id.address')}</td>
            </tr>
            <tr>
              <td>TELP</td>
              <td>:</td>
              <td>{_.get(data, 'customer_id.mobile_phone')}</td>
            </tr>
          </tbody>
        </table>

        <hr />

        <table className='table-bordered table-list'>
          <thead>
            <tr>
              <th></th>
              <th className='text-start'>NAMA BARANG</th>
              <th>QTY</th>
              <th>@HARGA</th>
              <th className='text-end'>JUMLAH Rp</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, 'products').map((item: any, key: number) => {
              const subtotal = _.get(item, 'price') * 1 * (_.get(item, 'quantity') * 1);

              return (
                <tr key={key}>
                  <td className='no-wrap'>
                    <NumberFormat
                      defaultValue={key + 1}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                      suffix={'.'}
                    />
                  </td>
                  <td className='text-start'>{_.get(item, 'product_id.name')}</td>
                  <td>
                    <NumberFormat
                      defaultValue={_.get(item, 'quantity')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />{' '}
                    {_.get(item, 'unit_id.code')}
                  </td>
                  <td>
                    <NumberFormat
                      defaultValue={_.get(item, 'price')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                  <td className='text-end'>
                    <NumberFormat
                      defaultValue={subtotal}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <hr />

        <div className='row'>
          <p className='print-text-note'>{nl2br(`Keterangan: ${_.get(data, 'note', '-')}`)}</p>
          <p className='print-text-note mb-4'>
            Terima kasih sudah berbelanja. Harap memeriksa kembali pesanan yang diterima sebelum kurir meninggalkan lokasi Anda.
          </p>
          <div className='col-4' />
          <div className='col-8'>
            <table className='table-summary'>
              <tbody>
                <tr>
                  <td className='text-end'>Subtotal</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'summary.subtotal')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>Potongan</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'summary.discount')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>Potongan Tambahan</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'discount')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>Total</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'summary.total')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>Bayar</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'summary.payment')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='text-end'>Kembali</td>
                  <td className='text-end'>
                    <NumberFormat
                      value={_.get(data, 'summary.balance')}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintSalesOrderReceipts;

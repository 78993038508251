import * as yup from 'yup';
import 'yup-phone';

export type ItemInputs = {
  id: number | null;
  expense_category_id: object;
  description: string;
  amount: number;
};

export const itemSchema = yup.object().shape({
  id: yup.mixed(),
  expense_category_id: yup.mixed().required().label('category'),
  description: yup.string(),
  amount: yup.number().typeError('field must be a number.').required().label('amount')
});

export type InformationInputs = {
  user_id: object;
  date: object;
  payment_method_id: object;
  expense_items: ItemInputs[];
};

export const informationSchema = yup.object().shape({
  user_id: yup.mixed().required().label('beneficiary'),
  date: yup.mixed().required(),
  payment_method_id: yup.mixed().required().label('method')
});

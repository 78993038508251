// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useState } from '@hookstate/core/dist';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InformationInputs, informationSchema, InformationBaseModel } from './validation';
import { useTranslation } from 'react-i18next';
import SettingRepository from '../../../../repositories/SettingRepository';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { mapHookErrors, showToast } from '../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import VuiActionForm from '../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import { formatFormData, formatSetValueForm } from '../../../../vodea/utilities/helpers/form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';

const SettingCompany: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setValue: informationSetValue,
    setError: informationSetError
  } = useForm<InformationInputs>({
    mode: 'onChange',
    resolver: yupResolver(informationSchema)
  });

  useEffect(() => {
    getCompanyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanyData = () => {
    SettingRepository.company().then((response: AxiosResponse) => {
      const data = response.data.data;

      const formattedData = formatSetValueForm(InformationBaseModel, data);

      _.forEach(formattedData, (value, name) => {
        // @ts-ignore
        informationSetValue(name, value);
      });
    });
  };

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const formData = formatFormData(data);

    SettingRepository.updateCompany(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, 'success');

        informationLoading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Company'),
      link: '/setting/company'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Company')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />
      <div id={'setting-section'}>
        <div className={'page-header-component'}>
          <h3 className={'title'}>{t('Company')}</h3>
        </div>

        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout'}>
            <div>
              <div className={'card-setting'}>
                <div className={'inner-content'}>
                  <FontAwesomeIcon className={'icon icon-prefix type-setting'} icon={faBuilding} />
                  <h3 className={'title'}>{t('Company')}</h3>
                </div>
              </div>
            </div>

            <div className='information-section'>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.name
                      })}
                    >
                      <label className={'form-label'}>Company Name</label>
                      <input type='text' name='name' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.name?.message}</label>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.email
                        })}
                      >
                        <label className={'form-label'}>Email</label>
                        <input type='text' name='email' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.email?.message}</label>
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.telephone
                        })}
                      >
                        <label className={'form-label'}>Phone Number</label>
                        <input type='text' name='telephone' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.telephone?.message}</label>
                      </div>
                    </div>
                  </div>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.address
                      })}
                    >
                      <label className={'form-label'}>Address</label>
                      <textarea name='address' className='form-control' rows={4} ref={informationRegister}></textarea>
                      <label className={'label-help'}>{informationErrors.address?.message}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {permissions.includes('setting-company.update') ? (
            <VuiActionForm loading={informationLoading.get()} cancelLink={'/setting'} />
          ) : null}
        </form>
      </div>
    </>
  );
};

export default SettingCompany;

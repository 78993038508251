import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';

const loadAsyncOptions = async (search: string, prevOptions: any, page: number, selectRepository: any, selectParams = {}) => {
  let data: any = {};

  if (selectRepository !== null) {
    await selectRepository
      .select({
        ...selectParams,
        per_page: 10,
        search: search !== '' ? search : null,
        page: page
      })
      .then((response: AxiosResponse) => {
        data = response.data;
      })
      .catch((e: AxiosError) => {});
  }

  return {
    options: _.get(data, 'data', []),
    hasMore: _.get(data, 'data', []).length > 0 && _.get(data, 'data', []).length === 10,
    additional: {
      page: page + 1
    }
  };
};

export default loadAsyncOptions;

// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useState } from '@hookstate/core/dist';
import { $clone, defaultActionFormatter } from '../../../../vodea/utilities';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { showToast } from '../../../../vodea/utilities/helpers/global';
import { defaultStatusOptions } from '../../../../constants';
import VuiActionModal from '../../../../vodea/@vodea-ui/components/Modal/VuiActionModal';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import useScroll from '../../../../vodea/utilities/hooks/useScroll';
import DataTableInfiniteScroll, { ITableColumn } from '../../../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import VuiSearch from '../../../../vodea/@vodea-ui/components/VuiSearch';
import DriverRepository from '../../../../repositories/DriverRepository';

const SettingDriver = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { isBottom } = useScroll();
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(false);
  const [totalData, setTotalData] = React.useState<number>(0);

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    DriverRepository.delete(tableDeleteId.get())
      .then((response: AxiosResponse) => {
        showToast(response.data.message, 'error');
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const tableConfig = useState({
    search: '',
    page: 1,
    per_page: 10,
    sorted_by: 'desc',
    order_by: 'created_at',
    total: 0,
    loading: false
  });

  const tableData = useState<any[]>([]);

  const tableColumns: ITableColumn[] = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'is_pic',
      text: 'Type',
      render: (cell: any) => {
        return Boolean(Number(cell)) ? 'PIC' : 'Driver';
      }
    },
    {
      dataField: 'is_visible',
      text: 'Status',
      render: (cell: any) => {
        const selected = _.find(defaultStatusOptions, option => {
          return Number(option.id) === Number(cell);
        });

        return _.get(selected, 'name', '');
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      render: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete,
        permission: 'driver',
        permissions: permissions
      }
    }
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = async (reset: boolean = false, forNextPage: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    if (isMounted.current && forNextPage) {
      setIsFetchingMoreData(true);
      tableConfig.page.set(tableConfig.page.get() + 1);
    } else {
      changeTableLoading(true);
    }

    if (isMounted.current) {
      setHasMore(true);
    }

    const conf = _.omit($clone(tableConfig.value), 'loading');

    let params: any = {};
    Object.keys(conf).forEach(prop => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current && !forNextPage) tableData.set([]);

    await DriverRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          tableData.merge(responseData);
          setIsFetchingMoreData(false);
          setHasInitialize(true);
          if (responseData.length == 0 || responseData.length < tableConfig.per_page.get()) {
            setHasMore(false);
          }
          if (reset) setTotalData(response.data.total_data);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, 'error');
        if (isMounted.current) {
          setHasInitialize(true);
          setIsFetchingMoreData(false);
          setTotalData(0);
        }
        changeTableLoading(false);
      });
  };

  useMemo(() => {
    getTableData(true);
  }, []);

  useMemo(() => {
    if (isBottom && hasInitialize && !isFetchingMoreData && hasMore) {
      getTableData(false, true);
    }
  }, [isBottom]);

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Driver'),
      link: '/setting/driver'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Driver')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Driver')}</h3>
        {permissions.includes('driver.store') ? (
          <div className={'btn-wrapper'}>
            <Link to={'/setting/driver/create'}>
              <button type={'button'} className={'btn btn-primary'}>
                <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                {t('Create Driver')}
              </button>
            </Link>
          </div>
        ) : null}
      </div>

      {permissions.includes('driver.show') ? (
        <div className={'card-paper'}>
          <div className={'card-content'}>
            <div className={'default-filter-section'}>
              <div className={'filter-item filter-special type-search'}>
                <VuiSearch value={tableConfig.search.get()} callback={handleTableSearch} />
              </div>
            </div>

            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData.get()}
              propKey={'id'}
              isLoading={tableConfig.loading.get()}
              isFetchingMoreData={isFetchingMoreData}
              totalData={totalData}
            />
          </div>
        </div>
      ) : null}

      <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />
    </>
  );
};

export default SettingDriver;

import * as yup from 'yup';
import 'yup-phone';
import {phoneRegExp} from "../../../../../types";

export type InformationInputs = {
  name: string;
  email: string;
  mobile_phone: string;
  date_of_birth: object;
  membership_id: object;
  photo_id: number | null;
  address: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  email: yup.string().label('Email'),
  mobile_phone: yup.string().matches(phoneRegExp, {
    message:"Phone number is not valid",
    excludeEmptyString:true
  }).label('Phone Number'),
  date_of_birth: yup.mixed().label('date of birth'),
  membership_id: yup.mixed().required().label('Membership ID'),
  address: yup.string(),
  photo_id: yup.mixed()
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  email: '',
  mobile_phone: '',
  date_of_birth: {},
  membership_id: {},
  address: '',
  photo_id: null
};

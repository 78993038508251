// @ts-nocheck
import React, { useCallback } from 'react';
import { $clone } from '../../vodea/utilities';
import VuiDateRangePicker from '../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import WidgetRepository from '../../repositories/WidgetRepository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import VuiNumberFormat from '../../vodea/@vodea-ui/components/VuiNumberFormat';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { TableVirtualizeWidgetProps } from '../widget.interface';
import DataTableInfiniteScroll from '../../vodea/@vodea-ui/components/DataTableInfiniteScroll';
import { AxiosError } from 'axios';
import fileDownload from 'js-file-download';

const ListCustomerWidget: React.FC<TableVirtualizeWidgetProps> = ({
  handleSearch,
  tableData,
  isFetchingMoreData,
  handleChangeDate,
  tableConfig,
  callback,
  hasMore = false,
  hasInitialize = false,
  onTableChange,
  totalData = 0
}) => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'count',
      text: 'T Sales Count',
      sort: true
    },
    {
      dataField: 'value',
      text: 'T Sales Amount',
      sort: true,
      render: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'profit',
      text: 'Profit',
      sort: true,
      render: (cell: any) => {
        return <VuiNumberFormat className={cell < 0 ? 'text-danger' : cell != 0 ? 'text-success' : 'text'} data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'id',
      text: 'Action',
      render: (cell: any, row: any) => {
        return permissions.includes('report.list-customer') ? (
          <button type='button' className='btn btn-primary btn-small' onClick={() => handleExportCustomer(row)}>
            {t('Export')}
          </button>
        ) : (
          <div />
        );
      }
    }
  ];

  const handleExportCustomer = useCallback(
    async row => {
      const conf = _.omit($clone(tableConfig), ['loading', 'total']);

      let params: any = {
        customer_id: row.id
      };

      Object.keys(conf).forEach(prop => {
        if (conf[prop]) {
          params[prop] = conf[prop];
        }
      });

      const fileName = `customer-report-${row?.name.toLowerCase().replace(' ', '-')}.xlsx`;

      await WidgetRepository.exportListCustomer(params)
        .then((response: any) => {
          fileDownload(response.data, fileName);
        })
        .catch((error: AxiosError) => {});
    },
    [tableConfig]
  );

  return (
    <>
      <div className={'card-paper'}>
        <div className={'card-header'}>
          <div className={'card-header-filter-wrapper'}>
            <div className={'title-wrapper'}>
              <h6 className={'card-header-title'}>{t(`List Customer`)}</h6>
            </div>
            <div className={'display-flex align-items-center'}>
              <div className={'filter-item'}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from}
                  endDate={tableConfig.date_to}
                  callback={handleChangeDate}
                  useRanges={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={'card-content'}>
          <div className={'default-filter-section'}>
            <div className={'filter-item type-search-full'}>
              <div className='input-group prefix-group'>
                <span className='input-group-text'>
                  <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                </span>
                <input
                  defaultValue={tableConfig.search}
                  type='text'
                  className='form-control'
                  placeholder='Search'
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>

          {tableConfig.is_skeleton_loading ? (
            <div className={'table-box is-table-loading'} />
          ) : (
            <DataTableInfiniteScroll
              columns={tableColumns}
              data={tableData}
              propKey={'id'}
              isLoading={tableConfig.loading}
              isFetchingMoreData={isFetchingMoreData}
              isVirtualized={true}
              callback={callback}
              hasMore={hasMore}
              hasInitialize={hasInitialize}
              onTableChange={onTableChange}
              totalData={totalData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListCustomerWidget;

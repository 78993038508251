import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import VuiDateRangePicker from '../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker';
import _ from 'lodash';
import { InformationInputs, informationSchema, PasswordInputs, passwordSchema } from './validation';
import VuiUploadImage from '../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage';
import VuiTextEditor from '../../../../vodea/@vodea-ui/components/Forms/VuiTextEditor';
import VuiSelect from '../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import moment from 'moment';
import VuiSelectMedia from '../../../../vodea/@vodea-ui/components/Forms/VuiSelectMedia';
import { formatFormData } from '../../../../vodea/utilities/helpers/form';

const ExampleDetails: React.FC<any> = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const title = `Example Details ${id}`;

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl
  } = useForm<InformationInputs>({
    mode: 'onChange',
    resolver: yupResolver(informationSchema)
  });
  const onInformationSubmit = informationHandleSubmit(data => {
    const formData = formatFormData(data);

    informationLoading.set(true);

    setTimeout(() => {
      informationLoading.set(false);
    }, 1000);
  });

  const passwordLoading = useState(false);
  const { register: passwordRegister, handleSubmit: passwordHandleSubmit, errors: passwordErrors } = useForm<PasswordInputs>({
    resolver: yupResolver(passwordSchema)
  });
  const onPasswordSubmit = passwordHandleSubmit(data => {
    const formData = formatFormData(data);

    passwordLoading.set(true);

    setTimeout(() => {
      passwordLoading.set(false);
    }, 1000);
  });

  const breadcrumbList = [
    {
      label: 'Example',
      link: '/example'
    },
    {
      label: title,
      link: '/example/1'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Example Details</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <div className={'default-page-layout'}>
        <div className={'information-section'}>
          <div className={'card-paper'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Information')}</h6>
            </div>
            <div className={'card-content'}>
              <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.name
                      })}
                    >
                      <label className={'form-label'}>Name</label>
                      <input type='text' name='name' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.name?.message}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.role
                      })}
                    >
                      <label className={'form-label'}>Role</label>
                      <Controller
                        name={'role'}
                        defaultValue={{
                          key: 3,
                          label: 'Option 3'
                        }}
                        control={informationControl}
                        render={props => <VuiSelect defaultValue={props.value} onChange={props.onChange} />}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.role, 'message')}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.email
                      })}
                    >
                      <label className={'form-label'}>Email</label>
                      <input type='text' name='email' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.email?.message}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.phone_number
                      })}
                    >
                      <label className={'form-label'}>Phone Number</label>
                      <input type='text' name='phone_number' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.phone_number?.message}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.date_single
                      })}
                    >
                      <label className={'form-label'}>Date Single</label>
                      <Controller
                        name={'date_single'}
                        control={informationControl}
                        defaultValue={new Date()}
                        render={({ value, onChange }) => <VuiDateRangePicker onChange={onChange} single={true} timePicker={true} />}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.date_single, 'message')}</label>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.date_range
                      })}
                    >
                      <label className={'form-label'}>Date Range</label>
                      <Controller
                        name={'date_range'}
                        control={informationControl}
                        defaultValue={new Date()}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker
                            startDate={moment().subtract(10, 'days').format('YYYY-MM-DD')}
                            onChange={onChange}
                            single={false}
                            timePicker={false}
                          />
                        )}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.date_range, 'message')}</label>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.tags
                      })}
                    >
                      <label className={'form-label'}>Tags</label>
                      <Controller
                        name={'tags'}
                        control={informationControl}
                        render={({ value, onChange }) => <VuiSelect defaultValue={value} onChange={onChange} isMulti={true} />}
                      />
                      <label className={'label-help'}>{_.get(informationErrors.tags, 'message')}</label>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.description
                      })}
                    >
                      <label className={'form-label'}>Description</label>
                      <Controller
                        render={({ value, name, onChange }) => <VuiTextEditor name={name} defaultValue={value} onChange={onChange} />}
                        name='description'
                        control={informationControl}
                        defaultValue=''
                      />
                      <label className={'label-help'}>{informationErrors.description?.message}</label>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.image_single
                      })}
                    >
                      <label className={'form-label'}>Image Single</label>
                      <Controller
                        name='image_single'
                        control={informationControl}
                        defaultValue={null}
                        render={({ value, onChange }) => <VuiUploadImage defaultValue={value} onChange={onChange} />}
                      />
                      <label className={'label-help'}>{informationErrors.image_single?.message}</label>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.select_media
                      })}
                    >
                      <label className={'form-label'}>Select Media</label>
                      <Controller
                        name='select_media'
                        control={informationControl}
                        defaultValue={[]}
                        render={({ value, onChange }) => <VuiSelectMedia max={5} defaultValue={value} onChange={onChange} />}
                      />
                      <label className={'label-help'}>{informationErrors.select_media?.message}</label>
                    </div>
                  </div>
                </div>

                <div className={'form-btn-wrapper'}>
                  <VuiButton forSubmit={true} label={'Save'} loading={informationLoading.get()} />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={'profile-picture-section'}>
          <div className={'card-paper floating-content'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Profile Picture')}</h6>
            </div>
            <div className={'card-content'}></div>
          </div>
        </div>

        <div className={'password-section'}>
          <div className={'card-paper'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Password')}</h6>
            </div>
            <div className={'card-content'}>
              <form className={'form-wrapper'} onSubmit={onPasswordSubmit}>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.current_password
                          })}
                        >
                          <label className={'form-label'}>Current Password</label>
                          <input type='password' name='current_password' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.current_password?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.new_password
                          })}
                        >
                          <label className={'form-label'}>New Password</label>
                          <input type='password' name='new_password' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.new_password?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.confirm_new_password
                          })}
                        >
                          <label className={'form-label'}>Confirm New Password</label>
                          <input type='password' name='confirm_new_password' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.confirm_new_password?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'form-btn-wrapper'}>
                  <VuiButton forSubmit={true} label={'Save'} loading={passwordLoading.get()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExampleDetails;

import { api, createCancelTokenHandler } from '../vodea/services';
// import qs from 'query-string';

// const queryParams = () => {
//  return qs.parse(window.location.search);
// };

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    // const { notif_id: notifId } = queryParams();
    return api.get(`${endPoint()}/api/sales-order`, {
      params: {
        ...params,
        // notif_id: notifId
      },
      cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
    });
  },
  show: function (id: number | string, params: any = null, useCancelToken = true) {
    // const { notif_id: notifId } = queryParams();
    return api.get(`${endPoint()}/api/sales-order/${id}`, {
      params: {
        ...params,
        // notif_id: notifId
      },
      cancelToken: useCancelToken ? cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token : false
    });
  },
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/select/sales-order`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.select.name].handleRequestCancellation().token
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/sales-order`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/sales-order/${id}`, payload, {
      params,
      cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/sales-order/${id}`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
    });
  },
  print: function (id: number | string, params: any = null) {
    return api.put(`${endPoint()}/api/sales-order/${id}/print-count`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
    });
  },
  export: function (params: any = null) {
    return api.get(`${endPoint()}/api/report/sales-order`, {
      params,
      cancelToken: cancelTokenHandlerObject[this.export.name].handleRequestCancellation().token,
      responseType: 'blob'
    });
  }
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

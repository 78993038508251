// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import VuiSelectMedia from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelectMedia';
import {
  InformationInputs,
  informationSchema,
  ProductTierInputs,
  InformationBaseModel,
  ProductConversionBaseModel,
  ProductTierBaseModel
} from './validation';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import { defaultStatusOptions } from '../../../../../constants';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import ProductRepository from '../../../../../repositories/ProductRepository';
import CategoryRepository from '../../../../../repositories/CategoryRepository';
import SupplierRepository from '../../../../../repositories/SupplierRepository';
import UnitRepository from '../../../../../repositories/UnitRepository';
import MembershipRepository from '../../../../../repositories/MembershipRepository';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatFormData, formatSetValueForm } from '../../../../../vodea/utilities/helpers/form';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../../vodea/utilities/helpers/global';
import { $clone } from '../../../../../vodea/utilities';
import BootstrapTable from 'react-bootstrap-table-next';
import VuiNumberFormat from '../../../../../vodea/@vodea-ui/components/VuiNumberFormat';

const BaseModelPriceDetail: ProductTierInputs = {
  quantity: 1,
  unit_id: {},
  product_tier_prices: []
};

const ProductCreate: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const title = id ? t('Edit Product') : t('Create Product');

  const [profit, setProfit] = React.useState(0);
  const productStocks = useState<any[]>([]);

  const hasOtherUnits = useState(false);
  const hasTieredPrice = useState(false);

  const membershipOptions = useState([]);

  useEffect(() => {
    getMembershipOptions();

    if (id) {
      getData();
    } else {
      addProductTier();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMembershipOptions = () => {
    MembershipRepository.select({
      is_visible: 1
    })
      .then((response: AxiosResponse) => {
        membershipOptions.set(response.data.data);
      })
      .catch((e: AxiosError) => {});
  };

  const getData = () => {
    ProductRepository.show(id, {
      with: [
        'productPhotos',
        'productConversions.unit',
        'productTiers.unit',
        'productTiers.productTierPrices',
        'category',
        'unit',
        'supplier',
        'stocks.storage'
      ]
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        if (isMounted.current) {
          productStocks.set(_.get(data, 'stocks', []));

          if (formattedData?.product_conversions?.length > 0) {
            hasOtherUnits.set(true);

            formattedData.product_conversions.forEach((item: any, key: number) => {
              formattedData.product_conversions[key] = formatSetValueForm({ ...ProductConversionBaseModel, id }, item);
            });
          }

          if (formattedData?.product_tiers?.length > 0) {
            if (formattedData?.product_tiers?.length > 1) {
              hasTieredPrice.set(true);
            }

            formattedData.product_tiers.forEach((item: any, key: number) => {
              formattedData.product_tiers[key] = formatSetValueForm({ ...ProductTierBaseModel, id }, item);
            });
          }
        }

        setProfit(data.profit);

        _.forEach(formattedData, (value, name) => {
          informationSetValues(name, value);
        });
      })
      .catch((e: AxiosError) => {
        showToast(e.message, 'error');
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setError: informationSetError,
    setValue: informationSetValues,
    watch: informationWatch
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema)
  });

  const watchUnit = informationWatch('unit_id');
  const watchProductConversions = informationWatch('product_conversions');

  useEffect(() => {
    if (watchUnit) {
      productTierFields.forEach((value, index) => {
        if (index !== 0) informationSetValues(`product_tiers[${index}].unit_id`, null);
      });
    }
  }, [watchUnit]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPriceTierUnitOptions = () => {
    let productConversionUnits: any[] = [];

    if (hasOtherUnits.get()) {
      productConversionUnits = watchProductConversions.map((item: any) => {
        return item.unit_id;
      });
    }

    return _.uniqBy(_.concat(productConversionUnits, [watchUnit]), 'id');
  };

  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    if (formData.product_conversions !== undefined) {
      formData.product_conversions.forEach((item: any, key: number) => {
        formData.product_conversions[key] = formatFormData(item);
      });
    }

    if (formData.product_tiers !== undefined) {
      formData.product_tiers.forEach((item: any, key: number) => {
        formData.product_tiers[key] = formatFormData(item);
      });
    }

    if (id === undefined) {
      ProductRepository.create(formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, response.data.success ? 'success' : 'error');

          navigate('/inventory/product');
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    } else {
      ProductRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          showToast(response.data.message, 'success');
          informationLoading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, 'error');
          }

          informationLoading.set(false);
        });
    }
  });

  // Product Conversions
  const { fields: productConversionFields, append: productConversionAppend, remove: productConversionRemove } = useFieldArray({
    keyName: 'key',
    name: 'product_conversions',
    control: informationControl
  });

  const addProductConversions = () => {
    productConversionAppend(ProductConversionBaseModel);
  };

  const removeProductConversion = (index: number) => {
    productConversionRemove(index);
  };

  const renderTableProductConversions = (item: any, index: number) => {
    return (
      <tr key={item.key}>
        <td className={'text-table'}>
          <div className={'text-custom'}>1</div>
        </td>
        <td>
          <input type={'hidden'} name={`product_conversions[${index}].id`} ref={informationRegister()} defaultValue={item.id} />

          <div
            className={clsx({
              'form-group': true,
              error: _.get(informationErrors, `product_conversions[${index}].unit_id`)
            })}
          >
            <div className={'form-select-wrapper'}>
              <Controller
                name={`product_conversions[${index}].unit_id`}
                control={informationControl}
                defaultValue={item.unit_id}
                render={props => (
                  <VuiSelect selectRepository={UnitRepository} defaultValue={props.value} onChange={props.onChange} isMulti={false} />
                )}
              />
            </div>
          </div>
        </td>
        <td className={'text-table'}>
          <div className={'text-custom'}>=</div>
        </td>
        <td className={'form-table'}>
          <div
            className={clsx({
              'form-group': true,
              error: _.get(informationErrors, `product_conversions[${index}].quantity`)
            })}
          >
            <Controller
              name={`product_conversions[${index}].quantity`}
              control={informationControl}
              defaultValue={item.quantity}
              render={props => (
                <div className='input-group'>
                  <NumberFormat
                    className='form-control'
                    defaultValue={props.value}
                    allowNegative={false}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    onValueChange={({ value }) => props.onChange(value)}
                  />
                  <span className='input-group-text'>{_.get(watchUnit, 'name')}</span>
                </div>
              )}
            />
          </div>
        </td>
        <td className='close-action'>
          <FontAwesomeIcon icon={faTimes} onClick={() => removeProductConversion(index)} />
        </td>
      </tr>
    );
  };

  // Product Tiers
  const { fields: productTierFields, append: productTierAppend, remove: productTierRemove } = useFieldArray({
    keyName: 'key',
    name: 'product_tiers',
    control: informationControl
  });

  const addProductTier = () => {
    productTierAppend({
      ...BaseModelPriceDetail
    });
  };

  const removeProductTier = (index: number) => {
    productTierRemove(index);
  };

  const getProductTierPrices = (data: any) => {
    let productTierPrices: any[] = [];

    if (membershipOptions.value.length && data) {
      membershipOptions.value.forEach((membership: any, key: number) => {
        const selectedTierPrice = data.find((item: any) => {
          return Number(item.membership_id) === membership.id;
        });

        const tierPrice = {
          id: selectedTierPrice?.id ?? 0,
          membership_id: membership.id,
          price: selectedTierPrice?.price ?? null
        };

        productTierPrices[key] = tierPrice;
      });
    }

    return productTierPrices;
  };

  const renderTableProductTier = (item: any, index: number) => {
    const productTierPrices = getProductTierPrices(item.product_tier_prices);
    const unitOptions = getPriceTierUnitOptions();

    return (
      <tr key={item.key}>
        <td>
          <input type={'hidden'} name={`product_tiers[${index}].id`} ref={informationRegister()} defaultValue={item.id} />
          <div
            className={clsx({
              'form-group': true,
              error: _.get(informationErrors, `product_tiers[${index}].quantity`)
            })}
          >
            {index === 0 ? (
              <>
                <input type='hidden' name={`product_tiers[${index}].quantity`} ref={informationRegister()} defaultValue={item.quantity} />1
              </>
            ) : (
              <Controller
                name={`product_tiers[${index}].quantity`}
                control={informationControl}
                defaultValue={item.quantity}
                render={props => (
                  <OverlayTrigger key={index} placement={'top'} overlay={<Tooltip id={`tooltip-${index}`}>Up To</Tooltip>}>
                    <NumberFormat
                      className='form-control'
                      value={props.value}
                      allowNegative={false}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                      onValueChange={({ value }) => props.onChange(value)}
                    />
                  </OverlayTrigger>
                )}
              />
            )}

            <label className={'label-help'}>{informationErrors.minimum_stock?.message}</label>
          </div>
        </td>

        <td>
          <div
            className={clsx({
              'form-group': true,
              error: _.get(informationErrors, `product_tiers[${index}].unit_id`)
            })}
          >
            <div className={'form-select-wrapper'}>
              {index === 0 ? (
                <>
                  <input
                    type='hidden'
                    name={`product_tiers[${index}].unit_id`}
                    ref={informationRegister()}
                    defaultValue={_.get(watchUnit, 'id')}
                  />
                  {_.get(watchUnit, 'name')}
                </>
              ) : (
                <Controller
                  name={`product_tiers[${index}].unit_id`}
                  control={informationControl}
                  defaultValue={item.unit_id}
                  render={props => (
                    <VuiSelect
                      propKey={JSON.stringify(unitOptions)}
                      options={unitOptions}
                      defaultValue={props.value}
                      onChange={val => {
                        props.onChange(val);
                      }}
                      isMulti={false}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </td>

        {productTierPrices.map((tier: any, key: number) => {
          return (
            <td key={key}>
              <input
                type={'hidden'}
                name={`product_tiers[${index}].product_tier_prices[${key}].id`}
                ref={informationRegister()}
                defaultValue={tier.id}
              />
              <input
                type={'hidden'}
                name={`product_tiers[${index}].product_tier_prices[${key}].membership_id`}
                ref={informationRegister()}
                defaultValue={tier.membership_id}
              />
              <div
                className={clsx({
                  'form-group': true,
                  error: _.get(informationErrors, `product_tiers[${index}].product_tier_prices[${key}].price`)
                })}
              >
                <Controller
                  name={`product_tiers[${index}].product_tier_prices[${key}].price`}
                  control={informationControl}
                  defaultValue={tier.price}
                  render={props => (
                    <NumberFormat
                      className='form-control'
                      defaultValue={props.value}
                      allowNegative={false}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={0}
                      prefix={'IDR '}
                      onValueChange={({ value }) => props.onChange(value)}
                    />
                  )}
                />
              </div>
            </td>
          );
        })}

        <td>{index !== 0 ? <FontAwesomeIcon icon={faTimes} onClick={() => removeProductTier(index)} /> : ''}</td>
      </tr>
    );
  };

  // Stock Table
  const productStockColumns = [
    {
      dataField: 'storage.name',
      text: 'Storage'
    },
    {
      dataField: 'quantity',
      text: 'Quantity'
    },
    {
      dataField: 'price',
      text: 'Price',
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={'IDR '} />;
      }
    },
    {
      dataField: 'modal',
      text: 'Modal',
      formatter: (row: any, cell: any) => {
        const modal = cell.price * cell.quantity || 0;
        return <VuiNumberFormat data={modal} prefix={'IDR '} />;
      }
    }
  ];

  const breadcrumbList = [
    {
      label: t('Product'),
      link: '/inventory/product'
    },
    {
      label: id ? id : t('Create'),
      link: '/inventory/product/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <div id={'product-section'}>
        <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
          <div className={'default-page-layout'}>
            <div className={'multiple-paper-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>{t('Information')}</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.name
                        })}
                      >
                        <label className={'form-label'}>Name</label>
                        <input type='text' name='name' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.name?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.sku
                        })}
                      >
                        <label className={'form-label'}>SKU</label>
                        <input type='text' name='sku' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.sku?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-6'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.barcode
                        })}
                      >
                        <label className={'form-label'}>Barcode</label>
                        <input type='text' name='barcode' className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.barcode?.message}</label>
                      </div>
                    </div>

                    <div className={'col-md-12'}>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.description
                        })}
                      >
                        <label className={'form-label'}>Description</label>
                        <textarea name='description' rows={5} className='form-control' ref={informationRegister} />
                        <label className={'label-help'}>{informationErrors.description?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Photos</h6>
                </div>
                <div className={'card-content'}>
                  <div className='col-md-12'>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.product_photos
                      })}
                    >
                      <Controller
                        name='product_photos'
                        control={informationControl}
                        defaultValue={[]}
                        render={({ value, onChange }) => <VuiSelectMedia max={5} defaultValue={value} onChange={onChange} />}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Unit of Measurement</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.unit_id
                      })}
                    >
                      <label className={'form-label'}>Default Unit</label>
                      <div className={'form-select-wrapper'}>
                        <Controller
                          name={'unit_id'}
                          control={informationControl}
                          defaultValue={null}
                          render={props => (
                            <VuiSelect
                              selectRepository={UnitRepository}
                              defaultValue={props.value}
                              onChange={val => {
                                props.onChange(val);
                              }}
                              isMulti={false}
                            />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.unit_id, 'message')}</label>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <label className={'form-label'}>{t('Conversion')}</label>
                    <Form.Check
                      id='has-other-units'
                      type='checkbox'
                      label='Does this product has other unit?'
                      checked={hasOtherUnits.value}
                      onChange={val => {
                        hasOtherUnits.set(val.target.checked);
                      }}
                    />
                  </div>

                  {hasOtherUnits.get() ? (
                    <>
                      <hr />

                      <Table responsive='sm' className='table-add-item'>
                        <tbody>{productConversionFields.map(renderTableProductConversions)}</tbody>
                      </Table>

                      <button type={'button'} className={'btn btn-primary btn-small'} onClick={e => addProductConversions()}>
                        <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                        {t('Add Conversion')}
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Price</h6>
                </div>
                <div className={'card-content'}>
                  {watchUnit ? (
                    <>
                      <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.default_price
                        })}
                      >
                        <label className={'form-label'}>Default Price (if tier price has new membership)</label>
                        <Controller
                          name={'default_price'}
                          control={informationControl}
                          defaultValue={null}
                          render={props => (
                            <NumberFormat
                              className='form-control'
                              value={props.value}
                              allowNegative={false}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                              prefix={'IDR '}
                              onValueChange={({ value }) => props.onChange(value)}
                            />
                          )}
                        />
                        <label className={'label-help'}>{informationErrors.default_price?.message}</label>
                      </div>

                      <label className={'form-label'}>{t('Price Tiers')}</label>
                      <Form.Check
                        id='has-price-tier'
                        type='checkbox'
                        label='Does this product has tiered price?'
                        checked={hasTieredPrice.value}
                        onChange={val => {
                          hasTieredPrice.set(val.target.checked);
                        }}
                      />

                      <hr />
                      <div className='table-price-wrapper'>
                        <Table className='table-price table-add-item table-fixed'>
                          <thead>
                            <tr>
                              <th>Range</th>
                              <th>Unit</th>
                              {membershipOptions.value.map((item, key) => {
                                return <th key={key}>{_.get(item, 'name')}</th>;
                              })}
                              <th className='close-action'></th>
                            </tr>
                          </thead>
                          <tbody>{productTierFields.map(renderTableProductTier)}</tbody>
                        </Table>
                      </div>

                      {hasTieredPrice.get() ? (
                        <>
                          <button type={'button'} className={'btn btn-primary btn-small'} onClick={e => addProductTier()}>
                            <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                            {t('Add Price')}
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <div className={'card-empty-content'}>please select unit first</div>
                  )}
                </div>
              </div>

              {$clone(productStocks.get()).length ? (
                <div className={'card-paper'}>
                  <div className={'card-header'}>
                    <h6 className={'card-header-title'}>{t('Stocks')}</h6>
                  </div>

                  <div className={'card-content'}>
                    <BootstrapTable keyField='id' data={$clone(productStocks.get())} columns={productStockColumns} />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className={'multiple-paper-section'}>
              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Visibility</h6>
                </div>
                <div className={'card-content'}>
                  <div
                    className={clsx({
                      'form-group': true,
                      error: informationErrors.is_visible
                    })}
                  >
                    <label className={'form-label'}>Status</label>

                    <div className={'form-select-wrapper'}>
                      <Controller
                        name={'is_visible'}
                        control={informationControl}
                        defaultValue={defaultStatusOptions[0]}
                        render={props => <VuiSelect options={defaultStatusOptions} defaultValue={props.value} onChange={props.onChange} />}
                      />
                    </div>
                    <label className={'label-help'}>{_.get(informationErrors.is_visible, 'message')}</label>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Group</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.category_id
                      })}
                    >
                      <label className={'form-label'}>Category</label>
                      <div className={'form-select-wrapper'}>
                        <Controller
                          name={'category_id'}
                          control={informationControl}
                          defaultValue={null}
                          render={props => (
                            <VuiSelect
                              selectRepository={CategoryRepository}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              isMulti={false}
                            />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.category_id, 'message')}</label>
                    </div>
                  </div>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.supplier_id
                      })}
                    >
                      <label className={'form-label'}>Supplier</label>
                      <div className={'form-select-wrapper'}>
                        <Controller
                          name={'supplier_id'}
                          control={informationControl}
                          defaultValue={null}
                          render={props => (
                            <VuiSelect
                              selectRepository={SupplierRepository}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              isMulti={false}
                            />
                          )}
                        />
                      </div>
                      <label className={'label-help'}>{_.get(informationErrors.supplier_id, 'message')}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Stock</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.minimum_stock
                      })}
                    >
                      <label className={'form-label'}>Minimum Available Stock</label>
                      <Controller
                        name={'minimum_stock'}
                        control={informationControl}
                        defaultValue={0}
                        render={props => (
                          <div className='input-group'>
                            <NumberFormat
                              className='form-control'
                              value={props.value}
                              allowNegative={false}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                              onValueChange={({ value }) => props.onChange(value)}
                            />
                            <span className='input-group-text'>{_.get(watchUnit, 'name')}</span>
                          </div>
                        )}
                      />
                      <label className={'label-help'}>{informationErrors.minimum_stock?.message}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-paper'}>
                <div className={'card-header'}>
                  <h6 className={'card-header-title'}>Profit</h6>
                </div>
                <div className={'card-content'}>
                  <div className={'col-md-12'}>
                    <div className='form-group mb-unset'>
                      <label className={'form-label'}>Profit</label>
                      <div>{profit}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/inventory/product'} />
        </form>
      </div>
    </>
  );
};

export default ProductCreate;

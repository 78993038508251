// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable-next-line */
import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Model, modelSchema } from './validation';
import VuiSelect from '../../../../../vodea/@vodea-ui/components/Forms/VuiSelect';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, randomString, showToast, stringTransform } from '../../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../../vodea/utilities/hooks/useIsMounted';
import VuiActionForm from '../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm';
import RoleRepository from '../../../../../repositories/RoleRepository';
import { $clone } from '../../../../../vodea/utilities';
import UserRepository from '../../../../../repositories/UserRepository';
import GateSettingRepository from '../../../../../repositories/GateSettingRepository';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../stores';

interface AutoCompleteInterface {
  options: any[];
}

interface AutoCompleteAsyncInterface {
  loading: boolean;
  params: {
    [key: string]: any;
  };
  loadData: (key?: any) => void;
}

const BaseModel: Model = {
  type: 'role',
  role_id: null,
  user_id: null,
  permission_ids: []
};

interface OptionInterface {
  role: AutoCompleteInterface & AutoCompleteAsyncInterface;
  user: AutoCompleteInterface & AutoCompleteAsyncInterface;
}

const SettingPermissionForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const title = id ? t('Edit Permission') : t('Create Permission');

  const [data, setData] = React.useState<Model>(BaseModel);
  const permissionData = useState<any>(null);
  const permissionColumn = useState<any[]>([]);
  const informationLoading = useState(false);
  const permissions = useSelector((state: RootState) => state.permission.permission.permissions);

  const permissionFromBackend = useState<any[]>([]);

  const loadData = useCallback(async () => {
    const getPermissionData: AxiosResponse = await GateSettingRepository.permission();
    const { data: getPermissionResponse } = getPermissionData;
    if (getPermissionResponse) {
      permissionFromBackend.set(getPermissionResponse.data);
      transformPermissionData(getPermissionResponse.data);
    }

    if (!id) {
      return;
    }

    const params = {
      with: ['permissions', 'role', 'user']
    };

    try {
      const gateSettingData: AxiosResponse = await GateSettingRepository.show(id, params);
      const { data: gateSettingResponse } = gateSettingData;
      if (gateSettingResponse) {
        const valuePermissionIds = (gateSettingResponse.data?.permissions || []).map((permission: any) => permission.id);

        setData({
          ...gateSettingResponse.data,
          type: gateSettingResponse.data.role_id === null ? 'people' : 'role',
          ...(gateSettingResponse.data.role_id === null
            ? { user_id: gateSettingResponse.data.user }
            : { role_id: gateSettingResponse.data.role }),
          permission_ids: valuePermissionIds
        });
      }
    } catch (e) {
      if (isMounted.current) {
        showToast(e.message, 'error');
      }
    }
  }, []);

  const { register, handleSubmit, errors, control, setError, reset, watch, getValues, setValue } = useForm<Model>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(modelSchema),
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();

      return data;
    }, [id, loadData])
  });

  const type = watch('type', data.user_id ? 'role' : 'user_id');

  const permissionIds = watch('permission_ids');
  const option = useState<OptionInterface>({
    role: {
      options: [],
      loading: false,
      params: {
        for: 'gate-setting',
        'is-special': 0
      },
      loadData: async () => {
        option.role.loading.set(true);
        await RoleRepository.all()
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.role.options.set(data.data);
              option.role.loading.set(false);
            }
          })
          .catch((e: AxiosError) => {
            showToast(e.message, 'error');
          });
      }
    },
    user: {
      options: [],
      loading: false,
      params: {
        for: 'gate-setting'
      },
      loadData: async () => {
        option.user.loading.set(true);
        await UserRepository.all()
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.user.options.set(data.data);
              option.user.loading.set(false);
            }
          })
          .catch((e: AxiosError) => {
            showToast(e.message, 'error');
          });
      }
    }
  });

  useEffect(() => {
    if (type != 'people') {
      setValue('user_id', null);
      option.role.loadData.get()();
    }

    if (type != 'role') {
      setValue('role_id', null);
      option.user.loadData.get()();
    }
  }, [type]);

  const getSiblingIndexAbility = (transaction: string) => {
    const siblingId: any[] = [];

    permissionFromBackend.value.forEach((permission: any) => {
      if (permission.name.includes(transaction)) {
        siblingId.push(permission.id);
      }
    });

    return siblingId;
  };

  const getUpdateDestroyAbility = (transaction: string) => {
    const siblingId: any[] = [];

    permissionFromBackend.value.forEach((permission: any) => {
      if (permission.name.includes(transaction)) {
        if (permission.name.split('.').pop() === 'update' || permission.name.split('.').pop() === 'destroy') {
          siblingId.push(permission.id);
        }
      }
    });

    return siblingId;
  };

  const handlePermissionCheck = (ability: any, event: any, onChange: any) => {
    const isChecked = event.target?.checked;
    const abilitySplited = ability.name.split('.');
    const isIndexAbility = abilitySplited.pop() === 'index';
    const value = ability.id;

    const excludedValue = [value];

    if (isIndexAbility && !isChecked) {
      const siblingAbility = getSiblingIndexAbility(abilitySplited.shift());
      excludedValue.push(...siblingAbility);
    }

    if (ability.name.split('.').pop() === 'show' && !isChecked) {
      const updateDestroyAbility = getUpdateDestroyAbility(ability.name.split('.').shift());
      excludedValue.push(...updateDestroyAbility);
    }

    let selectedPermission: any[] = permissionIds;
    if (isChecked) {
      selectedPermission.push(value);
    } else {
      selectedPermission = selectedPermission.filter((permissionId: string) => !excludedValue.includes(permissionId));
    }

    onChange(selectedPermission);
  };

  useEffect(() => {
    loadData();
  }, []);

  useMemo(() => {
    reset(data);
  }, [data]);

  const transformPermissionData = (permissions: any) => {
    const columns: any[] = [];
    const tempPermissions: any[] = [];

    permissions.forEach((item: any) => {
      const splitName = item.name.split('.');
      const name = splitName[0];
      const ability = splitName[1];
      if (!columns.includes(ability)) {
        columns.push(ability);
      }
      const permission = {
        ...item,
        transaction: stringTransform.removeDashUnderScore(name),
        ability: ability
      };
      tempPermissions.push(permission);
    });

    const group = _.groupBy(tempPermissions, item => item.transaction);
    permissionColumn.set(columns);
    permissionData.set(group);
  };

  const onSubmit = async (formData: Model) => {
    informationLoading.set(true);

    formData = $clone(formData);

    if (formData.role_id) {
      formData.user_id = null;
      formData.role_id = formData.role_id.id;
    } else if (formData.user_id) {
      formData.role_id = null;
      formData.user_id = formData.user_id.id;
    }

    await (id != null ? GateSettingRepository.update(id, formData) : GateSettingRepository.create(formData))
      .then((resp: any) => {
        navigate('/setting/permission');
        showToast(resp.data.message, 'success');
      })
      .catch((e: any) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
          showToast(e.response.data.message, 'error');
        }
      });

    if (isMounted.current) {
      informationLoading.set(false);
    }
  };

  const renderTableRow = (abilities: any[], name: string, tableColumns: string[]) => (
    <tr>
      <td className={'td-permission'}>{name}</td>
      {tableColumns.map((tableColumn, i) => {
        const ability = abilities.find((a: any) => a.ability == tableColumn);
        if (!ability) {
          return <td key={`${name}${randomString(3)}`}></td>;
        }

        return (
          <td className={'td-permission check'} key={`${name}${ability.id}`}>
            <Controller
              render={({ value, name, onChange }) => {
                return (
                  <input
                    defaultValue={ability.id}
                    name={'permission_ids'}
                    type='checkbox'
                    checked={permissionIds.includes(ability.id)}
                    onChange={event => handlePermissionCheck(ability, event, onChange)}
                    color='primary'
                  />
                );
              }}
              name='permission_ids'
              control={control}
              defaultValue={[]}
            />
          </td>
        );
      })}
    </tr>
  );

  const renderTypeOptions = () => {
    return (
      <Controller
        render={({ onChange, value, name }) => (
          <div className={'form-check'}>
            <div className={'d-flex align-items-center'}>
              <input type='radio' value='role' name={name} className='form-check-input' onChange={onChange} checked={value === 'role'} />
              <label className={'form-check-label'}>Role</label>
            </div>
            <div className={'d-flex align-items-center'}>
              <input
                type='radio'
                value='people'
                name={name}
                className='form-check-input'
                onChange={onChange}
                checked={value === 'people'}
              />
              <label className={'form-check-label'}>People</label>
            </div>
          </div>
        )}
        name='type'
        control={control}
        defaultValue={type}
      />
    );
  };

  const breadcrumbList = [
    {
      label: t('Setting'),
      link: '/setting'
    },
    {
      label: t('Permission'),
      link: '/setting/permission'
    },
    {
      label: id ? id : t('Create'),
      link: '/setting/permission/create'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{title}</h3>
      </div>

      <div id={'product-section'}>
        <form className={'form-wrapper'} onSubmit={handleSubmit(onSubmit)}>
          <div className={'card-paper'}>
            <div className='card-header'>
              <div className={'card-header-title'}>Apply Permission To</div>
            </div>
            <div className={'card-content'}>
              <div className='row'>
                <div className='col-md-12'>{renderTypeOptions()}</div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  {type === 'people' ? (
                    <Controller
                      name={'user_id'}
                      control={control}
                      propKey={'user_id'}
                      render={({ value, onChange, name }) => (
                        <VuiSelect options={$clone(option.user.options.get())} defaultValue={value} onChange={onChange} />
                      )}
                    />
                  ) : null}
                  {type === 'role' ? (
                    <Controller
                      name={'role_id'}
                      control={control}
                      propKey={'role_id'}
                      render={({ value, onChange, name }) => (
                        <VuiSelect options={$clone(option.role.options.get())} defaultValue={value} onChange={onChange} />
                      )}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className={'card-paper mt-4'}>
            <div className='card-header'>
              <div className={'card-header-title'}>Master Data & Transcational</div>
            </div>
            <div className={'card-content'}>
              <Table striped bordered hover>
                <thead>
                  <tr className={'th-permission'}>
                    <th />
                    {permissionColumn.get().map((column: any, index: number) => (
                      <th key={index}>{column || 'widget'}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permissionData.get() &&
                    Object.keys(permissionData.get()).map((key: string) =>
                      renderTableRow(_.get(permissionData.get(), key), key, permissionColumn.get())
                    )}
                </tbody>
              </Table>
            </div>
          </div>

          <VuiActionForm loading={informationLoading.get()} cancelLink={'/setting/permission'} />
        </form>
      </div>
    </>
  );
};

export default SettingPermissionForm;
